import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import AppNavigator from "./screens/AppNavigator";

// const firebaseConfig = {
//   apiKey: "AIzaSyB4ziM1B24Rj4kuKl8stt0QVj_wzmt0sw4",
//   authDomain: "apd-formacion.firebaseapp.com",
//   databaseURL: "https://apd-formacion.firebaseio.com",
//   projectId: "apd-formacion",
//   storageBucket: "apd-formacion.appspot.com",
//   messagingSenderId: "487214668266",
//   appId: "1:487214668266:web:79a4d321399006ded4fa93",
//   measurementId: "G-QVGEM8LK41"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCn8FdzS427iyVc47rTmwW3H5KNRMsDfpQ",
  authDomain: "uno-work.firebaseapp.com",
  projectId: "uno-work",
  storageBucket: "uno-work.appspot.com",
  messagingSenderId: "970827252335",
  appId: "1:970827252335:web:ff2b962527f021a654c39a",
  measurementId: "G-Y0D39T3Y1L"
};

firebase.initializeApp(firebaseConfig)

const App = () => {

  return (
    <Router>
      <AppNavigator />
    </Router>
  );
};

export default App;
