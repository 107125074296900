import React, { useState } from "react";
import { Box, DataTable, Heading, CheckBox, Text, Layer, Button } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Trash, Add } from "grommet-icons";

import { withRouter } from "react-router";

const Challenges = ({ location, history }) => {

  const [checked, setChecked] = useState([]);
  const [deleteModal, toogleDeleteModal] = useState(false);
  const [contentIDToDelete, setContentIDToDelete] = useState("");
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)


  const deleteChallenge = () => {}


  const columns = [
    {
      property: "title",
      header: <Text>Título del Reto</Text>,
      render: datum => (
        <Text key={datum.id}>
          {datum.name !== null ? datum.name.substring(0, 50) +
            (datum.name.length > 50 ? "..." : "") : "Sin titulo"}
        </Text>
      ),
      primary: true,
      search: true
    },
    {
      property: "amount",
      header: "Repeticiones",
      align: "center"
    },
    {
      property: "often",
      header: "Frecuencia",
      align: "center"
    },
    {
      property: "edit",
      header: "Edit",
      sortable: false,
      render: datum =>
        datum && (
          <Box flex>
            <Link
              to={{
                pathname: `/challenges/editchallenge/${datum.id}`,
              }}
            >
              <Edit color="gray" style={{ cursor: "pointer" }} />
            </Link>
          </Box>
        )
    },
    {
      property: "delete",
      header: "Delete",
      sortable: false,
      render: datum =>
        datum && (
          <Trash
            color="gray"
            style={{ cursor: "pointer" }}
            onClick={() => {
              toogleDeleteModal(!deleteModal);
              setContentIDToDelete(datum.id);
            }}
          />
        )
    }
  ];

  const onCheck = (event, value) => {
    event.target.checked && setChecked([...checked, value]);
    !event.target.checked && setChecked(checked.filter(item => item !== value));
  };

  const onCheckAll = event => {
    setChecked(
      event.target.checked ? data.challenges.map(datum => datum.id) : []
    );
  };



  if (loading) {
    console.log("loading")
    return null
  } else if (error) {
    // console.log({error})
    // localStorage.clear()
    return <Text>Error :(</Text>
  }
  return (
    <Box pad="medium">

      <Box background="white" overflow="hidden" round="small" elevation="medium" >

        <Box pad="small" >
          <Heading color="gray" level="4" margin="none">Listado de Contenidos</Heading>
        </Box>

        <DataTable
          columns={[
            {
              property: "checkbox",
              render: datum => (
                  <CheckBox
                    checked={checked.indexOf(datum.id) !== -1}
                    onChange={e => onCheck(e, datum.id)}
                  />
              ),
              header: (
                  <CheckBox
                    checked={checked.length === data.challenges.length}
                    indeterminate={
                      checked.length > 0 && checked.length < data.challenges.length
                    }
                    onChange={onCheckAll}
                  />
              ),
              sortable: false,
            },
            ...columns
          ]}
          data={data.challenges}
          onClickRow={event => console.log("asd")}
          sortable
          resizeable
        />
      </Box>

      <Box align="end" pad="small">
        <Button primary onClick={() => history.push("/challenges/newchallenge")} icon={<Add />} label="Nuevo Reto" />
      </Box>

      {deleteModal && (
        <Layer
          position="center"
          modal
          onClickOutside={() => toogleDeleteModal(!deleteModal)}
          onEsc={() => toogleDeleteModal(!deleteModal)}
        >
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button
              label="Cancel"
              onClick={() => toogleDeleteModal(!deleteModal)}
            />
            <Button
              type="submit"
              label="Eliminar"
              primary
              onClick={() => {
                deleteChallenge({
                  variables: { challengeID: { id: contentIDToDelete } }
                });
                toogleDeleteModal(!deleteModal);
              }}
            />
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default withRouter(Challenges);
