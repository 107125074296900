import React, { useState, useEffect, useContext } from "react";
import { Box, Layer, Button, Heading, DataTable, Text, CheckBox } from "grommet";
import { View } from "grommet-icons";

import { firestore, storage } from "firebase";

import ReactTable from "react-table";
import AuthContext from "../context/AuthContext";
// import 'react-table/react-table.css'

const columns = [
	{
		property: "title",
		header: <Text>Forum Title</Text>,
		render: (datum) => (
			<Box width={{ max: "medium" }}>
				<Text key={datum.id}>
					{/* .substring(0, 30) +
                    (datum.title.length > 30 ? "..." : "") */}
					{datum.name !== null ? datum.name : "Sin titulo"}
				</Text>
			</Box>
		),
		primary: true,
		search: true,
	},
	
	// {
	// 	property: "origin",
	// 	header: "Origen",
	// 	render: (datum) => <Text>{datum.origin}</Text>,
	// },
	// {
	// 	property: "edit",
	// 	header: "Preview",
	// 	sortable: false,
	// 	render: (datum) =>
	// 		datum && (
	// 			<Box flex>
	// 				<View />
	// 			</Box>
	// 		),
	// },
];

const ForumLibrary = ({ single, closeCallback, submitCallback }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState([]);


	useEffect(() => {
		getDataContent()
		console.log(data);
	}, []);

	const getDataContent = async () => {
		tenant.ref.collection("forums").onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					const img = doc.data().image;
					const url = img
						? await storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: "";
					return { ...doc.data(), id: doc.id, publicURL: url, image: url, ref: doc.ref };
				})
			).then((res) => {
				const data = res;
				setData(data);
			});
		});
	};
	
	const onCheck = (event, value) => {
		if (single) {
			setChecked([value]);
		} else {
			event.target.checked && setChecked([...checked, value]);
			!event.target.checked && setChecked(checked.filter((item) => item !== value));
		}
	};

	const onCheckAll = (event) => {
		setChecked(event.target.checked ? data.map((datum) => datum) : []);
	};

	return (
		<Layer full='vertical' position='left' onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
			<Box width={{ min: "large" }}>
				<Box pad='small' height='80px' background='#f2f2f2' justify='between' direction='row' align='center'>
					<Heading color='gray' level='4' margin='none'>
						Selecciona el foro que quieres asignar a este curso
					</Heading>
					<Box direction='row' gap='small' pad='small'>
						<Button label='Cancelar' onClick={() => closeCallback()} />
						<Button primary label='Añadir' onClick={() => submitCallback(checked)} />
					</Box>
				</Box>
				<Box overflow='scroll' flex>
					{/* {!loading && */}
					<DataTable
						pad={{ horizontal: "small", vertical: "medium" }}
						columns={[
							{
								property: "checkbox",
								render: (datum) => <CheckBox key={datum.id} checked={checked.map((el) => el.id).indexOf(datum.id) !== -1} onChange={(e) => onCheck(e, datum)} />,
								header: single ? <Text></Text> : <CheckBox checked={checked.length === data.length} indeterminate={checked.length > 0 && checked.length < data.length} onChange={onCheckAll} />,
								sortable: false,
							},
							...columns,
						]}
						data={data}
						sortable
						resizeable
					/>
					{/* } */}
				</Box>
			</Box>
		</Layer>
	);
};

export default ForumLibrary;
