import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput, CheckBox } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, Group, FormViewHide, View, Close } from "grommet-icons";
import { Editor } from "@tinymce/tinymce-react";

import moment from "moment";
import { withRouter, useParams, useHistory } from "react-router";
import Loader from "react-loader-spinner";
import axios from "axios";
import { firestore, storage } from "firebase";

import InviteModal from "../../components/InviteModal";

import ReactTable from "react-table";
import "react-table/react-table.css";
import sendPushNotification from "../../utils/sendPushNotification";
import AuthContext from "../../context/AuthContext";

const NotificationForm = () => {
	const { tenant } = useContext(AuthContext);
	const { id } = useParams();
	const history = useHistory();
	const [token, setToken] = useState("ExponentPushToken[qS9LkiDu76bo-Ai2uaWa1Z]");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [result, setResult] = useState(null);
	const [invite, setInvite] = useState(false);
	const [sendModal, setSendModal] = useState(false);
	const [users, setUsers] = useState([]);
	const [notification, setNotification] = useState({
		sent: false,
		responses: [],
	});
	const [richEditor, setRichEditor] = useState(null);
	const [form, setForm] = useState({
		subject: "",
		body: "",
		from: "formacion@hub7s.com",
		email: false,
		push: false,
		// host: "smtp.office365.com",
		// port: 587,
		// user: "formacion@hub7s.com",
		// pass: "hub7s",
		// secure: true,
		// service: "Outlook365",
		// from: "notificaciones@uno.work",
		host: "email-smtp.eu-west-1.amazonaws.com",
		port: 465,
		secure: true,
		user: "AKIA5EGT5JSVTI3JSREV",
		pass: "BEyellyX16VBaLuWu35YxgwYB5o5FAExmmuxpCuEu3Me",
	});

	useEffect(() => {
		!id && setRichEditor("<p></p>");
		// !id && console.log("no mierda");
		id && fetchData();
	}, []);

	const fetchData = async () => {
		tenant.ref
			.collection("customnotifications")
			.doc(id)
			.get()
			.then((doc) => {
				if (doc.exists) {
					const d = doc.data();
					setForm(d.form);
					setRichEditor(d.form.body || "<p></p>");
					setUsers(d.users);
					setNotification(d.notification);
					console.log(doc.data());
				} else {
					setError(true);
				}
			})
			.catch((err) => {
				setError(true);
			});
	};

	const sendNotification = () => {
		setLoading(true);
		!form.email && !form.push && alert("Selecciona si deseas mandar la notificación por email o push para poder enviarla");
		if (users.length === 0) {
			alert("Selecciona uno o mas destinatarios de la notificación");
			setLoading(false);
		} else if (form.subject === "") {
			alert("Escribe un asunto para la notificación");
			setLoading(false);
		} else {
			if (form.email) {
				Promise.all(
					users.map((user) => {
						const d = richEditor.replace("{{%email%}}", user.email).replace("{{%name%}}", user.name).replace("{{%lastname%}}", user.lastname);
						const t = form.subject.replace("{{%email%}}", user.email).replace("{{%name%}}", user.name).replace("{{%lastname%}}", user.lastname);
						// console.log(d);

						return axios
							.post("https://europe-west1-uno-work.cloudfunctions.net/sendMail", {
								method: "POST",
								body: JSON.stringify({ ...form, to: user.email, subject: t, body: d }),
							})
							.then((res) => {
								return { ...res.data, email: user.email };
							})
							.catch((res) => {
								return { ...res.data, email: user.email };
							});
					})
				)
					.then((res) => {
						console.log(res);
						setResult(res);
						setLoading(false);
						setNotification({
							sent: true,
							responses: res,
						});
						id ? updateNotification({ sent: true, responses: res }) : saveNotification({ sent: true, responses: res });
					})
					.catch((err) => {
						console.log("ERROR ", err);
						setResult(err);
						setLoading(false);
					});
			}
			if (form.push) {
			}
		}
	};

	const saveNotification = (n) => {
		tenant.ref
			.collection("customnotifications")
			.add({
				notification: { ...(n ? n : notification), sentAt: firestore.FieldValue.serverTimestamp() },
				users,
				form: {
					...form,
					body: richEditor,
				},
			})
			.then((res) => {
				history.push(`${process.env.PUBLIC_URL}/${tenant.url}/notifications`);
			})
			.catch((err) => {
				alert("Ha ocurrido un error al guardar la notificación");
			});
	};

	const updateNotification = (n) => {
		tenant.ref
			.collection("customnotifications")
			.doc(id)
			.update({
				notification: { ...(n ? n : notification), sentAt: firestore.FieldValue.serverTimestamp() },
				users,
				form: {
					...form,
					body: richEditor,
				},
			})
			.then((res) => {
				history.push(`${process.env.PUBLIC_URL}/${tenant.url}/notifications`);
			})
			.catch((err) => {
				alert("Ha ocurrido un error al guardar la notificación");
			});
	};
	if (error) {
		return (
			<Box>
				<Box background="white" overflow="hidden" round="small" elevation="medium">
					<Box margin="small" background={"status-critical"} pad="medium" round="small" direction="row" align="center">
						<Text color="white">Ha ocurrido un error al cargar la notificación</Text>
					</Box>
				</Box>
			</Box>
		);
	}
	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				{result && (
					<Box margin="small" background={result.some((el) => el.error) ? "status-critical" : "status-ok"} pad="medium" round="small" direction="row" align="center">
						<Box flex>
							{result.some((el) => el.error) ? (
								<Box gap="small">
									<Text color="white">Ha ocurrido un error al enviar la notificación a los siguientes emails:</Text>
									<Box direction="row" wrap>
										{result
											.filter((el) => el.error)
											.map((el, i) => {
												return (
													<Text key={i} margin="5px" color="white" size="small">
														{el.email}
													</Text>
												);
											})}
									</Box>
								</Box>
							) : (
								<Text color="white">Se ha enviado las notificaciones con éxito</Text>
							)}
						</Box>
						<Button onClick={() => setResult(null)} color="white" icon={<Close color="white" />} />
					</Box>
				)}
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Nueva Notificación
					</Heading>

					{/* <Box justify="end" gap="medium" direction="row" flex="grow" wrap>
                        <Box flex="grow" width={{ max: "450px" }}>
                            <TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.notifications.filter(el => el.email.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
                        </Box>
                        <Button primary onClick={() => history.push("/forums/newforum")} icon={<Add />} label="Nuevo Usuario" />
                    </Box> */}
				</Box>

				<Box pad="medium" gap="medium" style={{ width: "100%" }}>
					<TextInput value={form.subject} placeholder="Asunto" onChange={(ev) => setForm({ ...form, subject: ev.target.value })} />
					<Box gap="small">
						<Box direction="row">
							<Box flex justify="center">
								<Heading margin="none" level="5">
									Destinatarios
								</Heading>
							</Box>
							<Button primary onClick={() => setInvite(true)} icon={<Add />} label="Agregar destinatarios" />
						</Box>
						<Box height={{ max: "90px", min: "50px" }} overflow="scroll" pad={{ horizontal: "small" }} direction="row" wrap align="center" round="small" background="#f2f2f2">
							{users.length === 0 ? (
								<Text>Sin destinatarios</Text>
							) : (
								users?.map((user, i) => {
									return (
										<Box background="white" pad="small" round="5px" margin="5px" key={i} onClick={() => setUsers(users.filter((el) => el.email !== user.email))} direction="row" gap="small">
											<Text>{user.email}</Text>
											<Close />
										</Box>
									);
								})
							)}
						</Box>
					</Box>
					{richEditor !== null ? (
						<Editor
							initialValue={richEditor}
							init={{
								height: 300,
								branding: false,
								menubar: "file edit view insert format tools table tc help",
								plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
								toolbar: "undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help",
							}}
							onEditorChange={setRichEditor}
							apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
						/>
					) : (
						<Box height="300px" width="100%" align="center" justify="center" background="#f2f2f2">
							<Loader type="Puff" color="black" height={50} width={50} />
						</Box>
					)}
					<Text size="small">
						Se pueden emplear las siguientes etiquetas en el título o el cuerpo de de la notificación para personalizar la información: {`{{%name%}}`} - {`{{%lastname%}}`} {`{{%email%}}`}
					</Text>
					{/* <TextArea style={{ height: 150 }} value={form.body} placeholder="Cuerpo de la notificación" onChange={(ev) => setForm({ ...form, body: ev.target.value })} /> */}
					<Box gap="small">
						<CheckBox onChange={(ev) => setForm({ ...form, email: ev.target.checked })} checked={form.email} label="Enviar notificación por Email" />
						<CheckBox onChange={(ev) => setForm({ ...form, push: ev.target.checked })} checked={form.push} label="Enviar notificación Push" />
					</Box>
				</Box>
				{notification?.sent && (
					<Box margin="small" background={notification?.responses.some((el) => el.error) ? "status-critical" : "status-ok"} pad="medium" round="small" direction="row" align="center">
						<Box flex>
							{notification?.responses.some((el) => el.error) ? (
								<Box gap="small">
									<Text color="white">Ha ocurrido un error al enviar la notificación a los siguientes emails:</Text>
									<Box direction="row" wrap>
										{notification?.responses
											.filter((el) => el.error)
											.map((el, i) => {
												return (
													<Text key={i} margin="5px" color="white" size="small">
														{el.email}
													</Text>
												);
											})}
									</Box>
								</Box>
							) : (
                                <Box gap="small">
                                    <Text color="white">Este email se envió correctamente a los siguientes destinatarios:</Text>
                                    <Box direction="row" wrap>
										{notification?.responses
											.map((el, i) => {
												return (
													<Text key={i} margin="5px" color="white" size="small">
														{el.email}
													</Text>
												);
											})}
									</Box>
                                </Box>
							)}
						</Box>
						{/* <Button onClick={() => setResult(null)} color="white" icon={<Close color="white" />} /> */}
					</Box>
				)}
				<Box pad="medium" direction="row" align="center" justify="between">
					{!notification?.sent && <Button disabled={loading} label={id ? "Actualizar" : "Guardar"} onClick={() => (id ? updateNotification() : saveNotification())} />}
					{!notification?.sent && <Button disabled={loading} label="Enviar" primary onClick={() => (users.length > 0 ? setSendModal(true) : alert("Selecciona al menos un destinatario"))} />}
					{/* sendPushNotification({ to: [token], title: form.title, body: form.body, screen: "Post", params: { id: "1Q3i1ONZlKICYSeYrULu" } }) */}
				</Box>
			</Box>

			{invite && (
				<InviteModal
					withDates={false}
					closeCallback={() => setInvite(false)}
					submitCallback={(ev) => {
						setUsers(ev.users.map((el) => ({ email: el.email, name: el.name, lastname: el.surname })));
						setInvite(false);
					}}
				/>
			)}

			{loading && (
				<Layer>
					<Box align="center" background="rgba(0,0,0,0.6)" justify="center" style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
						<Loader type="Puff" color="white" height={50} width={50} />
					</Box>
				</Layer>
			)}

			{sendModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => setSendModal(!sendModal)} onEsc={() => setSendModal(!sendModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea enviar esta Notificación?
							</Heading>
							<Text>Se enviará a los siguientes usuarios: {users.map((el) => el.email).join(", ")}</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => setSendModal(!sendModal)} />
							<Button
								type="submit"
								label="Enviar"
								color="status-ok"
								primary
								onClick={() => {
									sendNotification();
									setSendModal(!sendModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default NotificationForm;
