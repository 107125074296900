import React, { useState, useEffect, useContext } from "react";
import { Box, Layer, Button, Heading, DataTable, Text, Image, CheckBox, TextInput } from "grommet";
import { Edit, Send, Trash, View } from "grommet-icons";

import { firestore, storage } from "firebase";

import AuthContext from "../context/AuthContext";
import moment from "moment";

const ModerateLibrary = ({ single, closeCallback, submitCallback, forumId, topicId }) => {
	const { userData, tenant, customization } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState([]);
	const [topic, setTopic] = useState({});
	const [body, setBody] = useState("");

	useEffect(() => {
		getTopic();
		getDataContent();
	}, []);

	const getTopic = async () => {
		tenant.ref
			.collection("forums")
			.doc(forumId)
			.collection("topics")
			.doc(topicId)
			.get()
			.then(async (doc) => {
				const d = doc.data();
				const img = doc.data()?.user?.image;
				const url = img
					? await storage()
							.ref(img)
							.getDownloadURL()
							.then((res) => res)
							.catch((err) => "https://www.theglobaltreasurer.com/wp-content/uploads/2019/02/placeholder-person.jpg")
					: null;
				setTopic({ ...d, id: doc.id, user: { ...doc.data().user, image: url } });
			});
	};

	const getDataContent = async () => {
		console.log(forumId, topicId);
		tenant.ref
			.collection("forums")
			.doc(forumId)
			.collection("topics")
			.doc(topicId)
			.collection("messages")
			.orderBy("createdAt", "asc")
			.onSnapshot(async (querySnapshot) => {
				const d = await Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data()?.user?.image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "https://www.theglobaltreasurer.com/wp-content/uploads/2019/02/placeholder-person.jpg")
							: null;
						return { ...doc.data(), id: doc.id, ref: doc.ref, user: { ...doc.data().user, image: url } };
					})
				).then((res) => res);
				console.log(d);
				setData(d);
			});
	};

	const sendMessage = () => {
		const variables = {
			body,
			user: {
				id: userData.id,
				name: userData.name,
				image: `${tenant.ref.id}/users/${userData.id}/profilePicture`,
			},
			createdAt: firestore.FieldValue.serverTimestamp(),
			topic: tenant.ref.collection("forums").doc(topicId),
		};

		tenant.ref
			.collection("forums")
			.doc(forumId)
			.collection("topics")
			.doc(topicId)
			.collection("messages")
			.add(variables)
			.then(() => {
				setBody("");
				tenant.ref
					.collection("forums")
					.doc(forumId)
					.collection("topics")
					.doc(topicId)
					.update({
						responses: firestore.FieldValue.increment(1),
						lastActive: firestore.FieldValue.serverTimestamp(),
					});
			});
	};

	const deleteComment = (ref) => {
		ref.delete()
			.then((res) => {
				tenant.ref
					.collection("forums")
					.doc(forumId)
					.collection("topics")
					.doc(topicId)
					.update({
						responses: firestore.FieldValue.increment(-1),
						lastActive: firestore.FieldValue.serverTimestamp(),
					})
					.then((res) => console.log(res))
					.catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const editComment = () => {};

	return (
		<Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
			<Box width={{ min: "large" }} flex>
				<Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
					{/* <Heading color='gray' level='4' margin='none'>
						Selecciona el foro que quieres asignar a este curso
					</Heading> */}
					<Box direction="row" gap="small" pad="small">
						<Button label="Volver" onClick={() => closeCallback()} />
						{/* <Button primary label="Añadir" onClick={() => submitCallback(checked)} /> */}
					</Box>
				</Box>
				<Box overflow={{ vertical: "scroll" }} flex pad="small" gap="small">
					<Heading level="3" margin="10px">
						{topic.title}
					</Heading>
					<Box flex="grow">
						<Box gap="small">
							{topic.id && (
								<Box width="large" elevation="medium" background={customization.mainColor} pad="medium" gap="small" round="small">
									<Box direction="row" gap="small" align="center">
										<Box width="45px" height="45px" round="23px" overflow="hidden">
											<Image src={topic.user.image} width="45px" height="45px" round="23px" />
										</Box>
										<Text size="18px">{topic.user.name}</Text>
									</Box>
									<Text color="white">{topic.body}</Text>
								</Box>
							)}
							{data.map((topic, i) => {
								return (
									<Box width="large" gap="small" background="white" elevation="medium" pad="medium" round="small" key={i}>
										<Box direction="row" justify="between" align="center">
											<Box direction="row" gap="small" align="center">
												<Box width="45px" height="45px" round="23px" overflow="hidden">
													<Image src={topic.user.image} width="45px" height="45px" round="23px" />
												</Box>
												<Text size="18px">{topic.user.name}</Text>
											</Box>
											<Box direction="row" align="center">
												<Text margin={{ right: "15px" }}>{moment(topic?.createdAt?.toDate()).format("DD [de] MMMM [de] YYYY [a las] hh:mm")}</Text>
												{/* <Button hoverIndicator onClick={() => editComment()} icon={<Edit />} /> */}
												<Button hoverIndicator onClick={() => deleteComment(topic.ref)} icon={<Trash />} />
											</Box>
										</Box>
										<Text>{topic.body}</Text>
									</Box>
								);
							})}
						</Box>
					</Box>
				</Box>
				<Box height="60px" align="center" pad={{ horizontal: "small" }} direction="row">
					<TextInput value={body} onChange={(ev) => setBody(ev.target.value)} placeholder="Escribe aquí..." />
					<Button hoverIndicator onClick={() => sendMessage()} icon={<Send />} />
				</Box>
			</Box>
		</Layer>
	);
};

export default ModerateLibrary;
