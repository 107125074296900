
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import {
    Box,
    ResponsiveContext
} from 'grommet';

import UserForm from './UserForm'
import UserList from './UserList'
import UserGroups from './UserGroups'
import UserGroupForm from './UserGroupForm'
import ImportData from './ImportData'
import SideMenu from '../../components/SideMenu'
import UsersActions from './UsersActions'; 


const RouterUsers = ({ area }) => {

    const size = useContext(ResponsiveContext)
    const { url } = useRouteMatch();

    const routes = [
        {
            route: `${url}/newuser`,
            icon: "UserAdd",
            title:"Nuevo Usuario",
            allowed: ["Admin"]
        }, {
            route: `${url}`,
            icon: "Group",
            title:"Usuarios",
            allowed: ["Admin"]
        }, 
        {
            route: `${url}/groups`,
            icon: "Stakeholder",
            title:"Grupos Usuarios",
            allowed: ["Admin"]
        }, 
        {
            route: `${url}/import`,
            icon: "Add",
            allowed: ["Admin"],
            title:"Importación"
        }
    ]

    return (
        <Box direction="row" gap="medium" justify="center" pad="medium">

            {size !== "small" &&
                <Box width="100px">
                    <SideMenu routes={routes} sidebarStatus={true} />
                </Box>
            }

            <Box flex width={{ max: "xlarge" }}>
                <Route exact path={`${url}`}  component={UserList} exact />
                <Route path={`${url}/newuser`}  component={UserForm} />
                <Route path={`${url}/edituser/:id`}  component={UserForm} />
                <Route path={`${url}/groups`}  component={UserGroups} />
                <Route path={`${url}/newgroup`}  component={UserGroupForm} />
                <Route path={`${url}/editgroup/:id`} component={UserGroupForm} />
                <Route path={`${url}/import`} component={ImportData} />
                <Route path={`${url}/logs`}  component={UsersActions} />
            </Box>
        </Box>
    );
}

export default RouterUsers;