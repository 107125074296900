
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import { Box } from 'grommet';

import Challenges from './Challenges'
import ChallengeForm from './ChallengeForm'

import SideMenu from '../../components/SideMenu'

const routerChallenges = ({ area }) => {

    const routes = [
        {
            route: "/challenges/newchallenge",
            icon: "Add"
        }, {
            route: "/challenges",
            icon: "Book"
        }
    ]

    return (
        <Box direction="row">

            <Box width="100px" margin="medium">
                <SideMenu routes={routes} sidebarStatus={true} />
            </Box>

            <Box fill="horizontal">
                <Route exact path="/challenges" component={Challenges} exact />
                <Route path="/challenges/newchallenge" component={ChallengeForm} />
                <Route path="/challenges/editchallenge/:id" component={ChallengeForm} />
            </Box>
        </Box>
    );
}

export default routerChallenges;