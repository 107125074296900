import React, { useState, useEffect, useContext } from 'react';
import { Box, Layer, Button, Heading, DataTable, Text, CheckBox } from 'grommet';
import { View } from 'grommet-icons'

import { firestore } from 'firebase'
import ReactTable from 'react-table'
import AuthContext from '../context/AuthContext';
// import 'react-table/react-table.css'

const columns = [
    {
        property: "title",
        header: <Text>Título del Exámen</Text>,
        render: datum => (
            <Text key={datum.id}>
                {datum.name !== null ? datum.name.substring(0, 30) +
                    (datum.name.length > 30 ? "..." : "") : "Sin titulo"}
            </Text>
        ),
        primary: true,
        search: true
    },
    {
        property: "description",
        render: datum => <Box width={{ max: "300px" }}><Text truncate>{datum.description}</Text></Box>,
        header: "Descripción"
    },
    {
        property: "edit",
        header: "Preview",
        sortable: false,
        render: datum =>
            datum && (
                <Box flex>
                    <View />
                </Box>
            )
    },
];

const ExamsLibrary = ({ single, closeCallback, submitCallback }) => {

    const {userData, tenant} = useContext(AuthContext)

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([])

    useEffect(() => {
        const unsubscribe = tenant.ref.collection("exams")
        .onSnapshot((querySnapshot) => {
            Promise.all(querySnapshot.docs.map(async (doc) => {
                return { ...doc.data(), id: doc.id, ref: doc.ref }
            })).then(res => {
                setData(res)
            })
        })

        return () => unsubscribe()
    }, [])

    const onCheck = (event, value) => {
        if (single) {
            setChecked([value])
        } else {
            event.target.checked && setChecked([...checked, value]);
            !event.target.checked && setChecked(checked.filter(item => item !== value));
        }
    };

    const onCheckAll = event => {
        setChecked(
            event.target.checked ? data.map(datum => datum) : []
        );
    };

    return (
        <Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
            <Box width={{ min: "large" }}>
                <Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
                    <Heading color="gray" level="4" margin="none">Selecciona uno o varios contenidos</Heading>
                    <Box direction="row" gap="small" pad="small">
                        <Button label="Cancelar" onClick={() => closeCallback()} />
                        <Button primary label="Añadir" onClick={() => submitCallback(checked)} />
                    </Box>
                </Box>
                <Box overflow="scroll" flex>
                    <DataTable
                        columns={[
                            {
                                property: "checkbox",
                                render: datum => (
                                    <CheckBox
                                        key={datum.id}
                                        checked={checked.map(el => el.id).indexOf(datum.id) !== -1}
                                        onChange={e => onCheck(e, datum)}
                                    />
                                ),
                                header: single ?
                                    (<Text></Text>)
                                    :
                                    (
                                        <CheckBox
                                            checked={checked.length === data.length}
                                            indeterminate={
                                                checked.length > 0 && checked.length < data.length
                                            }
                                            onChange={onCheckAll}
                                        />
                                    ),
                                sortable: false
                            },
                            ...columns
                        ]}
                        data={data}
                        sortable
                        resizeable
                    />
                </Box>
            </Box>
        </Layer>
    );
}

export default ExamsLibrary;