import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Image, Heading, Text, Video, Layer, Grid, CheckBox, TextInput } from "grommet";
import { Previous, Next, Close, Trash } from "grommet-icons";

import Unsplash from "unsplash-js";

import { storage, firestore } from "firebase";

import AuthContext from "../context/AuthContext";

const unsplash = new Unsplash({
	accessKey: "I1dl8DgkpSCw0131IA3uCZk4K3jMU_hseJk0mc8SVoE",
	// Optionally you can also configure a custom header to be sent with every request
	// Optionally if using a node-fetch polyfill or a version of fetch which supports the timeout option, you can configure the request timeout for all requests
	timeout: 500, // values set in ms
});

const MediaLibrary = ({ selectCallback, cancelCallback, type, open }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [elementSelected, selectElement] = useState(null);
	const [filtered, setFiltered] = useState([])
	const [elements, setElements] = useState([]);
	const [galleryType, setGalleryType] = useState(type);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [totalItems, setTotalItems] = useState(12);
	const [search, setSearch] = useState("");
	const [start, setStart] = useState(0);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);

	useEffect(() => {
		loadItems();
	}, []);

	const loadItems = async () => {
		tenant.ref
			.collection("medialibrary")
			.where("contentType", "==", type)
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().ref;
						const url = img ? await storage().ref(img).getDownloadURL().catch(err => "") : "";
						return { ...doc.data(), url: url, id: doc.id };
					})
				).then((res) => {
					setData(res);
					setFiltered(res)
					setElements(res);
					setLoading(false);
				});
			});
	};

	// const findPictures = (search) => {
	//     unsplash.search.photos("dogs", 1, 12, { orientation: "portrait" })
	//         .then(data => data.json())
	//         .then(json => {
	//             // Your code
	//             console.log(json)
	//             setNumberOfPages(json.total_pages)
	//             setElements(json.results.map(i => ({ url: i.urls.thumb, name: i.description, links: i.links.download })))
	//         });
	// }

	useEffect(() => {
		setGalleryType(type);
	}, []);

	useEffect(() => {
		if (filtered) {
			const number = filtered.length / totalItems;
			setNumberOfPages(number % totalItems === 0 ? number : parseInt(number) + 1);
		}
	}, [filtered]);

	const deleteFile = async (fileID) => {
		console.log("deleteFile", fileID);
		tenant.ref.collection("medialibrary").doc(fileID).delete();
		// const headers = {
		//     Authorization: `Bearer ${await localStorage.getItem("jwt")}`
		// };

		// fetch(`${Servers.URL_API}/upload/files/${fileID}`, {
		//     method: "DELETE",
		//     headers: headers
		// })
		//     .then(response => response.json())
		//     .then(data => {
		//         console.log(data);
		//         refetch()
		//     })
		//     .catch(err => {
		//         console.log(err);
		//     });
	};

	const back = () => {
		setPage(page - 1);
		setStart(start - totalItems);
	};

	const next = () => {
		setPage(page + 1);
		setStart(start + totalItems);
	};

	const navigateTo = (n) => {
		const init = (n - 1) * totalItems;
		setStart(init);
		setPage(n);
	};

	const handleSearch = (ev) => {
		setStart(0)
		setPage(1)
		setSearch(ev)
		setFiltered(data.filter((item) => item?.name?.toLowerCase().includes(ev.toLowerCase())))
	}

	return (
		<Layer onEsc={cancelCallback} position='left' full='vertical' onClickOutside={cancelCallback}>
			<Box pad='medium' height={{ min: "medium" }} flex='grow' gap='small' width='xlarge'>
				<Box direction='row' justify='between' align='center' flex='grow' justify='between' height={{ max: "50px" }}>
					<Heading level='3' margin='none'>
						Galería de medios
					</Heading>
					<Button hoverIndicator onClick={() => cancelCallback()} icon={<Close />} />
				</Box>

				<Box flex='grow' height={{ max: "50px" }}>
					<TextInput style={{ borderRadius: 7 }} placeholder='Buscar medios' value={search} onChange={(event) => handleSearch(event.target.value)} />
				</Box>

				{/* {type === "images" && <Button onClick={() => {
                    setGalleryType("unsplash")
                    findPictures()
                }} label="Unsplash" />} */}

				<Box flex='grow'>
					{filtered.length === 0 ? (
						<Box align='center' justify='center' width='100%' flex='grow'>
							<Box align='center' justify='center' width='medium' alignSelf='center' pad='medium' background='#fefefe'>
								<Heading textAlign='center' level='3'>
									{loading ? "Cargando..." : "No hay medios disponibles"}
								</Heading>
							</Box>
						</Box>
					) : (
						<Box wrap direction='row'>
							{filtered.slice(start, totalItems + start).map((file, i) => (
									<Box
										key={i}
										background={elementSelected === file?.id ? "light-4" : "light-2"}
										round='7px'
										pad={{ horizontal: "small", bottom: "small" }}
										margin='5px'
										width={"24%"}
										id='item'
										onClick={(e) => {
											if (!["svg", "path", "button"].includes(e?.target?.localName)) {
												elementSelected === file?.id ? selectElement(null) : selectElement(file?.id);
											}
										}}
										height='22vh'
										// border={elementSelected === i ? { size: "small", color: "brand" } : false}
										// height={type === "audios" ? "auto" : "calc( (100vh / 4))"}
									>
										<Box direction='row' align='center' justify='between' gap='small'>
											<CheckBox checked={elementSelected === file?.id} />
											<Text truncate>{file.name}</Text>
											<Button id='delete' hoverIndicator onClick={(e) => deleteFile(file.id)} icon={<Trash />} />
										</Box>
										{/* <Text truncate margin={{ bottom: "10px" }}>
											{file.size}
										</Text> */}
										{galleryType === "unsplash"}
										{galleryType === "images" ? (
											<Image key={file.url} src={file.url} fit='cover' />
										) : galleryType === "videos" ? (
											<Box height='100%'>
												<video
													key={file.url}
													width='100%'
													height='100%'
													controls
													//  onLoadedMetadata={dat => console.log(dat.currentTarget.duration)}
												>
													<source src={file.url} type={file.mime} />
												</video>
											</Box>
										) : galleryType === "audios" ? (
											<audio key={file.url} style={{ width: "100%" }} controls>
												<source src={file.url} />
											</audio>
										) : galleryType === "documents" ? (
											<object key={file.url} data={file.url} type='application/pdf' width='100%' height='70%'></object>
										) : (
											<Box />
										)}
									</Box>
								))}
						</Box>
					)}
				</Box>
				<Box direction='row' gap='small' flex='grow' justify='between' height={{ max: "50px" }}>
					{data && data.length > 0 && (
						<Box direction='row' gap='small'>
							<Button hoverIndicator disabled={page === 1} onClick={() => back()} icon={<Previous />} />
							<Button onClick={() => navigateTo(1)} p primary={1 === page} hoverIndicator label={1} />
							{numberOfPages > 2 && <Button
								onClick={() => (page === 1 ? null : page === numberOfPages ? navigateTo(page - 1) : navigateTo(page + 1))}
								p
								primary={page !== numberOfPages && page !== 1}
								hoverIndicator
								label={page === 1 ? 2 : page === numberOfPages ? page - 1 : page}
							/>}
							{numberOfPages > 1 && <Button onClick={() => navigateTo(numberOfPages)} p primary={numberOfPages === page} hoverIndicator label={numberOfPages} />}
							<Button hoverIndicator disabled={page === numberOfPages} onClick={() => next()} icon={<Next />} />
						</Box>
					)}

					<Button
						onClick={() => {
							// console.log(data[elementSelected])
							selectCallback(data.find((x) => elementSelected === x.id));
						}}
						primary
						disabled={elementSelected === null}
						label='Seleccionar'
					/>
				</Box>
			</Box>
		</Layer>
	);
};

export default MediaLibrary;
