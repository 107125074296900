import React from 'react';
import {
    Box,
} from "grommet";

import MediaLibrary from '../../components/MediaLibrary';


const media = () => {


    return (
        <Box >
            
        </Box>
    );
}

export default media;