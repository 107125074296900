
import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import { Box, ResponsiveContext } from 'grommet';

import Forums from './Forums'
import ForumForm from './ForumForm'
import Topics from './Topics';

import SideMenu from '../../components/SideMenu'

const RouterForums = ({ area }) => {

    const size = useContext(ResponsiveContext)
    const { url } = useRouteMatch();

    const routes = [
        {
            route: `${url}/newforum`,  
            icon: "Add",
            title: "Nuevo Foro",
            allowed: ["Admin"]
        }, {
            route: `${url}`,
            icon: "Archive",
            title: "Foros",
            allowed: ["Admin", "Teacher"]
        }
    ]

    return (
        <Box direction="row" gap="medium" justify="center" pad="medium">

            {size !== "small" &&
                <Box width="100px">
                    <SideMenu routes={routes} sidebarStatus={true} />
                </Box>
            }
            <Box flex width={{ max: "xlarge" }}>
                <Route exact path={`${url}`} component={Forums} exact />
                <Route path={`${url}/newforum`} component={ForumForm} />
                <Route path={`${url}/editforum/:id`} component={ForumForm} />
                <Route path={`${url}/manageforum/:id`} component={Topics} />
            </Box>
        </Box>
    );
}

export default RouterForums;