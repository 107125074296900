import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Button, Heading, Text, Layer, TextInput } from "grommet";
import { Task, DocumentText, CirclePlay, Trash } from "grommet-icons";

import MissionLibrary from "../../components/MissionLibrary";
import MediaHandler from "../../components/MediaHandler";

import { withRouter, useParams } from "react-router";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AuthContext from "../../context/AuthContext";

import { firestore, storage } from "firebase";

const ChallengeForm = ({ history }) => {
	const { id } = useParams();

	const { userData, tenant } = useContext(AuthContext);

	const [publishModal, tooglePublishModal] = useState(false);
	const [missionsModal, setMissionsModal] = useState(false);

	const [image, setImage] = useState(null);

	const [data, setData] = useState({
		name: "",
		image: "",
		missions: [],
		content: [],
		notification: false,
	});

	useEffect(() => {
		id !== undefined && getData();
	}, []);

	const getData = () => {
		tenant.ref
			.collection("itineraries")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// SI HAY IMAGEN CONSIGUE LA URL PUBLICA
					const img = doc.data().image;
                    console.log(img)
					img
						? await storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => setImage(res))
								.catch((err) => {
                                    console.log("ASD")
                                    setImage("https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg")
                                })
						: setImage("https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg");

					setData({
						...data,
						...doc.data(),
					});
				} else {
					console.log("No such document!");
				}
			})
			.catch(function (error) {
				console.log("Error getting document:", error);
			});
	};

	const publishItinerary = async () => {
		let variables = {
			...data,
			name: data.name,
			image: data.image,
			content: data.content,
			missions: data.content.map((el) => el.id),
		};

		tenant.ref
			.collection("itineraries")
			.add(variables)
			.then((response) => {
				console.log(response);
				history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/itineraries`);
			});
	};

	const editItinerary = async () => {
		let variables = {
			...data,
			name: data.name,
			image: data.image,
			content: data.content,
			missions: data.content.map((el) => el.id),
		};
		console.log(variables);
		tenant.ref
			.collection("itineraries")
			.doc(id)
			.update(variables)
			.then((response) => {
				console.log(response);
				history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/itineraries`);
			});
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const grid = 8;

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		borderRadius: 12,
		padding: grid * 2,
		margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "#dde6ed",
		padding: grid,
		borderRadius: 12,
		width: "100%",
	});

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(data.content, result.source.index, result.destination.index);

		setData({
			...data,
			content: items,
		});
	};

	const deleteContent = (contentID) => {
		const newarr = data.content.filter((el) => el.id !== contentID);
		console.log(contentID);
		setData({
			...data,
			content: newarr,
		});
	};

	const addMission = (cb) => {
		console.log(cb);
		const newcontents = cb.map((el) => {
			// console.log( el.image ? el.image.url.split("/")[5].split('?')[0] : null)
			return {
				id: el.id,
				score: el.score,
				type: el.type,
				title: el.title,
				image: el.image ? el.image : null,
				description: el.description,
				content: el.content,
			};
		});
		const newarr = [...data.content, ...newcontents];
		setData({
			...data,
			content: newarr,
		});
	};

	return (
		<Box>
			<Box direction='row-responsive' gap='medium'>
				<Box basis='2/3' gap='medium'>
					{/* TITULO */}
					<Box background='white' overflow='hidden' round='small' elevation='medium'>
						<Box pad='small'>
							<Heading color='gray' level='4' margin='none'>
								Título del Itinerario
							</Heading>
						</Box>

						<Box pad={{ left: "small", right: "small" }}>
							<TextInput
								plain
								size='xlarge'
								placeholder='Escribe aquí...'
								value={data.name}
								onChange={(event) => {
									setData({ ...data, name: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO */}

					{/* REPETICIONES */}
					<Box background='white' overflow='hidden' round='small' elevation='medium'>
						<Box pad='small'>
							<Heading color='gray' level='4' margin='none'>
								Misiones
							</Heading>
						</Box>

						<Box pad='small'>
							{data.content.length === 0 ? (
								<Box pad='small' round='small' background='light-2' align='center'>
									<Text textAlign='center'>Sin Misiones</Text>
								</Box>
							) : (
								<DragDropContext onDragEnd={(ev) => onDragEnd(ev)}>
									<Droppable droppableId='droppable'>
										{(provided, snapshot) => (
											<Box {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
												{data.content.map((content, i) => (
													<Draggable key={content.id} draggableId={content.id} index={i}>
														{(provided, snapshot) => (
															<Box
																justify='between'
																pad='small'
																round='small'
																direction='row'
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
																<Box direction='row' gap='small' align='center'>
																	{content.type === "EXAM" ? <Task /> : content.type === "VIDEO" ? <CirclePlay /> : <DocumentText />}

																	<Text>{content.title}</Text>
																</Box>
																{/* <Box height="small" width="small" background="red">
                                                                    {JSON.stringify(content.image)}
                                                                    <Image fit="cover" source={content.image && content.image.url} />
                                                                </Box> */}
																<Button hoverIndicator icon={<Trash />} onClick={() => deleteContent(content.id)} />
															</Box>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</Box>
										)}
									</Droppable>
								</DragDropContext>
							)}
						</Box>
					</Box>

					<Box align='center'>
						<Button primary onClick={() => setMissionsModal(true)} label='Añadir Misiones' />
					</Box>
					{/* FIN REPETICIONES */}
				</Box>

				<Box basis='1/3' gap='medium'>
					{/* BOTONES PUBLICACION */}
					<Box background='white' overflow='hidden' round='small' elevation='medium'>
						<Box pad='small'>
							<Heading color='gray' level='4' margin='none'>
								Publicación
							</Heading>
						</Box>

						<Box pad='small' gap='small'>
							<Button disabled={data.name !== "" ? false : true} primary label={id ? "Actualizar Itinerario" : "Crear Itinerario"} onClick={() => tooglePublishModal(true)} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}

					{/* IMAGEN DESTACADA */}
					<Box background='white' pad='small' round='small' elevation='medium'>
						<MediaHandler
							type='images'
							value={image}
							height='300px'
							title='Imagen Destacada'
							uploadCallback={(ev) => setData({ ...data, image: ev })}
							galleryCallback={(ev) => setData({ ...data, image: ev.ref })}
							durationCallback={(ev) => console.log(ev)}
						/>
					</Box>
					{/* FIN IMAGEN DESTACADA */}
				</Box>
			</Box>

			{/* MODALES */}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width='medium' pad='medium' gap='medium' align='center' justify='center'>
						<Heading level='3' margin='none'>
							{id ? "Actualizar" : "Publicar"} itinerario
						</Heading>
						<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este itinerario, ¿estás seguro que deseas hacerlo?</Text>

						<Box direction='row' gap='small' width='100%' justify='between'>
							<Button label='Cancelar' onClick={() => tooglePublishModal(!publishModal)} />
							<Button
								primary
								label={id ? "Actualizar" : "Publicar"}
								onClick={() => {
									tooglePublishModal(!publishModal);
									id ? editItinerary() : publishItinerary();
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{missionsModal && (
				<MissionLibrary
					closeCallback={() => setMissionsModal(false)}
					submitCallback={(cb) => {
						addMission(cb);
						setMissionsModal(false);
					}}
				/>
			)}
			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ChallengeForm);
