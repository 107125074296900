import React, { useState } from 'react';
import {
    Box,
    Button,
    Layer,
    Calendar,
    MaskedInput,
    Keyboard,
} from 'grommet';
import moment from 'moment'

const ScheduleModal = ({ prevDate, dateCallback, cancelCallback, limitInit }) => {

    const [date, setDate] = useState(prevDate === "" ? moment().format() : moment(prevDate).format())
    const [time, setTime] = useState(prevDate === "" ? moment().format("HH:MM") : moment(prevDate).format("HH:MM"))
    const [displayTime, setDisplayTime] = useState(prevDate === "" ? moment().format("h:mm A") : moment(prevDate).format("h:mm A"));
    // console.log((new Date()).toISOString())
    return (
        <Layer
            onEsc={cancelCallback}
            onClickOutside={cancelCallback}
        >
            <Box pad="medium" gap="medium">

                <Calendar
                    date={date ?? (new Date()).toISOString()}
                    bounds={limitInit ? [moment(limitInit).format(), moment(limitInit).add('years', 10).format()] : [moment().subtract('years', 10).format(), moment().add('years', 10).format()]}
                    onSelect={ev => {
                        setDate(ev)
                        // console.log(ev)
                    }}
                    firstDayOfWeek={1}
                    locale={"es-ES"}
                    showAdjacentDays={false}
                />

                <Box gap="medium">
                    <Keyboard
                        onEnter={event => {
                            event.preventDefault(); // so drop doesn't re-open
                            // close();
                        }}
                    >
                        <MaskedInput
                            mask={[
                                {
                                    length: [1, 2],
                                    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
                                    regexp: /^1[1-2]$|^[0-9]$/,
                                    placeholder: "hh"
                                },
                                { fixed: ":" },
                                {
                                    length: 2,
                                    options: ["00", "15", "30", "45"],
                                    regexp: /^[0-5][0-9]$|^[0-9]$/,
                                    placeholder: "mm"
                                },
                                { fixed: " " },
                                {
                                    length: 2,
                                    options: ["am", "pm"],
                                    regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                                    placeholder: "am/pm"
                                }
                            ]}
                            value={displayTime}
                            name="maskedInput"
                            onChange={event => {
                                setDisplayTime(event.target.value)
                                setTime(moment(event.target.value, ["h:mm A"]).format("HH:mm"))
                            }}
                        />
                    </Keyboard>
                    <Box direction="row" justify="between" gap="small">
                        <Button onClick={cancelCallback} label="Cancelar" />
                        <Button primary disabled={!time} label="Guardar Fecha" onClick={() => {
                            // console.log(moment(moment(date).format("YYYY-MM-DD") + "T" + time).format());
                            dateCallback(moment(moment(date).format("YYYY-MM-DD") + "T" + time).format());
                        }} />
                    </Box>
                </Box>
            </Box>
        </Layer>
    );
}

export default ScheduleModal;