import React, { useEffect } from "react";
import { useField } from "formik";
import { CheckBox } from "grommet";

const CheckboxField = ({ label, icon, type, callback, clickCallback, ...props }) => {
	const [field, meta, helpers] = useField(props);

	return (
		<CheckBox
			{...field}
			{...props}
			checked={field.value}
			label={label}
			onChange={(event) => {
				clickCallback && callback()
				helpers.setValue(event.target.checked);
			}}
		/>
	);

	// <Button {...field} {...props} size="small" plain={false} tip={{ dropProps: { background: "white" }, content: label }} icon={icon} onClick={() => helpers.setValue(type)} primary={field.value === type} />
};

export default CheckboxField;
