import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import { Text, Box, TextInput, Button, Image, Keyboard } from "grommet";
import logo from '../assets/images/ibclogo.png'

import { get } from 'lodash'
import AuthContext from "../context/AuthContext";

import { auth } from "firebase"

const Login = ({ history }) => {
  // Declare a new state variable, which we'll call "count"
  const [username, setUser] = useState("");
  const [password, setPass] = useState("");
  const [email, setEmail] = useState("");
  const [wrongPass, setWrongPass] = useState(false);
  const [wrongUser, setWrongUser] = useState(false);
  const [checking, setChecking] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [reset, setReset] = useState(false)

  const { authFunctions, userData, state, tenant } = useContext(AuthContext)

  const signIn = async () => {
    setChecking(true)
    authFunctions.signIn({ identifier: username, password }).then(res => {
      setChecking(false)
    })

  }

  useEffect(() => {
    if (state.authed) {
      userData?.role === "Admin" || userData?.role === "Teacher" && history.replace(`${process.env.PUBLIC_URL}/${tenant.url}/smartlearning/courses`);
    } else {

    }
  }, [state])

  const resetFunc = () => {
    auth().sendPasswordResetEmail(email).then((a) => {
      // Email sent.
      setSuccess("El correo electrónico se ha enviado correctamente. Revisa tu bandeja de entrada, en breves momentos podrás recuperar tu contraseña.")
      setEmail("")
      setReset(!reset)
    }).catch((error) => {
      // An error happened.
      setError(error)
    });
  }


  return (
    <Box fill="vertical" flex={"grow"} justify="center" background={{ color: "black", image: "url(https://images.unsplash.com/photo-1509023464722-18d996393ca8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80)", size: "cover" }} style={{ position: "relative" }}>
      <Box background="black" style={{ zIndex: 1, opacity: .5, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, height: "100%", width: "100%" }} />
      <Box background="#F4F6F9" style={{ zIndex: 2 }} elevation="large" fill="vertical" width="medium" height="medium" elevation="large" round={{ size: "small", corner: "right" }} gap="small" pad="small">
        <Box height="50%">
          <Image fit="cover" src="https://cdn.dribbble.com/users/981961/screenshots/5826652/secure_4x.png?compress=1&resize=1200x900" />
        </Box>
        <Keyboard onEnter={() => !reset ? signIn() : resetFunc()} >
          <Box gap="small">
            {!reset ? <>
              <TextInput
                id="user"
                type="text"
                value={username}
                placeholder="Email..."
                onChange={event => {
                  setUser(event.target.value);
                  setWrongPass(false);
                }}

              />
              <TextInput
                id="pass"
                type="password"
                value={password}
                placeholder="Contraseña..."
                onChange={event => {
                  setPass(event.target.value);
                  setWrongPass(false);
                }}
              />
            </> : <TextInput
                id="email"
                type="email"
                value={email}
                placeholder="Email"
                onChange={event => setEmail(event.target.value)}
              />}

            <Button disabled={checking} onClick={() => !reset ? signIn() : resetFunc()} primary color="brand" label={!reset ? (checking ? "Accediendo..." : "Acceder") : "Recuperar contraseña"} />

            {error !== false && (
              <Box onClick={() => setError(false)}>
                <Text>{error}</Text>
              </Box>
            )}

            {success !== false && (
              <Box onClick={() => setSuccess(false)}>
                <Text color="#4BB543">{success}</Text>
              </Box>
            )}

            <Box align="center" onClick={() => setReset(!reset)}>
              <Text>{!reset ? "Recuperar Contraseña" : "Login"}</Text>
            </Box>

          </Box>
        </Keyboard>
      </Box>

    </Box>
  );
};

export default withRouter(Login);
