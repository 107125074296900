import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select } from "grommet";
import { Close, Trash, DocumentText, CirclePlay, Task, Music, DocumentPdf } from "grommet-icons";
import ReactTable from "react-table";

import ScheduleModal from "../../components/ScheduleModal";

import { withRouter, useParams } from "react-router";
import MediaHandler from "../../components/MediaHandler";
import { Editor } from "@tinymce/tinymce-react";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const CategoryForm = ({ history }) => {
	const [loadingMedia, setLoadingMedia] = useState(false);
	const [publishModal, tooglePublishModal] = useState(false);
	const [scheduleModal, toogleScheduleModal] = useState(false);
	const [categories, setCategories] = useState([]);
	const [usergroups, setUserGroups] = useState([]);
	const [contents, setContents] = useState([]);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [contentIDToDelete, setContentIDToDelete] = useState("");

	const { id } = useParams();
	const { userData, tenant } = useContext(AuthContext);

	const [image, setImage] = useState(null);

	const [richEditor, setRichEditor] = useState(null);

	const [data, setData] = useState({
		name: "",
		childCategories: [],
		usergroups: [],
		notification: false,
		active: true,
		image: "",
		draft: false,
	});

	useEffect(() => {
		id === undefined && setRichEditor("");
		getGroups();
	}, []);

	const getCategories = async (grps) => {
		tenant.ref
			.collection("categories")
			.get()
			.then((querySnapshot) => {
				const cats = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setCategories(cats);
				id !== undefined && getData(cats, grps);
			});
	};

	const getGroups = () => {
		tenant.ref
			.collection("usergroups")
			.get()
			.then((querySnapshot) => {
				const grps = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				setUserGroups(grps);
				getCategories(grps);
			});
	};

	const getData = (cats, grps) => {
		tenant.ref
			.collection("categories")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// SI HAY IMAGEN CONSIGUE LA URL PUBLICA
					const img = doc.data().image;
					img &&
						storage()
							.ref(img)
							.getDownloadURL()
							.then((url) => setImage(url));

					// COGE LAS CATEGORIAS HIJAS Y LAS FILTRA CON TODAS
					const childCats = doc.data().childCategories;
					const postGroups = doc.data().usergroups;

					setData({
						...data,
						...doc.data(),
						childCategories: childCats ? cats.filter((cat) => childCats.map((c) => c.id).includes(cat.id)) : [],
						usergroups: grps.filter((gr) => postGroups.map((c) => c.id).includes(gr.id)),
					});

					getContents(doc);
					setRichEditor(doc.data().description);
				} else {
					console.log("No such document!");
				}
			})
			.catch(function (error) {
				console.log("Error getting document:", error);
			});
	};

	const getContents = (cat) => {
		cat.ref
			.collection("contents")
			.orderBy("publishDate", "desc")
			.onSnapshot((snapShot) => {
				Promise.all(
					snapShot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png")
							: "https://i1.wp.com/autohub.de/wp-content/uploads/2019/08/placeholder.png";
						const content = {
							...doc.data(),
							image: url,
							id: doc.id,
						};
						return content;
					})
				).then((contents) => {
					setContents(contents);
				});
			});
	};

	const postCategory = async () => {
		const childCatRefs = data.childCategories.map((cat, i) => tenant.ref.collection("categories").doc(cat.id));
		const grpRefs = data.usergroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id));
		let variables = {
			...data,
			childCategories: childCatRefs,
			usergroups: grpRefs,
			name: data.name,
			description: richEditor,
			active: data.active,
			image: data.image,
		};

		tenant.ref
			.collection("categories")
			.add(variables)
			.then((res) => {
				const categoryID = res.id;
				console.log("Post Actualizado");
				Promise.all(
					childCatRefs.map((ref) => {
						ref.update({
							parentCategories: firestore.FieldValue.arrayUnion(tenant.ref.collection("categories").doc(categoryID)),
						})
							.then(() => {
								console.log("Categoria Escritas: " + ref.id);
							})
							.catch((err) => console.log(err));
					})
				).then((res) => {
					Promise.all(
						grpRefs.map((ref) => {
							ref.update({
								categories: firestore.FieldValue.arrayUnion(tenant.ref.collection("categories").doc(categoryID)),
							})
								.then(() => {
									console.log("Actualizado Grupo: " + ref.id);
								})
								.catch((err) => console.log(err));
						})
					).then((res) => {
						console.log("All Good");
						history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/content/categories`);
					});
				});
			})
			.catch((err) => console.log(err));
		// .then(res => {

		//   Promise.all(grpRefs.map(ref => {
		//     ref.update({
		//       parentCategories: firestore.FieldValue.arrayUnion(firestore().collection("categories").doc(categoryID))
		//     }).then(
		//       Promise.all(grpRefs(ref => {
		//         ref.update({
		//           categories: firestore.FieldValue.arrayUnion(firestore().collection("categories").doc(categoryID))
		//         }).then(() => {
		//           console.log('Actualizado Grupo')
		//         }).catch(
		//           err => console.log(err)
		//         );
		//       })).then(res => console.log("written"))
		//     ).catch(
		//       err => console.log(err)
		//     );
		//   })).then(res => {
		//     history.push('/content/categories')
		//   })
		// }
		// ).catch(
		//   err => console.log(err)
		// );
	};

	const editCategory = async () => {
		const childCatRefs = data.childCategories.map((cat, i) => tenant.ref.collection("categories").doc(cat.id));
		const grpRefs = data.usergroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id));
		let variables = {
			...data,
			childCategories: childCatRefs,
			usergroups: grpRefs,
			name: data.name,
			description: richEditor,
			active: data.active,
			image: data.image,
		};
		tenant.ref
			.collection("categories")
			.doc(id)
			.update(variables)
			.then((res) => {
				console.log("Post Actualizado");
				Promise.all(
					childCatRefs.map((ref) => {
						ref.update({
							parentCategories: firestore.FieldValue.arrayUnion(tenant.ref.collection("categories").doc(id)),
						})
							.then(() => {
								console.log("Categoria Escritas: " + ref.id);
							})
							.catch((err) => console.log(err));
					})
				).then((res) => {
					Promise.all(
						grpRefs.map((ref) => {
							ref.update({
								categories: firestore.FieldValue.arrayUnion(tenant.ref.collection("categories").doc(id)),
							})
								.then(() => {
									console.log("Actualizado Grupo: " + ref.id);
								})
								.catch((err) => console.log(err));
						})
					).then((res) => {
						console.log("All Good");
						history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/content/categories`);
					});
				});
			})
			.catch((err) => console.log(err));
	};

	return (
		<Box>
			<Box direction="row-responsive" gap="medium">
				<Box pad={{ vertical: "medium" }} basis="2/3" gap="medium">
					{/* TITULO DE LA PUBLICACIÓN */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Título de la Categoría
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<TextInput
								plain
								size="large"
								placeholder="Escribe aquí..."
								value={data.name}
								onChange={(event) => {
									setData({ ...data, name: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO DE LA PUBLICACIÓN */}

					{/* CONTENIDO */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Descripción
							</Heading>
						</Box>

						<Box pad="small">
							{richEditor !== null && (
								<Editor
									initialValue={richEditor}
									init={{
										height: 300,
										menubar: false,
										branding: false,
										menubar: "file edit view insert format tools table tc help",
										plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
										toolbar: "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
									}}
									onEditorChange={setRichEditor}
									apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
								/>
							)}
						</Box>
					</Box>

					{/* SELECCIONAR CATEGORIA  */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Categorías Hija
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Select labelKey="name" valueKey="id" multiple placeholder="Select" options={id ? categories.filter((cat) => id !== cat.id) : categories} onChange={({ value: nextValue }) => setData({ ...data, childCategories: nextValue })} value={data.childCategories} />

							<Box gap="small">
								{data.childCategories.length === 0 ? (
									<Box align="center" fill justify="center">
										<Text textAlign="center">Sin Categorías Seleccionadas</Text>
									</Box>
								) : (
									data.childCategories.map((cat, i) => (
										<Button
											key={i}
											onClick={() => {
												setData({ ...data, childCategories: data.childCategories.filter((el) => el.id !== cat.id) });
											}}
											label={cat.name}
											primary
											color="accent-2"
											icon={<Close />}
										/>
									))
								)}
							</Box>
						</Box>
					</Box>
					{/* FIN SELECCIONAR CATEGORIA */}
					{/* FIN CONTENIDO */}

					{/* Contents */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Contenidos
							</Heading>
						</Box>

						<Box gap="small" pad="small">
							<ReactTable
								data={contents}
								defaultPageSize={10}
								loadingText="Cargando Contenidos..."
								previousText="Contenidos Anteriores"
								nextText="Contenidos Siguientes"
								noDataText="Sin Contenidos"
								pageText="Página"
								ofText="de"
								rowsText="filas"
								columns={[
									{
										Header: "Imagen",
										headerStyle: { padding: 15 },
										id: "image",
										sortable: false,
										width: 100,
										style: { display: "flex", justifyContent: "center", padding: 15 },
										accessor: (datum) => (
											<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
												<Image fit="cover" src={datum.image} />
											</Box>
										),
									},
									{
										Header: "Título",
										headerStyle: { padding: 15 },
										id: "title",
										style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
										accessor: (datum) => (
											<Box>
												<Text>{datum.title}</Text>
											</Box>
										),
									},
									{
										Header: "Tipo",
										id: "type",
										headerStyle: { padding: 15 },
										sortable: false,
										width: 100,
										style: { display: "flex", justifyContent: "center", alignItems: "center" },
										accessor: (datum) => (datum.contentType === "TEXT" ? <DocumentText /> : datum.contentType === "VIDEO" ? <CirclePlay /> : datum.contentType === "EXAM" ? <Task /> : datum.contentType === "AUDIO" ? <Music /> : <DocumentPdf />),
									},
									{
										Header: "Eliminar",
										headerStyle: { padding: 15 },
										id: "delete",
										width: 100,
										resizable: false,
										sortable: false,
										style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
										accessor: (datum) => (
											<Button
												color="gray"
												hoverIndicator
												icon={<Trash />}
												onClick={() => {
													setContentIDToDelete(datum);
													toogleDeleteModal(!deleteModal);
												}}
											/>
										),
									},
								]}
							/>
						</Box>
					</Box>
				</Box>

				<Box basis="1/3" gap="medium">
					{/* BOTONES PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Publicación
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Button disabled={data.name !== "" ? false : true} primary label={id ? "Actualizar Categoría" : "Publicar Categoría"} onClick={() => tooglePublishModal(true)} />
						</Box>
						<Box pad="small">
							<CheckBox checked={!data.active} label="Ocultar Categoría" onChange={(event) => setData({ ...data, active: event.target.checked })} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}

					{/* IMAGEN DESTACADA */}
					<Box background="white" pad="small" round="small" elevation="medium">
						<MediaHandler type="images" value={image} height="250px" title="Imagen Destacada" uploadCallback={(ev) => setData({ ...data, image: ev })} galleryCallback={(ev) => setData({ ...data, image: ev.ref })} durationCallback={(ev) => console.log(ev)} />
					</Box>
					{/* FIN IMAGEN DESTACADA */}

					{/* BLOQUE PUBLICACION */}
					<Box background="white" pad="medium" gap="medium" round="small" elevation="medium">
						<Box>
							<Heading color="titles" level="4" margin="none">
								Filtrar Usuarios
							</Heading>
						</Box>

						<Box gap="medium">
							{/* <CheckBox
                checked={data.notification}
                label="Categoría Pública"
                onChange={event =>
                  setData({ ...data, notification: event.target.checked })
                }
              /> */}

							<Collapsible open={!data.notification}>
								<Box gap="medium">
									<Heading color="gray" level="4" margin="none">
										Grupo de Usuarios
									</Heading>

									<Select labelKey="name" valueKey="id" multiple placeholder="Selecciona..." options={usergroups} onChange={({ value: nextValue }) => setData({ ...data, usergroups: nextValue })} value={data.usergroups} />
									<Box gap="small">
										{data.usergroups.length === 0 ? (
											<Box align="center" fill justify="center">
												<Text textAlign="center">Sin Grupos Seleccionadas</Text>
											</Box>
										) : (
											data.usergroups.map((gr, i) => (
												<Button
													key={i}
													onClick={() => {
														setData({ ...data, usergroups: data.usergroups.filter((el) => el.id !== gr.id) });
													}}
													label={gr.name}
													primary
													color="accent-2"
													icon={<Close />}
												/>
											))
										)}
									</Box>
								</Box>
							</Collapsible>
						</Box>
					</Box>
					{/* FIN BLOQUE PUBLICACION */}
				</Box>
			</Box>

			{/* MODALES */}

			{loadingMedia && (
				<Layer>
					<Box width="small" height="small" align="center" justify="center">
						<Text>Cargando</Text>
					</Box>
				</Layer>
			)}

			{scheduleModal && (
				<ScheduleModal
					prevDate={data.publishDate}
					cancelCallback={() => {
						setData({ ...data, postSchedule: false, draft: true });
						toogleScheduleModal(!scheduleModal);
						console.log(data.postSchedule);
					}}
					// toogleScheduleModal={() => toogleScheduleModal(!scheduleModal)}

					dateCallback={(ev) => {
						setData({ ...data, publishDate: ev, postSchedule: true, draft: false });
						toogleScheduleModal(!scheduleModal);
					}}
				/>
			)}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" pad="medium" gap="medium" align="center" justify="center">
						<Heading level="3" margin="none">
							{id ? "Actualizar" : "Publicar"} categoría
						</Heading>
						<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este categoría, ¿estás seguro que deseas hacerlo?</Text>

						<Box direction="row" gap="small" width="100%" justify="between">
							<Button label="Cancelar" onClick={() => tooglePublishModal(!publishModal)} />
							<Button
								primary
								label={id ? "Actualizar" : "Publicar"}
								onClick={() => {
									tooglePublishModal(!publishModal);
									id ? editCategory() : postCategory();
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{console.log({ contentIDToDelete })}

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este contenido de esta categoría?
							</Heading>
							<Text>Esta acción solo borrará el contenido de la categoría</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									tenant.ref.collection("categories").doc(id).collection("contents").doc(contentIDToDelete?.id).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(CategoryForm);
