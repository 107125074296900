import React, { useContext } from "react";
import { useField } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { Box, Heading } from "grommet";
import AuthContext from "../../context/AuthContext";

const RichEditorField = ({ label, icon, type, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const { customization } = useContext(AuthContext);

	return (
		<Box>
			<Box margin={{ bottom: "10px" }}>
				<Heading color={customization.mainColor} level="4" margin="none">
					Contenido
				</Heading>
			</Box>

			<Editor
				// {...field}
				// {...props}
				id={field.name}
				value={field.value}
				initialValue={"<p/>"}
				onLoadContent={() => console.log("CARGAR", true)}
				init={{
					height: 500,
					menubar: false,
					branding: false,
					menubar: "file edit view insert format tools table tc help",
					plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
					toolbar: "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
				}}
				onEditorChange={(ev) => {
					helpers.setValue(ev);
				}}
				apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
			/>
		</Box>
	);

	// <Button {...field} {...props} size="small" plain={false} tip={{ dropProps: { background: "white" }, content: label }} icon={icon} onClick={() => helpers.setValue(type)} primary={field.value === type} />
};

export default RichEditorField;
