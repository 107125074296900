import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import { Box, Button, Heading, Text, Layer, Image, TextInput, Select, CheckBox } from "grommet";
import { Close } from "grommet-icons";

import Papa from "papaparse";

import { withRouter, useParams } from "react-router";
import ReactTable from "react-table";
import AuthContext from "../../context/AuthContext";

import firebase, { firestore } from "firebase";
import axios from "axios";

const ImportData = ({ location, history }) => {
	const { id } = useParams();

	const fileInput = useRef();
	const { tenant } = useContext(AuthContext);

	const [users, setUsers] = useState([]);
	const [usersData, setUsersData] = useState([]);
	const [extraData, setExtraData] = useState([]);

	// useEffect(() => {
	// 	console.log(usersData);
	// 	if (usersData) {
	// 		const filterarr = usersData.users;
	// 		// .filter(u => !u.role)
	// 		// .map(u => {
	// 		//     editUser({variables: { userID: u.id, customization: "5e5443674474c570da8f5df8"}})
	// 		// })
	// 		console.log(filterarr);
	// 	}
	// }, [usersData]);

	const [userForm, setUserForm] = useState({
		name: "",
		image: {},
		surname: "",
		username: "",
		email: "",
		password: null,
		repeatPassword: null,
		userGroups: [],
		customization: "5e55137efbbc064fbd0beb24",
		customAttributes: {},
		appAccess: {
			content: false,
			itinerary: false,
			profile: false,
			focus: false,
			forum: false,
			challenges: false,
			smartlearning: false,
		},
		blocked: false,
	});
	const [loadingUsers, setLaodingUsers] = useState(false);
	const [masterGroup, setMasterGroup] = useState({ id: "" });
	const [dataToLoad, setDataToLoad] = useState([]);
	const [importForm, setImportForm] = useState({
		appAccess: {
			content: false,
			itinerary: false,
			profile: false,
			focus: false,
			forum: false,
			challenges: false,
			smartlearning: false,
		},
	});

	const [data, setData] = useState([]);

	const changePermissions = async (u) => {
		const zonas = {
			content: false,
			itinerary: false,
			profile: true,
			focus: false,
			forum: true,
			challenges: false,
			smartlearning: true,
		};
		firebase
			.firestore()
			.collection("users")
			.where("round", "==", 1)
			.get()
			.then((docs) => {
				docs.docs.map((el) => {
					console.log(el.data().email);
					el.ref
						.update(zonas)
						.then((res) => console.log("good"))
						.catch((el) => console.log("bad"));
				});
			});
	};

	const createUser = async (u) => {
		console.log(u);

		const variables = {
			email: u.email,
			password: u.password,
			name: u.name,
			disabled: u.disabled,
		};
		const p = await axios
			.post("https://europe-west1-uno-work.cloudfunctions.net/createUser", {
				method: "POST",
				body: JSON.stringify(variables),
			})
			.then((response) => {
				// console.log("Success:", response);
				if (!response.data.code) {
					console.log("bien");
					return firestore()
						.collection("users")
						.doc(response.data)
						.set({
							...u,
							zonas: importForm.appAccess,
							image: tenant.id + "/users/" + response.data + "/profilePicture",
							points: 0,
						})
						.then((res) => "GOOD: " + u.email)
						.catch((err) => {
							console.log(err);
							return "ERROR: " + u.email;
						});
				} else {
					console.log("error");
					return "ERROR: " + u.email;
				}
				// if (response.data.code === "auth/invalid-password") {
				// 	alert("La contraseña debe tener al menos 6 caracteres");
				// }
				// if (response.data.code === "auth/invalid-email") {
				// 	alert("El formato del email no es el adecuado");
				// }
				// if (response.data.code === "auth/email-already-exists") {
				// 	alert("El email introducido ya se encuentra en la Base de Datos");
				// }
				// setLoading(false);
			})
			.catch((error) => {
				// console.log(error);
				return "ERROR: " + u.email;
			});

		return p;
	};

	// const updateUser = () => {};
	const createUserGroup = () => {};

	const newUser = (user) => {
		const appAccess = {
			content: true,
			itinerary: true,
			farming: false,
			profile: true,
			focus: false,
			forum: true,
			challenges: false,
			smartlearning: true,
		};

		users.map((user) => {
			const variables = {
				username: user.email,
				email: user.email,
				password: "lapolar",
				blocked: false,
				name: user.name,
				surname: user.surname,
				role: "5e5443674474c570da8f5df8",
				customization: "5e55137efbbc064fbd0beb24",
				appAccess: appAccess,
				userGroups: userForm.userGroups.map((gr) => gr.id),
			};

			createUser({ variables })
				.then((res) => {
					console.log(res);
					history.push("/users");
				})
				.catch((err) => console.log(err));
		});
	};

	const loadCSV = (e) => {
		const file = e.target.files[0];
		// const fileURL = URL.createObjectURL(file);
		Papa.parse(file, {
			header: true,
			keepEmptyRows: false,
			skipEmptyLines: false,
			complete: async (results) => {
				console.log(results.data);
				const me = results.data.map((u, i) => {
					const test = Object.values(u);
					i === 0 && console.log(test)
					// const group = data.usergroups.filter((e) => e.name === test[2])[0];
					// console.log(data.usergroups.filter(e => e.name === test[2]))
					const pass = Math.random().toString(36).slice(-8);
					const variables = {
						name: test[1]?.trim(),
						surname: test[2]?.trim(),
						email: test[6]?.toLocaleLowerCase().trim(),
						username: test[6]?.toLocaleLowerCase().trim(),
						password: pass,
						repeatPassword: pass,
						role: "User",
						confirmed: true,
						blocked: false,
						customAttributes: {
							society: test[4]?.trim(),
							cif: test[5]?.trim(),
							dni: test[0]?.trim(),
							group: test[8],
						},
						usergroups: [],
						tenants: [tenant.ref],
					};
					// createUser({ variables }).then(c => console.log(c)).catch(e => console.log(e))
					return variables;
				});
				setExtraData(["society", "cif", "dni", "group"]);
				console.log(me);
				setDataToLoad(me);

				// CREA LOS GRUPOS EN BASE A LA INFO DEL CSV
				// const ne = results.data.map(e => e.GERENCIA)
				// const te = new Set(ne)
				// const le = [...te]
				// console.log(le)
				// le.map(el => {
				//     createUserGroup({ variables: { name: el } })
				//         .then(d => console.log(d))
				//         .catch(e => console.log(e))
				// })
				// FIN CREA LOS GRUPOS EN BASE A LA INFO DEL CSV

				// te.map(el => console.log(el))
				// const parsed = results.data.map((question, i) => {
				//     var keys = Object.keys(question).filter(el => el !== "question").filter(el => el !== "correctAnswer")
				//     const answers = keys.map((key, i) => question[key] !== "" ? { text: question[key], correctAnswer: question.correctAnswer === key } : null)
				//     return {
				//         question: question.question,
				//         answers: answers.filter(el => el !== null)
				//     }
				// })
				// setExamData({ ...examData, advance: parsed })
			},
		});
	};

	return (
		<Box direction="row" gap="medium">
			<Box basis="2/3" direction="column" gap="medium">
				<Box background="white" pad="medium" round="small" elevation="medium">
					<Box>
						<Heading color="gray" level="4" margin="none">
							Categoría Maestra
						</Heading>
					</Box>

					{/* <Select labelKey="name" valueKey="id" placeholder="Selecciona..." options={data && data.usergroups} onChange={({ value }) => setMasterGroup(value)} value={masterGroup} /> */}
				</Box>

				<Box background="white" overflow="hidden" round="small" elevation="medium">
					<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
						<Heading color="gray" level="3" margin="none">
							Importación
						</Heading>

						<Box justify="end">
							<Button color="brand" primary onClick={() => fileInput.current.click()} label="Carga Masiva" />
							<input type="file" accept=".csv" ref={fileInput} onChange={(e) => loadCSV(e)} style={{ display: "none" }} />
						</Box>
					</Box>

					<Box>
						{data && (
							<ReactTable
								data={dataToLoad}
								defaultPageSize={10}
								// loading={loading}
								loadingText="Cargando Grupos..."
								previousText="Grupos Anteriores"
								nextText="Grupos Siguientes"
								noDataText="Sin Grupos"
								pageText="Página"
								ofText="de"
								rowsText="filas"
								columns={[
									{
										Header: "Nombre",
										id: "name",
										headerStyle: { padding: 15 },
										width: 200,
										style: { paddingLeft: 15, fontSize: 10, display: "flex", justifyContent: "left", alignItems: "center" },
										accessor: (datum) => datum.name,
									},
									{
										Header: "Apellidos",
										id: "surname",
										headerStyle: { padding: 15 },
										width: 300,
										style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
										accessor: (datum) => datum.surname,
									},
									{
										Header: "Username",
										id: "username",
										headerStyle: { padding: 15 },
										width: 200,
										style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
										accessor: (datum) => datum.email,
									},
									{
										Header: "Email",
										id: "email",
										headerStyle: { padding: 15 },
										width: 200,
										style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
										accessor: (datum) => datum.email,
									},
									...extraData.map((el) => {
										return {
											Header: el,
											id: el,
											width: 200,
											style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
											accessor: (datum) => {

												return datum.customAttributes[el]
											},
										};
									}),

									// ...(dataToLoad.length > 0 && {

									// }),
									// {
									// 	Header: "Nivel",
									// 	id: "level",
									// 	headerStyle: { padding: 15 },
									// 	width: 400,
									// 	style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
									// 	accessor: (datum) => datum.level,
									// },
									// {
									// 	Header: "Grupos de usuarios",
									// 	id: "groups",
									// 	headerStyle: { padding: 15 },
									// 	width: 200,
									// 	// style: { display: "flex", fontSize: 10, justifyContent: "center", alignItems: "center" },
									// 	accessor: (datum) => (
									// 		<Box>
									// 			{" "}
									// 			{datum.userGroups.map((g, i) => (
									// 				<Button key={i}>
									// 					<Text style={{ fontSize: 10 }}>{g}</Text>
									// 				</Button>
									// 			))}
									// 		</Box>
									// 	),
									// },
									// {
									//     Header: "Editar",
									//     headerStyle: { padding: 15 },
									//     id: "edit",
									//     width: 100,
									//     resizable: false,
									//     sortable: false,
									//     style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									//     accessor: datum => (
									//         <Link to={{ pathname: `/users/editgroup/${datum.id}` }}>
									//             <Button fill color="gray" icon={<Edit />} hoverIndicator />
									//         </Link>
									//     )
									// },
									// {
									//     Header: "Eliminar",
									//     headerStyle: { padding: 15 },
									//     id: "delete",
									//     width: 100,
									//     resizable: false,
									//     sortable: false,
									//     style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									//     accessor: datum => (
									//         <Button color="gray" hoverIndicator icon={<Trash />}
									//             onClick={() => {
									//                 deleteUserGroup({
									//                     variables: { groupID: { id: datum.id } }
									//                 });
									//                 // toogleDeleteModal(!deleteModal);
									//                 // setContentIDToDelete(datum.id);
									//             }}
									//         />
									//     )
									// }
								]}
							/>
						)}
					</Box>
				</Box>
			</Box>

			<Box basis="1/3" direction="column" gap="small">
				{/* BOTONES PUBLICACION */}
				<Box direction="column" gap="medium">
					<Button
						disabled={dataToLoad.length === 0}
						primary
						label="Importar usuarios"
						onClick={() => {
							setLaodingUsers(true);
							Promise.all(
								dataToLoad.map((e) =>
									createUser(e)
										.then((c) => c)
										.catch((e) => e)
								)
							).then((ev) => {
								setLaodingUsers(false);
								console.log("RESULTADOS FINALES ", ev);
								setDataToLoad([]);
							});
						}}
					/>

					<Button
						disabled={dataToLoad.length === 0}
						label="Importar Grupos"
						onClick={() => {
							setLaodingUsers(true);
							const ne = dataToLoad.map((e) => e.gerencia);
							const te = new Set(ne);
							const le = [...te];
							console.log(le);

							Promise.all(
								le.map((el) => {
									createUserGroup({ variables: { name: el } })
										.then((d) => console.log(d))
										.catch((e) => console.log(e));
								})
							).then((ev) => {
								setLaodingUsers(false);
								setDataToLoad([]);
							});
						}}
					/>
				</Box>
				{/* FIN BOTONES PUBLICACION */}

				{/* ACCESOS APLICACIÓN */}
				<Box background="white" overflow="hidden" round="small" elevation="medium">
					<Box pad="small">
						<Heading color="gray" level="4" margin="none">
							Areas disponibles para usuario
						</Heading>
					</Box>
					<Box pad="small">
						{Object.keys(importForm.appAccess).map((area, i) => (
							<Box margin="small" key={i}>
								<CheckBox checked={importForm.appAccess[area]} label={area} onChange={(event) => setImportForm({ ...importForm, appAccess: { ...importForm.appAccess, [area]: !importForm.appAccess[area] } })} />
							</Box>
						))}
					</Box>
				</Box>
				{/* FIN ACCESOS APLICACIÓN */}
			</Box>

			{/* MODALES */}

			{loadingUsers && (
				<Layer>
					<Text>Cargando</Text>
				</Layer>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ImportData);
