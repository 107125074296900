import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Route, useParams, useRouteMatch } from "react-router-dom";

import { Box, ResponsiveContext } from "grommet";

import ContentForm from "./ContentForm";
import CategoryForm from "./CategoryForm";
import Library from "./Library";
import Comments from "./Comments";
// import updateComment from './UpdateComment'
import Media from "./media";
import Categories from "./Categories";
import Notifications from "./notifications";
import Dashboard from "./Dashboard";

import SideMenu from "../../components/SideMenu";

const RouterContent = () => {
	const size = useContext(ResponsiveContext);
	const { url } = useRouteMatch();

	const routes = [
		{
			route: `${url}`,
			icon: "Cluster",
			title: "Dashboard",
			allowed: ["Admin"]
		},
		{
			route: `${url}/newcontent`,
			icon: "Add",
			title: "Nuevo Contenido",
			allowed: ["Admin"]
		},
		{
			route: `${url}/library`,
			icon: "Catalog",
			title: "Contenidos",
			allowed: ["Admin"]
		},
		{
			route: `${url}/categories`,
			icon: "Bookmark",
			title: "Categorías",
			allowed: ["Admin"]
		},
	];

	return (
		<Box direction='row' gap='medium' justify='center' pad='medium'>
			{size !== "small" && (
				<Box width='100px'>
					<SideMenu routes={routes} sidebarStatus={true} />
				</Box>
			)}

			<Box flex width={{ max: "xlarge" }}>
				<Route exact path={`${url}`} component={Dashboard} exact />
				{/* <Route exact path="/content" component={Dashboard} exact /> */}
				<Route path={`${url}/library`} component={Library} />
				<Route path={`${url}/newcontent`} component={ContentForm} />
				<Route path={`${url}/editcontent/:id`} component={ContentForm} />
				{/* <Route path="/content/comments" component={Comments} /> */}
				{/* <Route path="/content/updateComment/" component={updateComment} /> */}
				<Route path={`${url}/media`} component={Media} />
				<Route path={`${url}/categories`} component={Categories} />
				<Route path={`${url}/newcategory`} component={CategoryForm} />
				<Route path={`${url}/editcategory/:id`} component={CategoryForm} />
			</Box>
		</Box>
	);
};

export default RouterContent;
