import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select } from "grommet";
import { Close, Trash } from "grommet-icons";

import MediaHandler from "../../components/MediaHandler";

import { withRouter, useParams } from "react-router";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import { isArray } from "lodash-es";

const ForumForm = ({ history }) => {
	const [publishModal, tooglePublishModal] = useState(false);
	const [userGroups, setUserGroups] = useState([]);

	const { userData, tenant, customization } = useContext(AuthContext);
	const { id } = useParams();

	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [teachers, setTeachers] = useState([]);
	const [teachersSelected, setTeachersSelected] = useState([]);

	const [richEditor, setRichEditor] = useState("");

	const [forumData, setForumData] = useState({
		name: "",
		image: "",
		usergroups: [],
		teacherRef: [],
		notification: false,
	});

	useEffect(() => {
		getGroups();

		id === undefined && setRichEditor("");
	}, []);

	const getUsers = async () => {
		const p = await firestore()
			.collection("users")
			.where("role", "==", "Teacher")
			.where("tenants", "array-contains", tenant.ref)
			.get()
			.then(async (doc) => {
				const t = doc.docs.map((teacher) => ({ ...teacher.data(), id: teacher.id, ref: teacher.ref }));
				setTeachers([...t]);
				return [...t];
			})
			.catch((err) => {
				return [];
			});
		return p;
	};

	const getGroups = async () => {
		tenant.ref
			.collection("usergroups")
			.get()
			.then(async (querySnapshot) => {
				const grps = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				const usrs = await getUsers().then((res) => res);
				setUserGroups(grps);
				id !== undefined && getData(grps, usrs);
			});
	};

	const getData = (grps, usrs) => {
		// console.log(usrs)
		tenant.ref
			.collection("forums")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// SI HAY IMAGEN CONSIGUE LA URL PUBLICA
					const img = doc.data().image;
					img &&
						storage()
							.ref(img)
							.getDownloadURL()
							.then((url) => setImage(url));
					// console.log(doc.data().teacherRef);
					const teacherRef = doc.data().teacherRef;
					const postGroups = doc.data().usergroups;
					// console.log(usrs.filter((u) => (isArray(teacherRef) ? teacherRef.map((i) => i.id).includes(u.ref.id) : false)));
					setForumData({
						...forumData,
						...doc.data(),
						usergroups: grps.filter((gr) => postGroups.map((c) => c.id).includes(gr.id)),
						// teacherRef: usrs.filter((u) => (isArray(teacherRef) ? teacherRef.map((i) => i.id).includes(u.ref.id) : false)),
					});
					console.log(usrs.filter((u) => (isArray(teacherRef) ? teacherRef.map((i) => i.id).includes(u.ref.id) : false)));
					setTeachersSelected(usrs.filter((u) => (isArray(teacherRef) ? teacherRef.map((i) => i.id).includes(u.ref.id) : false)));

					setRichEditor(doc.data().content);
					setLoading(false);
				} else {
					console.log("No such document!");
				}
			})
			.catch(function (error) {
				console.log("Error getting document:", error);
			});
	};

	// console.log(forumData);
	const publishForum = async () => {
		const grpRefs = forumData.usergroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id));

		let variables = {
			name: forumData.name,
			image: forumData.image,
			usergroups: grpRefs,
			teacherRef: forumData.teacherRef,
		};
		tenant.ref
			.collection("forums")
			.add(variables)
			.then((response) => {
				console.log(response);
				history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/forums/`);
			});
	};

	const editForum = async () => {
		const grpRefs = forumData.usergroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id));

		let variables = {
			forumID: id,
			name: forumData.name,
			image: forumData.image,
			usergroups: grpRefs,
			teacherRef: forumData.teacherRef,
		};

		tenant.ref
			.collection("forums")
			.doc(id)
			.update(variables)
			.then((response) => {
				console.log(response);
				history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/forums/`);
			});
	};

	return (
		<Box>
			<Box direction="row-responsive" gap="medium">
				<Box basis="2/3" gap="medium">
					{/* TITULO DE LA PUBLICACIÓN */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Título del Foro
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<TextInput
								plain
								size="xlarge"
								placeholder="Escribe aquí..."
								value={forumData.name}
								onChange={(event) => {
									setForumData({ ...forumData, name: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO DE LA PUBLICACIÓN */}

					{/* IMAGEN DESTACADA */}
					<Box background="white" overflow="hidden" pad="small" round="small" elevation="medium">
						<MediaHandler title="Imagen Destacada" type="images" value={image} height="350px" uploadCallback={(ev) => setForumData({ ...forumData, image: ev })} galleryCallback={(ev) => setForumData({ ...forumData, image: ev.ref })} durationCallback={(ev) => console.log(ev)} />
					</Box>
					{/* FIN IMAGEN DESTACADA */}
				</Box>

				<Box basis="1/3" gap="medium">
					{/* BOTONES PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Publicación
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Button disabled={forumData.name !== "" ? false : true} primary label={id ? "Actualizar Foro" : "Publicar Foro"} onClick={() => (id ? editForum() : tooglePublishModal(true))} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}

					{/* BLOQUE PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Filtrar Usuarios
							</Heading>
						</Box>

						<Box pad="small" gap="medium">
							<CheckBox checked={forumData.notification} label="Foro Público" onChange={(event) => setForumData({ ...forumData, notification: event.target.checked })} />

							<Collapsible open={!forumData.notification}>
								<Box background="light-2" gap="small" round="small" elevation="large" pad="small">
									<Heading level="5" margin="none">
										Grupo de Usuarios
									</Heading>

									<Select labelKey="name" valueKey="id" multiple placeholder="Selecciona..." options={userGroups} value={forumData.usergroups} onChange={({ value: nextValue }) => setForumData({ ...forumData, usergroups: nextValue })} />

									<Box gap="small">
										{forumData.usergroups.length === 0 ? (
											<Box align="center" fill justify="center">
												<Text textAlign="center">Sin Grupos Seleccionadas</Text>
											</Box>
										) : (
											forumData.usergroups.map((fr, i) => (
												<Button
													key={i}
													onClick={() => {
														setForumData({ ...forumData, usergroups: forumData.usergroups.filter((el) => el.id !== fr.id) });
													}}
													label={fr.name}
													primary
													color="accent-2"
													icon={<Close />}
												/>
											))
										)}
									</Box>
								</Box>
							</Collapsible>
						</Box>
					</Box>
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small" justify="between">
							<Heading color={customization.mainColor} level="4" margin="none" basis="1/2">
								Moderador del Foro
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Select
								placeholder="Selecciona el Moderador"
								options={teachers}
								value={forumData.teacherRef}
								labelKey={"name"}
								valueKey={{ key: "ref", reduce: true }}
								multiple={true}
								closeOnChange={false}
								onChange={({ value: nextValue }) => {
									console.log(nextValue);
									setForumData({ ...forumData, teacherRef: nextValue });
									setTeachersSelected(nextValue);
									// handleSelectedTeacher(e);
								}}
							/>
							{teachersSelected.map((u, i) => {
								return (
									<Box key={i} align="center" direction="row" pad="small" background="#f3f3f3" round="small">
										<Box flex>
											<Text key={i}>
												{teachers.find((el) => el.id === u.id)?.name} {teachers.find((el) => el.id === u.id)?.surname}
											</Text>
										</Box>
										<Button
											onClick={() => {
												setForumData({ ...forumData, teacherRef: forumData.teacherRef.filter((el) => el.id !== u.id) });
												setTeachersSelected(teachersSelected.filter((el) => el.id !== u.id));
											}}
											icon={<Trash />}
										/>
									</Box>
								);
							})}
						</Box>
					</Box>
					{/* FIN BLOQUE PUBLICACION */}
				</Box>
			</Box>
			{/* MODALES */}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" pad="medium" gap="medium" align="center" justify="center">
						<Heading level="3" margin="none">
							{id ? "Actualizar" : "Publicar"} Foro
						</Heading>
						<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este Foro, ¿estás seguro que deseas hacerlo?</Text>

						<Box direction="row" gap="small" width="100%" justify="between">
							<Button label="Cancelar" onClick={() => tooglePublishModal(!publishModal)} />
							<Button
								primary
								label={id ? "Actualizar" : "Publicar"}
								onClick={() => {
									tooglePublishModal(!publishModal);
									publishForum();
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ForumForm);
