import React, { useState, useEffect, useContext } from "react";
import { Box, TextInput, Image, Heading, Text, Layer, Button, Select } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Trash, Add, DocumentText, Schedule, CirclePlay, CloudUpload, Clock, BlockQuote, ContactInfo, Music, Task } from "grommet-icons";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const Itineraries = ({ history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [filter, setFilter] = useState({ search: "", type: null });
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const { userData, tenant } = useContext(AuthContext);

	useEffect(() => {
		setLoading(true);

		const unsubscribe = tenant.ref.collection("missions").onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					const img = doc.data().image;
					const url = img ? await storage().ref(img).getDownloadURL() : "";
					const postData = doc.data();
					return { id: doc.id, ...postData, image: url };
				})
			).then((res) => {
				// console.log(res[0])
				setLoading(false);
				setData(res);
				setFiltered(res);
			});
		});

		return () => {
			unsubscribe();
		};
	}, []);

	return (
		<Box>
			<Box background="white" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Misiones
					</Heading>

					<Box justify="end" gap="medium" align="center" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "550px" }} direction="row" gap="7px">
							<TextInput
								placeholder="Buscar..."
								type="search"
								value={filter.search}
								onChange={(ev) => {
									setFilter({ ...filter, search: ev.target.value.toLowerCase() });
									filter.type !== null
										? setFiltered([...data.filter((el) => el.title.toLowerCase().includes(ev.target.value.toLowerCase())).filter((el) => el.type === filter.type)])
										: setFiltered([...data.filter((el) => el.title.toLowerCase().includes(ev.target.value.toLowerCase()))]);
								}}
							/>
							<Select
								placeholder="Tipo"
								value={filter.type}
								options={["TODAS", "POST", "BIO", "VIDEO", "UPLOAD", "TIMER", "EVENT", "TEST", "ANSWER"]}
								onChange={(ev) => {
									setFilter({ ...filter, type: ev.value === "TODAS" ? null : ev.value });
									console.log(ev.value);
									ev.value === "TODAS" ? setFiltered([...data.filter((el) => el.title.toLowerCase().includes(filter.search.toLowerCase()))]) : setFiltered([...data.filter((el) => el.title.toLowerCase().includes(filter.search.toLowerCase())).filter((el) => el.type === ev.value)]);
								}}
							/>
						</Box>
						<Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/newmission`)} icon={<Add />} label="Nueva Mission" />
					</Box>
				</Box>

				<Box>
					<ReactTable
						data={filtered}
						defaultPageSize={10}
						loading={loading}
						loadingText="Cargando Datos..."
						previousText="Misiones Anteriores"
						nextText="Entradas Siguientes"
						noDataText="Sin Contenidos"
						pageText="Página"
						ofText="de"
						rowsText="filas"
						columns={[
							{
								Header: "",
								headerStyle: { padding: 15 },
								id: "image",
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", padding: 15 },
								accessor: (datum) => (
									<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
										<Image fit="cover" src={datum.image} />
									</Box>
								),
							},
							{
								Header: "Título",
								headerStyle: { padding: 15 },
								id: "title",
								style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
								accessor: (datum) => (
									<Box>
										<Text>{datum.title}</Text>
									</Box>
								),
							},
							{
								Header: "Tipo",
								id: "type",
								headerStyle: { padding: 15 },
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", alignItems: "center" },
								accessor: (datum) =>
									datum.type === "POST" ? (
										<DocumentText />
									) : datum.type === "VIDEO" ? (
										<CirclePlay />
									) : datum.type === "UPLOAD" ? (
										<CloudUpload />
									) : datum.type === "TIMER" ? (
										<Clock />
									) : datum.type === "BIO" ? (
										<ContactInfo />
									) : datum.type === "ANSWER" ? (
										<BlockQuote />
									) : datum.type === "EVENT" ? (
										<Schedule />
									) : datum.type === "TEST" ? (
										<Task />
									) : (
										<Music />
									),
							},
							{
								Header: "Puntos",
								id: "score",
								headerStyle: { padding: 15 },
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", alignItems: "center" },
								accessor: (datum) => datum.score,
							},
							{
								Header: "Editar",
								headerStyle: { padding: 15 },
								id: "edit",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/training/editmission/${datum.id}` }}>
										<Button fill color="gray" icon={<Edit />} hoverIndicator />
									</Link>
								),
							},
							{
								Header: "Eliminar",
								headerStyle: { padding: 15 },
								id: "delete",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Button
										color="gray"
										hoverIndicator
										icon={<Trash />}
										onClick={() => {
											toogleDeleteModal(!deleteModal);
											setContentIDToDelete(datum.id);
										}}
									/>
								),
							},
						]}
					/>
				</Box>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este Itinerario?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el itinerario no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									tenant.ref.collection("missions").doc(contentIDToDelete).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Itineraries);

// import React, { useState } from "react";
// import { Box, DataTable, Heading, CheckBox, Text, Layer, Button } from "grommet";
// import { Link } from "react-router-dom";
// import { Star, Edit, Trash, Add, UserAdd } from "grommet-icons";

// import moment from "moment";

// import * as queries from "../../graphql/newqueries";
// import * as mutation from "../../graphql/newmutation";

// import { useQuery, useMutation } from "@apollo/react-hooks";
// import { withRouter } from "react-router";

// const Itineraries = ({ location, history }) => {

//   const [checked, setChecked] = useState([]);
//   const [deleteModal, toogleDeleteModal] = useState(false);
//   const [contentIDToDelete, setContentIDToDelete] = useState("");
//   const [usersModal, setUsersModal] = useState({ status: false, data: {} })

//   const { loading, error, data } = useQuery(queries.getMissions);

//   const [deleteMission] = useMutation(mutation.deleteMission, {
//     refetchQueries: [{ query: queries.getMissions }]
//   });

//   const columns = [
//     {
//       property: "title",
//       header: <Text>Título de la Mision</Text>,
//       render: datum => (
//         <Text >
//           {datum.title !== null ? datum.title.substring(0, 50) +
//             (datum.title.length > 50 ? "..." : "") : "Sin titulo"}
//         </Text>
//       ),
//       primary: true,
//       search: true
//     },
//     {
//       property: "type",
//       header: "Tipo",
//       render: datum => (<Text>{datum.type}</Text>),
//       align: "center"
//     },
//     {
//       property: "points",
//       header: "Puntos",
//       render: datum => (<Text >{datum.score}</Text>),
//       align: "center"
//     },
//     {
//       property: "edit",
//       header: "Editar",
//       sortable: false,
//       render: datum =>
//         datum && (
//           <Box flex>
//             <Button onClick={() => history.push(`/training/editmission/${datum.id}`)} icon={<Edit color="gray" style={{ cursor: "pointer" }} />} />
//           </Box>
//         )
//     },
//     {
//       property: "delete",
//       header: "Delete",
//       sortable: false,
//       align: "center",
//       render: datum =>
//         datum && (
//           <Trash
//             color="gray"
//             style={{ cursor: "pointer" }}
//             onClick={() => {
//               toogleDeleteModal(!deleteModal);
//               setContentIDToDelete(datum.id);
//             }}
//           />
//         )
//     }
//   ];

//   const onCheck = (event, value) => {
//     event.target.checked && setChecked([...checked, value]);
//     !event.target.checked && setChecked(checked.filter(item => item !== value));
//   };

//   const onCheckAll = event => {
//     setChecked(
//       event.target.checked ? data.missions.map(datum => datum.id) : []
//     );
//   };

//   if (loading) {
//     console.log("loading")
//     return null
//   } else if (error) {
//     // console.log({error})
//     // localStorage.clear()
//     return <Text>Error :(</Text>
//   }
//   return (
//     <Box pad="medium">

//       <Box background="white" overflow="hidden" round="small" elevation="medium" >

//         <Box pad="small" >
//           <Heading color="gray" level="4" margin="none">Listado de Misiones</Heading>
//         </Box>

//         <DataTable
//           columns={[
//             {
//               property: "checkbox",
//               render: datum => (
//                 <CheckBox
//                   checked={checked.indexOf(datum.id) !== -1}
//                   onChange={e => onCheck(e, datum.id)}
//                 />
//               ),
//               header: (
//                 <CheckBox
//                   checked={checked.length === data.missions.length}
//                   indeterminate={
//                     checked.length > 0 && checked.length < data.missions.length
//                   }
//                   onChange={onCheckAll}
//                 />
//               ),
//               sortable: false,
//             },
//             ...columns
//           ]}
//           data={data.missions}
//           onClickRow={event => console.log("asd")}
//           step={5}
//           sortable
//           resizeable
//         />
//       </Box>

//       <Box align="end" pad="small">
//         <Button primary onClick={() => history.push("/training/newmission")} icon={<Add />} label="Nueva Mision" />
//       </Box>

//       {deleteModal && (
//         <Layer
//           position="center"
//           modal
//           onClickOutside={() => toogleDeleteModal(!deleteModal)}
//           onEsc={() => toogleDeleteModal(!deleteModal)}
//         >
//           <Box direction="row" justify="between" margin={{ top: "medium" }}>
//             <Button
//               label="Cancel"
//               onClick={() => toogleDeleteModal(!deleteModal)}
//             />
//             <Button
//               type="submit"
//               label="Eliminar"
//               primary
//               onClick={() => {
//                 deleteMission({ variables: { missionID: { id: contentIDToDelete } } });
//                 toogleDeleteModal(!deleteModal);
//               }}
//             />
//           </Box>
//         </Layer>
//       )}

//     </Box>
//   );
// };

// export default withRouter(Itineraries);
