import React, { useState, useEffect, useContext } from 'react';
import { Box, Layer, Button, Heading, DataTable, Text, CheckBox } from 'grommet';
import { View } from 'grommet-icons'


import ReactTable from 'react-table'
// import 'react-table/react-table.css'

import { firestore, storage } from 'firebase'
import AuthContext from '../context/AuthContext';

const columns = [
    {
        property: "title",
        header: <Text>Mission Title</Text>,
        render: datum => (
            <Text key={datum.id}>
                {datum.title !== null ? datum.title.substring(0, 30) +
                    (datum.title.length > 30 ? "..." : "") : "Sin titulo"}
            </Text>
        ),
        primary: true,
        search: true
    },
    {
        property: "score",
        header: "Points"
    },
    {
        property: "type",
        header: "Type",
        render: datum => (
            <Text key={3 + datum.id}>{datum.type !== null ? datum.type : "Sin tipo"}</Text>
        )
    },
    {
        property: "edit",
        header: "Preview",
        sortable: false,
        render: datum =>
            datum && (
                <Box flex>
                    <View />
                </Box>
            )
    },
];

const ContentLibrary = ({ closeCallback, submitCallback }) => {

    const { userData, tenant } = useContext(AuthContext)
    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const unsubscribe = tenant.ref.collection("missions").onSnapshot((querySnapshot) => {
            Promise.all(querySnapshot.docs.map(async (doc) => {
                const postData = doc.data()
                return { id: doc.id, ...postData }
            })).then(res => {
                // console.log(res[0])
                setLoading(false)
                setData(res)
            })
        })

        return () => {
            unsubscribe()
        }
    }, [])


    const onCheck = (event, value) => {
        event.target.checked && setChecked([...checked, value]);
        !event.target.checked && setChecked(checked.filter(item => item !== value));
    };

    const onCheckAll = event => {
        setChecked(
            event.target.checked ? data.map(datum => datum) : []
        );
    };

    console.log(data)

    return (
        <Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
            <Box width={{ min: "large" }}>
                <Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
                    <Heading color="gray" level="4" margin="none">Selecciona una o varias Misiones</Heading>
                    <Box direction="row" gap="small" pad="small">
                        <Button label="Cancelar" onClick={() => closeCallback()} />
                        <Button primary label="Añadir" onClick={() => submitCallback(checked)} />
                    </Box>
                </Box>
                <Box overflow="scroll" flex>
                    {!loading &&
                        <DataTable
                            columns={[
                                {
                                    property: "checkbox",
                                    render: datum => (
                                        <CheckBox
                                            key={datum.id}
                                            checked={checked.map(el => el.id).indexOf(datum.id) !== -1}
                                            onChange={e => onCheck(e, datum)}
                                        />
                                    ),
                                    header: (
                                        <CheckBox
                                            checked={checked.length === data.length}
                                            indeterminate={
                                                checked.length > 0 && checked.length < data.length
                                            }
                                            onChange={onCheckAll}
                                        />
                                    ),
                                    sortable: false
                                },
                                ...columns
                            ]}
                            data={data}
                            sortable
                            resizeable
                        />
                    }
                </Box>
            </Box>
        </Layer>
    );
}

export default ContentLibrary;