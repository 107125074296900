import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Trash, Add, DocumentText, DocumentPdf, CirclePlay, Music, Task, Duplicate } from "grommet-icons";

import moment from "moment";
import AuthContext from "../../context/AuthContext";

import { useHistory } from "react-router";

import { firestore, storage } from "firebase";

import ReactTable from "react-table";
import "react-table/react-table.css";
import CreateNotificationData from "../../utils/CreateNotificationData";

const Library = () => {
	const { remove } = CreateNotificationData();
	const history = useHistory();

	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [data, setData] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");

	const { tenant } = useContext(AuthContext);

	useEffect(() => {
		const unsubscribe = tenant.ref
			.collection("contents")
			.orderBy("publishDate", "desc")
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : "";
						return { ...doc.data(), image: url, id: doc.id };
					})
				).then((res) => {
					setData(res);
					setFiltered(res);
				});
			});

		return () => unsubscribe();
	}, []);

	const duplicateContent = async (id) => {
		tenant.ref
			.collection("contents")
			.doc(id)
			.get()
			.then((doc) =>
				tenant.ref.collection("contents").add({
					...doc.data(),
					title: (doc.data()?.title ?? "") + " copy",
					draft: true,
					published: false,
				})
			);
	};

	return (
		<Box>
			<Box background='white' round='small' elevation='medium'>
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap='large' direction='row' justify='between' align='center'>
					<Heading color='gray' level='3' margin='none'>
						Listado de Contenidos ({data.length})
					</Heading>

					<Box justify='end' gap='medium' direction='row' flex='grow' wrap>
						<Box flex='grow' width={{ max: "450px" }}>
							<TextInput placeholder='Buscar...' type='search' onChange={(ev) => setFiltered([...data.filter((el) => el.title.toLowerCase().includes(ev.target.value.toLowerCase()) || el.categories.includes(ev.target.value) )])} />
						</Box>
						<Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/content/newcontent`)} icon={<Add />} label='Nuevo Contenido' />
					</Box>
				</Box>

				<Box>
					<ReactTable
						data={filtered}
						defaultPageSize={10}
						loadingText='Cargando Datos...'
						previousText='Entradas Anteriores'
						nextText='Entradas Siguientes'
						noDataText='Sin Contenidos'
						pageText='Página'
						ofText='de'
						rowsText='filas'
						columns={[
							{
								Header: "Imagen",
								headerStyle: { padding: 15 },
								id: "image",
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", padding: 15 },
								accessor: (datum) => (
									<Box background='light-3' border='all' height='60px' width='60px' round='full' overflow='hidden'>
										<Image fit='cover' src={datum.image} />
									</Box>
								),
							},
							{
								Header: "Título",
								headerStyle: { padding: 15 },
								id: "title",
								style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
								accessor: (datum) => (
									<Box>
										<Text>{datum.title}</Text>
									</Box>
								),
							},
							{
								Header: "Tipo",
								id: "type",
								headerStyle: { padding: 15 },
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", alignItems: "center" },
								accessor: (datum) =>
									datum.contentType === "TEXT" ? (
										<DocumentText />
									) : datum.contentType === "VIDEO" ? (
										<CirclePlay />
									) : datum.contentType === "EXAM" ? (
										<Task />
									) : datum.contentType === "AUDIO" ? (
										<Music />
									) : (
										<DocumentPdf />
									),
							},
							// {
							// 	Header: "Categoría",
							// 	id: "category",
							// 	headerStyle: { padding: 15 },
							// 	width: 150,
							// 	style: { display: "flex", paddingLeft: 15, justifyContent: "center", paddingRight: 15, alignItems: "center" },
							// 	accessor: (datum) => datum.categories ,
							// },
							{
								Header: "Estado",
								headerStyle: { padding: 15 },
								id: "publishDate",
								width: 150,
								resizable: false,
								style: { display: "flex", paddingLeft: 15, whiteSpace: "unset", justifyContent: "center", paddingRight: 15, alignItems: "center" },
								accessor: (datum) => (datum.publishDate !== null ? (moment(datum.publishDate.toDate()).isAfter(moment()) ? `Programado` : datum.draft ? "Borrador" : `Publicado`) : "Sin fecha"),
							},
							{
								Header: "Destacado",
								headerStyle: { padding: 15 },
								id: "featured",
								width: 120,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => <Star size='35px' color={datum.featured ? "orange" : "gainsboro"} />,
							},
							{
								Header: "Duplicar",
								headerStyle: { padding: 15 },
								id: "duplicate",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Box onClick={() => duplicateContent(datum.id)}>
										<Button fill color='gray' icon={<Duplicate />} hoverIndicator />
									</Box>
								),
							},
							{
								Header: "Editar",
								headerStyle: { padding: 15 },
								id: "edit",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/content/editcontent/${datum.id}` }}>
										<Button fill color='gray' icon={<Edit />} hoverIndicator />
									</Link>
								),
							},
							{
								Header: "Eliminar",
								headerStyle: { padding: 15 },
								id: "delete",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Button
										color='gray'
										hoverIndicator
										icon={<Trash />}
										onClick={() => {
											toogleDeleteModal(!deleteModal);
											setContentIDToDelete(datum);
										}}
									/>
								),
							},
						]}
					/>
				</Box>
			</Box>

			{deleteModal && (
				<Layer position='center' modal margin='small' responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad='medium' width={{ min: "300px", max: "600px" }}>
						<Box gap='medium'>
							<Heading level='3' margin='none'>
								¿Está seguro que desea eliminar este contenido?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
						</Box>

						<Box direction='row' justify='between' margin={{ top: "medium" }}>
							<Button label='Cancel' onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type='submit'
								label='Eliminar'
								color='status-critical'
								primary
								onClick={() => {
									// deleteContent({
									//   variables: { contentID: { id: contentIDToDelete } }
									// });
									tenant.ref.collection("contents").doc(contentIDToDelete.id).delete();
									contentIDToDelete?.notificationDoc?.id && remove(contentIDToDelete?.notificationDoc?.id);
									console.log({ contentIDToDelete });
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default Library;
