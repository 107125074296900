import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Button,
    Collapsible,
    Heading,
    Text,
    Layer,
    Image,
    TextInput,
    CheckBox,
    Select
} from "grommet";
import * as Icon from "grommet-icons";


import MediaHandler from "../../components/MediaHandler";

import { withRouter, useParams } from "react-router";

import { firestore, storage } from 'firebase'

const EvaluationForm = ({ history }) => {

    const [publishModal, tooglePublishModal] = useState(false);
    const [userGroups, setUserGroups] = useState([])

    const { id } = useParams()

    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true)


    const [richEditor, setRichEditor] = useState("")

    const [form, setForm] = useState({
        name: "",
        image: "",
        usergroups: [],
        notification: false
    });

    useEffect(() => {
        getGroups()
        id === undefined && setRichEditor("")
    }, [])

    const getGroups = () => {
        firestore().collection("usergroups").get()
            .then((querySnapshot) => {
                const grps = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                setUserGroups(grps)
                id !== undefined && getData(grps)
            })
    }

    const getData = (grps) => {

        firestore().collection("evaluations").doc(id).get().then(async (doc) => {

            if (doc.exists) {

                // SI HAY IMAGEN CONSIGUE LA URL PUBLICA
                const img = doc.data().image
                img && storage().ref(img).getDownloadURL().then(url => setImage(url))

                const postGroups = doc.data().usergroups

                setForm({
                    ...form,
                    ...doc.data(),
                    usergroups: grps.filter(gr => postGroups.map(c => c.id).includes(gr.id))
                })

                setRichEditor(doc.data().content)
                setLoading(false)
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const publish = async () => {
        const grpRefs = form.usergroups.map((gr, i) => firestore().collection("usergroups").doc(gr.id))

        let variables = {
            name: form.name,
            image: form.image,
            usergroups: grpRefs
        };
        firestore().collection("evaluations").add(variables).then(response => {
            console.log(response)
            history.push("/evaluations")
        })
    }

    const edit = async () => {
        const grpRefs = form.usergroups.map((gr, i) => firestore().collection("usergroups").doc(gr.id))

        let variables = {
            forumID: id,
            name: form.name,
            image: form.image,
            usergroups: grpRefs
        };

        firestore().collection("evaluations").doc(id).update(variables).then(response => {
            console.log(response)
            history.push("/evaluations")
        })
    }


    return (
        <Box>

            <Box direction="row-responsive" gap="medium">

                <Box basis="2/3" gap="medium" >

                    {/* TITULO DE LA PUBLICACIÓN */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Título de la Evaluación</Heading>
                        </Box>

                        <Box
                            round="medium"
                            pad={{ left: "small", right: "small" }}
                        >
                            <TextInput
                                plain
                                size="xlarge"
                                placeholder="Escribe aquí..."
                                value={form.name}
                                onChange={event => { setForm({ ...form, name: event.target.value }) }}
                            />
                        </Box>
                    </Box>
                    {/* FIN TITULO DE LA PUBLICACIÓN */}

                    {/* IMAGEN DESTACADA */}
                    <Box background="white" overflow="hidden" pad="small" round="small" elevation="medium" >
                        <MediaHandler
                            title="Imagen Destacada"
                            type="images"
                            value={image}
                            height="350px"
                            uploadCallback={(ev) => setForm({ ...form, image: ev })}
                            galleryCallback={(ev) => setForm({ ...form, image: ev.ref })}
                            durationCallback={(ev) => console.log(ev)}
                        />
                    </Box>
                    {/* FIN IMAGEN DESTACADA */}

                </Box>


                <Box basis="1/3" gap="medium">
                    {/* BOTONES PUBLICACION */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Publicación</Heading>
                        </Box>

                        <Box pad="small" gap="small">
                            <Button
                                disabled={form.name !== "" ? false : true}
                                primary
                                label={id ? "Actualizar Foro" : "Publicar Foro"}
                                onClick={() => id ? edit() : tooglePublishModal(true)}
                            />
                        </Box>


                    </Box>
                    {/* FIN BOTONES PUBLICACION */}


                    {/* BLOQUE PUBLICACION */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Filtrar Usuarios</Heading>
                        </Box>

                        <Box pad="small" gap="medium">

                            <CheckBox
                                checked={form.notification}
                                label="Foro Público"
                                onChange={event =>
                                    setForm({ ...form, notification: event.target.checked })
                                }
                            />

                            <Collapsible open={!form.notification}>
                                <Box background="light-2" gap="small" round="small" elevation="large" pad="small">
                                    <Heading level="5" margin="none">Grupo de Usuarios</Heading>


                                    <Select
                                        labelKey="name"
                                        valueKey="id"
                                        multiple
                                        placeholder="Selecciona..."
                                        options={userGroups}
                                        value={form.usergroups}
                                        onChange={({ value: nextValue }) => setForm({ ...form, usergroups: nextValue })}
                                    />

                                    <Box gap="small">
                                        {form.usergroups.length === 0 ?
                                            <Box align="center" fill justify="center">
                                                <Text textAlign="center">Sin Grupos Seleccionadas</Text>
                                            </Box>
                                            :
                                            form.usergroups.map((fr, i) => <Button key={i} onClick={() => { setForm({ ...form, usergroups: form.usergroups.filter(el => el.id !== fr.id) }) }} label={fr.name} primary color="accent-2" icon={<Icon.Close />} />)}
                                    </Box>

                                </Box>
                            </Collapsible>
                        </Box>
                    </Box>
                    {/* FIN BLOQUE PUBLICACION */}
                </Box>

            </Box>
            {/* MODALES */}



            {publishModal && (
                <Layer
                    onEsc={() => tooglePublishModal(!publishModal)}
                    onClickOutside={() => tooglePublishModal(!publishModal)}
                >
                    <Box width="medium" pad="medium" gap="medium" align="center" justify="center">
                        <Heading level="3" margin="none">{id ? "Actualizar" : "Publicar"} Foro</Heading>
                        <Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este Foro, ¿estás seguro que deseas hacerlo?</Text>

                        <Box direction="row" gap="small" width="100%" justify="between">
                            <Button label="Cancelar"
                                onClick={() => tooglePublishModal(!publishModal)}
                            />
                            <Button
                                primary
                                label={id ? "Actualizar" : "Publicar"}
                                onClick={() => {
                                    tooglePublishModal(!publishModal);
                                    publish()
                                }}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}


            {/* FIN MODALES */}


        </Box>
    );
};

export default EvaluationForm;