import React, { useState, useEffect, useContext } from "react";
import { Box, Layer, Button, Heading, DataTable, Text, CheckBox, TextInput } from "grommet";

import { firestore } from "firebase";
import AuthContext from "../context/AuthContext";

const columns = [
	{
		property: "email",
		header: <Text>Email</Text>,
		render: (datum) => <Text>{datum.email}</Text>,
		primary: true,
		search: true,
	},
	{
		property: "name",
		header: "Nombre",
	},
	{
		property: "surname",
		header: "Apellido",
	},
];

const UsersLibrary = ({ closeCallback, submitCallback, filters }) => {
	const { tenant } = useContext(AuthContext);
	const [checked, setChecked] = useState([]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		// const unsubscribe = firestore().collection("users").where("tenants", "array-contains", tenant.ref).onSnapshot((querySnapshot) => {
		const ref = firestore().collection("users").where("tenants", "array-contains", tenant.ref);
		const refWithFilters = filters ? ref.where(filters) : ref;
		const unsubscribe = refWithFilters.onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					// const img = doc.data().image
					// const url = img ? await storage().ref(img).getDownloadURL() : ""
					return { ...doc.data(), id: doc.id };
				})
			).then((res) => {
				// const data = res.filter(el => !contentsUsed.map(c => c.id).includes(el.id))
				setUsers(res);
			});
		});

		return () => unsubscribe();
	}, []);

	const onCheck = (event, value) => {
		event.target.checked && setChecked([...checked, value]);
		!event.target.checked && setChecked(checked.filter((item) => item !== value));
	};

	const onCheckAll = (event) => {
		setChecked(event.target.checked ? users.map((datum) => datum) : []);
	};

	return (
		<Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
			<Box width={{ min: "large" }}>
				<Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
					{/* <Heading color="gray" level="4" margin="none">Selecciona uno o varios Usuarios</Heading> */}
					<Box direction="row" gap="small" pad="small">
						<Button label="Cancelar" onClick={() => closeCallback()} />
						<Button primary label="Añadir" onClick={() => submitCallback(checked)} />
					</Box>
				</Box>
				<Box overflow="scroll" flex>
					<DataTable
						columns={[
							{
								property: "checkbox",
								render: (datum) => <CheckBox key={datum.id} checked={checked.map((el) => el.id).indexOf(datum.id) !== -1} onChange={(e) => onCheck(e, datum)} />,
								header: <CheckBox checked={checked.length === users.length} indeterminate={checked.length > 0 && checked.length < users.length} onChange={onCheckAll} />,
								sortable: false,
							},
							...columns,
						]}
						data={users}
						sortable
						resizeable
					/>
				</Box>
			</Box>
		</Layer>
	);
};

export default UsersLibrary;
