import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add } from "grommet-icons";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { firestore } from "firebase";
import AuthContext from "../../context/AuthContext";

const UserGroups = ({ location, history }) => {
	const { userData, tenant } = useContext(AuthContext);

	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		getData();

		return () => getData();
	}, []);

	const getData = () => {
		tenant.ref.collection("usergroups").onSnapshot(async (res) => {
			Promise.all(res.docs.map(async (group) => ({ ...group.data(), id: group.id }))).then((groups) => {
				getUsers(groups);
			});
		});
	};

	const getUsers = (groups) => {
		firestore()
			.collection("users")
			.where("tenants", "array-contains", tenant.ref)
			.onSnapshot(async (res) => {
				Promise.all(res.docs.map(async (user) => ({ ...user.data(), id: user.id }))).then((users) => {

					const d = groups?.map((el) => el.id);
					const u = users?.map((el) => el.usergroups?.map((t) => t.id));
          
					setData(groups.map((group) => ({...group, users: u.filter((i) => i?.includes(group.id))})));
					setFiltered(groups.map((group) => ({...group, users: u.filter((i) => i?.includes(group.id))})));
					setLoading(false);
				});
			});
	};

	useEffect(() => {
		data && setFiltered(data.usergroups);
	}, [data]);

	return (
		<Box>
			<Box background='white' overflow='hidden' round='small' elevation='medium'>
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap='large' direction='row' justify='between' align='center'>
					<Heading color='gray' level='3' margin='none'>
						Listado de Grupo
					</Heading>

					<Box justify='end' gap='medium' direction='row' flex='grow' wrap>
						<Box flex='grow' width={{ max: "450px" }}>
							<TextInput placeholder='Buscar...' type='search' onChange={(ev) => setFiltered([...data.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => history.push(`${process.env.PUBLIC_URL}/${tenant.url}/users/newgroup`)} icon={<Add />} label='Nuevo Grupo' />
					</Box>
				</Box>

				<Box>
					{data && (
						<ReactTable
							data={filtered}
							defaultPageSize={10}
							loading={loading}
							loadingText='Cargando Grupos...'
							previousText='Grupos Anteriores'
							nextText='Grupos Siguientes'
							noDataText='Sin Grupos'
							pageText='Página'
							ofText='de'
							rowsText='filas'
							columns={[
								{
									Header: "Nombre",
									id: "name",
									headerStyle: { padding: 15 },
									style: { paddingLeft: 15, display: "flex", justifyContent: "left", alignItems: "center" },
									accessor: (datum) => datum.name,
								},
								{
									Header: "Usuarios",
									id: "users",
									headerStyle: { padding: 15 },
									width: 130,
									style: { display: "flex", justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (datum.users ? datum.users.length : 0),
								},
								{
									Header: "Editar",
									headerStyle: { padding: 15 },
									id: "edit",
									width: 100,
									resizable: false,
									sortable: false,
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Link to={{ pathname: `${process.env.PUBLIC_URL}/${tenant.url}/users/editgroup/${datum.id}` }}>
											<Button fill color='gray' icon={<Edit />} hoverIndicator />
										</Link>
									),
								},
								{
									Header: "Eliminar",
									headerStyle: { padding: 15 },
									id: "delete",
									width: 100,
									resizable: false,
									sortable: false,
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button
											color='gray'
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContentIDToDelete(datum.id);
											}}
										/>
									),
								},
							]}
						/>
					)}
				</Box>
			</Box>

			{deleteModal && (
				<Layer position='center' modal margin='small' responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad='medium' width={{ min: "300px", max: "600px" }}>
						<Box gap='medium'>
							<Heading level='3' margin='none'>
								¿Está seguro que desea eliminar este grupo?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el grupo no podrá ser recuperado</Text>
						</Box>

						<Box direction='row' justify='between' width='100%' margin={{ top: "medium" }}>
							<Button label='Cancelar' onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type='submit'
								label='Eliminar'
								color='status-critical'
								primary
								onClick={() => {
									tenant.ref.collection("usergroups").doc(contentIDToDelete).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(UserGroups);

// import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
// import {
//     Box,
//     Button,
//     Collapsible,
//     Heading,
//     Grommet,
//     Layer,
//     Grid,
//     Anchor,
//     Image,
//     TextArea,
//     TextInput,
//     ResponsiveContext,
//     DataTable,
//     CheckBox,
//     Select,
//     Tabs,
//     Calendar,
//     Video,
//     Text
// } from 'grommet';
// import { Star, Edit, Trash } from 'grommet-icons'

// import moment from 'moment'

// import * as queries from '../../graphql/newqueries';
// import * as mutations from '../../graphql/newmutation';

// import { useQuery, useMutation } from "@apollo/react-hooks";
// import { withRouter } from "react-router";

// const UserGroups = ({ history }) => {

//     const [checked, setChecked] = useState([])

//     const { loading, error, data, networkStatus } = useQuery(queries.getGroups);

//     const [deleteUserGroup] = useMutation(mutations.deleteUserGroup, {
//         refetchQueries: [{ query: queries.getGroups }]
//     });

//     const onCheck = (event, value) => {
//         if (event.target.checked) {
//             setChecked([...checked, value]);
//         } else {
//             setChecked({ checked: checked.filter(item => item !== value) });
//         }
//     };

//     const onCheckAll = event => setChecked(event.target.checked ? data.usergroups.map(datum => datum.id) : []);

//     const deleteGroup = (id) => {
//         const variables = {
//             groupID: { id }
//         }
//         deleteUserGroup({ variables }).then(res => console.log(res)).catch(err => console.log(err))
//     }

//     if (loading) { return <Box>Loading</Box> }
//     return (
//         <Box gap="medium" pad={{ vertical: "medium", right: "medium" }}>

//             <Box background="white" overflow="hidden" round="small" elevation="medium" >

//                 <Box pad="small" >
//                     <Heading color="gray" level="4" margin="none">Listado de Grupos de Usuarios</Heading>
//                 </Box>
//                 <Box pad="small">
//                     <DataTable
//                         columns={[
//                             {
//                                 property: "checkbox",
//                                 render: datum => (
//                                     <CheckBox
//                                         key={datum.id}
//                                         checked={checked.indexOf(datum.id) !== -1}
//                                         onChange={e => onCheck(e, datum.id)}
//                                     />
//                                 ),
//                                 header: (
//                                     <CheckBox
//                                         checked={checked.length === data.usergroups.length}
//                                         indeterminate={
//                                             checked.length > 0 && checked.length < data.usergroups.length
//                                         }
//                                         onChange={onCheckAll}
//                                     />
//                                 ),
//                                 sortable: false,
//                             },
//                             {
//                                 property: "name",
//                                 header: <Text>Nombre del Grupo</Text>,
//                                 primary: true,
//                             },
//                             // {
//                             //     property: "description",
//                             //     header: <Text>Descipción del Grupo</Text>
//                             // },
//                             {
//                                 property: "users",
//                                 header: "Numero de Usuarios",
//                                 render: datum => datum.users.length,
//                                 align: "center",
//                                 sortable: false
//                             },
//                             {
//                                 property: "edit",
//                                 header: "Editar",
//                                 render: datum => datum && <Edit onClick={() => history.push(`/users/editgroup/${datum.id}`)} color="black" />,
//                                 sortable: false
//                             },
//                             {
//                                 property: "delete",
//                                 header: "Eliminar",
//                                 render: datum => <Button hoverIndicator onClick={() => deleteGroup(datum.id)} ><Trash color="black" /></Button>,
//                                 sortable: false
//                             }
//                         ]}
//                         data={data.usergroups}
//                         sortable
//                     />
//                 </Box>
//             </Box>
//             <Box align="end">
//                 <Button primary href="/users/newgroup" label="Crear Grupo" />
//             </Box>
//         </Box>
//     );
// }

// export default withRouter(UserGroups);
