import React from 'react';
import { storage } from 'firebase'


const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export const UploadFromFile = async (e, type, isAdmin, route) => {

    const file = e.target.files[0];
    const name = slugify(e.target.files[0].name.replace(/\.[^/.]+$/, ""))
    const format = slugify(e.target.files[0].name.split(".")[1])
    // console.log(e.target.files[0])

    var storageRef = storage().ref().child(`${route}/${name}.${format}`)

    const data = storageRef.put(file).then((snapshot) => snapshot.ref.fullPath).catch(err => err);

    // data.on(
    //     'state_changed',
    //     (snapshot) => {
    //         const progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    //         console.log(progress)
    //         // setUploading({
    //         //     uploading: true,
    //         //     progress
    //         // })
    //     },
    //     function (error) {
    //         // Handle unsuccessful uploads
    //     },
    //     async function () {
    //         // setUploading({ uploading: false })
    //     }
    // );
    return data


};

export const UploadFromURL = async (e, type, isAdmin, route) => {


    // const fileURL = URL.createObjectURL(file);
    // console.log(e, type, route)


};