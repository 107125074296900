import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { firestore } from "firebase";

const CreateNotificationData = () => {
	const { userData, tenant } = useContext(AuthContext);

	const create = async (data) =>
		tenant.ref
			.collection("notifications")
			.add(data)
			// .then(res => console.log({ res, data }))
			.then((res) => tenant.ref.collection("contents").doc(data?.data?.params?.id).update({ notificationDoc: res }))
			.catch((error) => console.log({ error, where: "Create notification" }));
	const edit = async (ref, data) =>
		tenant.ref
			.collection("notifications")
			.doc(ref?.id)
			.update(data)
			.catch((error) => console.log({ error, where: "Upadate notification" }));
	const remove = (id) =>
		tenant.ref
			.collection("notifications")
			.doc(id)
			.delete()
			.catch((error) => console.log({ error, where: "Delete notification" }));

	return {
		create,
		edit,
		remove,
	};
};

export default CreateNotificationData;
