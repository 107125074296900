import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, Image, Heading, Button, Layer, TextInput } from "grommet";
import { Trash, Gallery, Link, CloudUpload } from "grommet-icons";
import Loader from "react-loader-spinner";
import { firestore } from "firebase";

import { UploadFromURL, UploadFromFile } from "../utils/Upload";
import MediaLibrary from "../components/MediaLibrary";
import AuthContext from "../context/AuthContext";

const MediaHandler = ({ type, value, height = "250px", placeholder = "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png", title, uploadCallback, galleryCallback, durationCallback }) => {
	const { userData, customization, tenant } = useContext(AuthContext);

	const [loadingMedia, setLoadingMedia] = useState(false);
	const [modalMedia, setModalMedia] = useState(false);

	const [preview, setPreview] = useState(null);
	const [loadingImage, setLoadingImage] = useState(false);
	const [fromURL, setFromURL] = useState({ status: false, url: null });

	const fileInput = useRef();

	useEffect(() => {
		value && setPreview(value);
		// setLoadingImage(true);
		value === null && setLoadingImage(false);
	}, [value]);

	const createCollectionUpload = async (ref, file) => {
		tenant.ref
			.collection("medialibrary")
			.add({
				name: file.name,
				size: file.size,
				type: file.type,
				contentType: file.type.includes("image") ? "images" : file.type.includes("appli") ? "documents" : file.type.includes("video") ? "videos" : "audios",
				createdAt: firestore.FieldValue.serverTimestamp(),
				ref,
			})
			.then((res) => console.log({ res }));
	};
	const loadURL = (e) => {
		setPreview(e);
		// console.log(`${userData.tenants[0].id}/admin/${type}`)
		UploadFromURL(e, type, true, `${tenant.id}/admin/${type}`)
			.then((data) => {
				uploadCallback(data);
				setLoadingMedia(false);
			})
			.catch((err) => {
				setLoadingMedia(false);
				// //console.log(err)
			});
	};

	const loadMedia = async (e) => {
		setLoadingMedia(true);

		const file = e.target.files[0];

		UploadFromFile(e, type, true, `${tenant.id}/admin/${type}`)
			.then((data) => {
				createCollectionUpload(data, file);
				uploadCallback(data);
				setLoadingMedia(false);
				setPreview(URL.createObjectURL(file));
			})
			.catch((err) => {
				setLoadingMedia(false);
				// //console.log(err)
			});
	};

	return (
		<Box>
			<Box gap="medium">
				{title !== undefined && (
					<Box>
						<Heading color={customization.mainColor} level="4" margin="none">
							{title}
						</Heading>
					</Box>
				)}

				<Box background="#f2f2f2" round="small">
					<Box overflow="hidden" background="#f2f2f2" round="small">
						{type === "images" && (
							<Box height={height} style={{ position: "relative" }}>
								{loadingImage && (
									<Box align="center" background="rgba(0,0,0,0.6)" justify="center" style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
										<Loader type="Puff" color="white" height={50} width={50} />
									</Box>
								)}
								<Image
									// onLoadStart={(dat => console.log(true))}
									key={preview === null ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMUrwcAALMAmGjO2MQAAAAASUVORK5CYII=" : preview}
									onError={() => setPreview(null)}
									onLoad={(dat) => {
										console.log("LOADED	");
										setLoadingImage(false);
									}}
									fit="cover"
									src={preview === null ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMUrwcAALMAmGjO2MQAAAAASUVORK5CYII=" : preview}
								/>
							</Box>
						)}
						{type === "videos" && (
							<video
								key={preview}
								width="100%"
								height="100%"
								controls
								onLoadedMetadata={(dat) => {
									durationCallback(dat.currentTarget.duration);
									// console.log(dat.currentTarget.duration)
								}}>
								<source src={preview} type="video/mp4" />
							</video>
						)}
						{type === "audios" && (
							<audio style={{ width: "100%" }} key={preview} controls onLoadedMetadata={(dat) => durationCallback(dat.currentTarget.duration)}>
								<source src={preview} />
							</audio>
						)}
						{type === "documents" && preview && <object key={preview} data={preview} type="application/pdf" width="100%" height={400}></object>}

						{type === "documents" && !preview && (
							<Box height="400px" background="#f3f3f3" align="center" justify="center" round="small">
								<Heading level="4" margin="none">
									Carga un PDF
								</Heading>
							</Box>
						)}
					</Box>
					{userData.role === "Admin" && <Box direction="row" justify="between">
						<Box direction="row">
							<Button hoverIndicator icon={<CloudUpload />} onClick={() => fileInput.current.click()} />
							<input type="file" accept={type === "documents" ? "application/pdf" : type === "images" ? "image/*" : type === "videos" ? "video/mp4" : "audio/*"} ref={fileInput} onChange={(e) => loadMedia(e)} style={{ display: "none" }} />

							<Button hoverIndicator icon={<Gallery />} onClick={() => setModalMedia({ type, status: true })} />

							{/* <Button
                            hoverIndicator
                            icon={<Link />}
                            onClick={() => setFromURL({ status: true, url: null })}
                        /> */}
						</Box>
						<Button hoverIndicator disabled={preview === null} icon={<Trash />} onClick={() => setPreview(null)} />
					</Box>}
				</Box>
			</Box>

			{loadingMedia && (
				<Layer>
					<Box pad="medium">
						<Loader type="Puff" color="#00BFFF" height={100} width={100} />
					</Box>
				</Layer>
			)}

			{fromURL.status && (
				<Layer onClickOutside={() => setFromURL({ status: false, url: null })}>
					<Box pad="large">
						<Box direction="row">
							<TextInput
								onChange={(ev) => {
									setFromURL({ ...fromURL, url: ev.target.value });
									//console.log(ev.target.value)
								}}
							/>
							<Button primary onClick={() => loadURL(fromURL.url)} label="Cargar" />
						</Box>
						<Box height="medium" width="medium">
							<Image src={fromURL.url} fit="cover" width="medium" height="medium" />
						</Box>
					</Box>
				</Layer>
			)}

			{modalMedia && (
				<MediaLibrary
					type={type}
					selectCallback={(ev) => {
						// //console.log(ev)
						setPreview(ev.url);
						galleryCallback(ev);
						setModalMedia(false);
					}}
					cancelCallback={() => {
						setModalMedia(false);
					}}
				/>
			)}
		</Box>
	);
};

export default MediaHandler;
