import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select, Video, TextArea, Calendar } from "grommet";
import { Bold, CirclePlay, FormSearch, Indicator, Clock, Schedule, Close, Trash, Gallery, ContactInfo, FormUpload, FormSchedule, CloudUpload, TextAlignFull, DocumentText, Task, Music } from "grommet-icons";

import ScheduleModal from "../../components/ScheduleModal";

import { Editor } from "@tinymce/tinymce-react";
import TextareaAutosize from "react-textarea-autosize";
import ContentLibrary from "../../components/ContentLibrary";
import QuestionGenerator from "../../components/QuestionGenerator";
import MediaHandler from "../../components/MediaHandler";

import moment from "moment";

import GoogleMapReact from "google-map-react";

import { withRouter, useParams } from "react-router";
import TimerGenerator from "../../components/TimerGenerator";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const ContentForm = ({ history }) => {
	const { userData, tenant } = useContext(AuthContext);

	const [publishModal, tooglePublishModal] = useState(false);
	const [scheduleModal, toogleScheduleModal] = useState(false);
	const [addContentModal, toogleAddContentModal] = useState(false);
	const [previewText, setPreviewText] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);

	const { id } = useParams();

	const [image, setImage] = useState(null);
	const [media, setMedia] = useState(null);

	const [data, setData] = useState({
		title: "",
		type: "POST",
		description: "",
		image: "",
		score: 0,
		content: {},
	});

	useEffect(() => {
		id === undefined && data.type === "EVENT" && editContent("description", "");
		console.log(data.content);
	}, [data.type]);

	useEffect(() => {
		id !== undefined && getData();
	}, []);

	const getData = () => {
		tenant.ref
			.collection("missions")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// SI HAY IMAGEN CONSIGUE LA URL PUBLICA
					const img = doc.data().image;
					img &&
						storage()
							.ref(img)
							.getDownloadURL()
							.then((url) => setImage(url));

					const md = doc.data().media;
					md &&
						storage()
							.ref(md)
							.getDownloadURL()
							.then((url) => setMedia(url));
					const v = doc.data().content.video;
					let video;
					v &&
						(await storage()
							.ref(v)
							.getDownloadURL()
							.then((url) => (video = url)));
					console.log(video);
					setData({
						...data,
						...doc.data(),
						content: { ...doc.data().content, video: video },
					});
				} else {
					console.log("No such document!");
				}
			})
			.catch(function (error) {
				console.log("Error getting document:", error);
			});
	};

	const addMission = async () => {
		var content = data.content;

		if (data.type !== "ANSWER" && data.type !== "UPLOAD") {
			delete content.waitForPoints;
		}
		if (data.type !== "UPLOAD") {
			delete content.durationLimit;
		}
		if (data.type !== "ANSWER") {
			delete content.characterLimit;
		}
		if (data.type !== "TEST") {
			content.withFeedback &&
				content.answers.map((aw) => ({
					...aw,
					feedback: aw.feedback ? aw.feedback : "",
				}));
		}
		if (data.type !== "TEST") {
			delete content.answers;
		}
		if (data.type === "BIO") {
			content.team = data.content.team ? true : false;
			content.position = data.content.position ? true : false;
			content.bio = data.content.bio ? true : false;
			content.hobbies = data.content.hobbies ? true : false;
			content.imageProfile = data.content.imageProfile ? true : false;
			content.skills = data.content.skills ? true : false;
		}
		if (data.type !== "BIO") {
			delete content.team;
			delete content.position;
			delete content.bio;
			delete content.hobbies;
			delete content.imageProfile;
			delete content.skills;
		}
		if (data.type !== "TIMER") {
			delete content.steps;
			delete content.withSteps;
			delete content.withMedia;
		}
		if (data.type === "TIMER") {
			!content.withMedia && (content.withMedia = false);
			!content.withSteps && (content.withSteps = false);
			!content.withSteps && delete content.steps;
		}

		let variables = {
			...data,
			title: data.title,
			type: data.type,
			score: data.score,
			content,
			description: data.description,
			image: data.image,
			media: data.media ? data.media : null,
		};

		tenant.ref
			.collection("missions")
			.add(variables)
			.then((response) => {
				console.log(response);
				history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/missions`);
			});
	};

	const editMission = async () => {
		var content = data.content;

		if (data.type !== "ANSWER" && data.type !== "UPLOAD") {
			delete content.waitForPoints;
		}
		if (data.type !== "UPLOAD") {
			delete content.durationLimit;
		}
		if (data.type !== "ANSWER") {
			delete content.characterLimit;
		}
		if (data.type !== "TEST") {
			delete content.answers;
		}
		if (data.type === "BIO") {
			content.team = data.content.team ? true : false;
			content.position = data.content.position ? true : false;
			content.bio = data.content.bio ? true : false;
			content.hobbies = data.content.hobbies ? true : false;
			content.imageProfile = data.content.imageProfile ? true : false;
			content.skills = data.content.skills ? true : false;
		}
		if (data.type !== "BIO") {
			delete content.team;
			delete content.position;
			delete content.bio;
			delete content.hobbies;
			delete content.imageProfile;
			delete content.skills;
		}
		if (data.type !== "TIMER") {
			delete content.steps;
			delete content.withSteps;
			delete content.withMedia;
		}
		if (data.type === "TIMER") {
			!content.withMedia && (content.withMedia = false);
			!content.withSteps && (content.withSteps = false);
			!content.withSteps && delete content.steps;
		}

		let variables = {
			...data,
			title: data.title,
			type: data.type,
			score: data.score,
			content,
			description: data.description,
			image: data.image,
			media: data.media ? data.media : null,
		};

		tenant.ref
			.collection("missions")
			.doc(id)
			.update(variables)
			.then((response) => {
				console.log(response);
				history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/missions`);
			});
	};

	const editContent = (type, value) => {
		const content = data.content;
		content[type] = value;
		setData({ ...data, content });
	};

	const formatURL = (str) => {
		str = str.replace(/^\s+|\s+$/g, ""); // trim
		str = str.toLowerCase();
		// remove accents, swap ñ for n, etc
		let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
		let to = "aaaaeeeeiiiioooouuuunc------";
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
		}
		str = str
			.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
			.replace(/\s+/g, "-"); // collapse whitespace and replace by -

		return str;
	};

	const getCoordinates = async () => {
		// Primero geocoding, sino error
		let baseuri = formatURL(data.content.place);

		const coordenadas = await fetch("https://maps.google.com/maps/api/geocode/json?address=" + baseuri + "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I").then((data) => data.json());

		if (coordenadas.results.length > 0) {
			const coord = coordenadas.results[0].geometry.location;
			editContent("lat", coord.lat);
			editContent("lng", coord.lng);
			console.log(coord);
		} else {
			alert("Error", "Dirección no encontrada.");
		}
	};

	return (
		<Box>
			<Box direction="row-responsive" gap="medium">
				<Box basis="2/3" gap="medium">
					{/* TITULO */}

					<Box background="white" round="small" gap="medium" pad="medium" elevation="medium">
						<Box>
							<Heading color="titles" level="4" margin="none">
								Título de la Misión
							</Heading>
						</Box>

						<Box>
							<TextareaAutosize
								maxRows={6}
								rows={1}
								resize="none"
								style={{ fontSize: 25, fontWeight: 600, borderRadius: 7, border: 0, padding: 11 }}
								placeholder="Escribe aquí el título..."
								value={data.title}
								onChange={(event) => {
									setData({ ...data, title: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO */}

					<Box direction="row-responsive" gap="medium">
						{/* IMAGEN DESTACADA */}
						<Box basis="1/2" background="white" pad="medium" round="small" elevation="medium">
							<MediaHandler type="images" value={image} height="200px" title="Imagen Destacada" uploadCallback={(ev) => setData({ ...data, image: ev })} galleryCallback={(ev) => setData({ ...data, image: ev.ref })} durationCallback={(ev) => console.log(ev)} />
						</Box>
						{/* FIN IMAGEN DESTACADA */}

						{/* TIPS MISION */}
						<Box basis="1/2" background="white" pad="medium" gap="medium" round="small" elevation="medium">
							<Box>
								<Heading color="titles" level="4" margin="none">
									Tips de la Misión
								</Heading>
							</Box>

							<Box flex="grow">
								<TextArea value={data.description} onChange={(ev) => setData({ ...data, description: ev.target.value })} placeholder="Escribe Aquí..." style={{ height: "100%", fontSize: 16, borderColor: "gainsboro", minHeight: "200px" }} resize={false} />
							</Box>
						</Box>
						{/* FIN TIPS MISION */}
					</Box>

					<Box background="white" round="small" pad="medium" elevation="medium">
						<Collapsible open={data.type === "POST"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Selecciona el contenido
									</Heading>
								</Box>
								<Box background="light-2" round="small" gap="small" pad="small">
									<Box round="small" overflow="hidden" height="200px">
										<Image key={previewImage ? previewImage : "imagePreview"} fit="cover" src={previewImage ? previewImage : "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png"} />
									</Box>
									<Text size="large">{data.content.title ? data.content.title : "Título del Contenido"}</Text>
									{previewText ? <div style={{ overflow: "hidden", maxHeight: 200 }} dangerouslySetInnerHTML={{ __html: previewText }}></div> : "Descripción"}
									{/* <Text>{data.content.type}</Text> */}
								</Box>

								<Box align="center">
									<Button primary onClick={() => toogleAddContentModal(true)} label={data.content.title ? "Cambiar Contenido" : "Seleccionar Contenido"} />
								</Box>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "TEST"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Rellena la pregunta
									</Heading>
								</Box>
								<QuestionGenerator
									points
									// allowDeleteQuestion
									withFeedback={data.content.withFeedback}
									withFeedbackCallback={(event) => editContent("withFeedback", event.target.checked)}
									value={data.content}
									answersCallback={(ev) => editContent("answers", ev)}
									question={data.content.question}
									questionCallback={(event) => editContent("question", event.target.value)}
								/>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "VIDEO"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Introduce tu Video
									</Heading>
								</Box>
								<MediaHandler
									type="videos"
									value={data.content.video}
									height="350px"
									style={{ marginBottom: 24 }}
									durationCallback={(ev) => {
										console.log(ev);
									}}
									uploadCallback={(ev) => {
										// console.log(ev)
										// setData({ ...data, media: ev });
										editContent("video", ev);
									}}
									galleryCallback={(ev) => {
										// setData({ ...data, media: ev.ref })
										editContent("video", ev.ref);
									}}
								/>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "ANSWER"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Enunciado de la pregunta
									</Heading>
								</Box>
								<Box>
									{/* <Text size="small">Escribe el enunciado de la pregunta o el caso que quieres plantear</Text> */}
									<TextareaAutosize
										maxRows={6}
										minRows={3}
										resize="none"
										style={{ background: "none", resize: "vertical", fontSize: 16, fontWeight: 600, width: "auto", borderColor: "lightgray", borderRadius: 5, padding: 11 }}
										placeholder="Escribe aquí la pregunta..."
										value={data.content.question}
										onChange={(event) => editContent("question", event.target.value)}
									/>
								</Box>
								<Box gap="small">
									<CheckBox checked={data.content.waitForPoints} onChange={(event) => editContent("waitForPoints", event.target.checked)} label="Asignar puntos tras corregir la respuesta" />
									<CheckBox checked={data.content.characterLimit} onChange={(event) => editContent("characterLimit", event.target.checked)} label="Limitar caracteres de la respuesta" />
									<Collapsible direction="vertical" open={data.content.characterLimit}>
										<TextInput type="number" size="medium" onChange={(event) => editContent("numberOfCharacters", parseInt(event.target.value))} placeholder={`Numero de caracteres de la respuesta...`} value={data.content.numberOfCharacters} />
									</Collapsible>
								</Box>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "EVENT"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Tipo de evento
									</Heading>
								</Box>
								<Box direction="row" gap="small" justify="start">
									<Button primary={data.content.eventType === "Offline"} onClick={() => editContent("eventType", "Offline")} label="Evento Presencial" />
									<Button primary={data.content.eventType === "Online"} onClick={() => editContent("eventType", "Online")} label="Evento Digital" />
								</Box>

								<Collapsible direction="vertical" open={data.content.eventType === "Offline"}>
									<Box gap="medium">
										<Box>
											<Heading color="titles" level="4" margin="none">
												Lugar del evento
											</Heading>
										</Box>
										<Box>
											<Box direction="row" align="center" border="all" borderRadius="small">
												<TextInput plain onChange={(event) => editContent("place", event.target.value)} placeholder={`Dirección...`} value={data.content.place} />
												<Button hoverIndicator onClick={getCoordinates} icon={<FormSearch />} />
											</Box>
											<Box height="250px" width="100%" round="small">
												<GoogleMapReact
													bootstrapURLKeys={{ key: "AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I" }}
													defaultCenter={{
														lat: -33.4372,
														lng: -70.6506,
													}}
													center={{
														lat: data.content.lat ? data.content.lat : -33.4372,
														lng: data.content.lng ? data.content.lng : -70.6506,
													}}
													defaultZoom={7}
													zoom={data.content.lat ? 20 : 7}>
													{data.content.lat && <Indicator lat={data.content.lat} lng={data.content.lng} style={{ position: "absolute", transform: "translate(-50%, -50%)" }} />}
												</GoogleMapReact>
											</Box>
										</Box>
									</Box>
								</Collapsible>

								<Box gap="medium">
									<Box>
										<Heading color="titles" level="4" margin="none">
											Fecha del evento
										</Heading>
									</Box>
									<Box background="light-2" pad="small" direction="row" justify="between" align="center" round="7px">
										<Text>{data.content.date ? `El evento se realizará el ${moment(data.content.date).format("DD [de] MM [del] YY [a las] HH:MM")}` : "Selecciona una fecha"}</Text>
										<Button hoverIndicator icon={<Schedule />} onClick={() => toogleScheduleModal(true)} />
									</Box>
								</Box>

								<Box gap="medium">
									<Box>
										<Heading color="titles" level="4" margin="none">
											Información del evento
										</Heading>
									</Box>
									{data.content.description !== undefined && (
										<Editor
											initialValue={data.content.description}
											placeholder="Información del evento..."
											init={{
												height: 350,
												menubar: false,
												branding: false,
												menubar: "file edit view insert format tools table tc help",
												plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
												toolbar:
													"undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help",
											}}
											onEditorChange={(event) => editContent("description", event)}
											apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
										/>
									)}
								</Box>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "UPLOAD"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Subir un video
									</Heading>
								</Box>
								<Box>
									<Text size="small">Reto a plantear o información sobre le video que tienen que completar</Text>
									<TextareaAutosize
										maxRows={3}
										minRows={3}
										resize="none"
										style={{ background: "none", resize: "vertical", fontSize: 16, fontWeight: 600, width: "auto", borderColor: "lightgray", borderRadius: 5, padding: 11 }}
										placeholder="Escribe aquí la pregunta..."
										value={data.content.description}
										onChange={(event) => editContent("description", event.target.value)}
									/>
								</Box>

								<Box gap="medium">
									<CheckBox checked={data.content.waitForPoints} onChange={(event) => editContent("waitForPoints", event.target.checked)} label="Asignar puntos tras corregir la respuesta" />
									<CheckBox checked={data.content.durationLimit} onChange={(event) => editContent("durationLimit", event.target.checked)} label="Limitar duración del video" />
									<Collapsible direction="vertical" open={data.content.durationLimit}>
										<Box>
											<Text size="small">Escribe los segundos de duración del video</Text>
											<TextInput type="number" onChange={(event) => editContent("seconds", parseInt(event.target.value))} placeholder={`Escribe aquí...`} value={data.content.seconds} />
										</Box>
									</Collapsible>
								</Box>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "BIO"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Completar perfil de usuario
									</Heading>
								</Box>
								<Box gap="small">
									<Text size="small">Campos a rellenar por el usuario</Text>
									<Box pad="medium" gap="medium" background="light-1" round="small">
										<CheckBox checked={data.content.team ? true : false} onChange={(event) => editContent("team", event.target.checked)} label="Equipo del usuario" />
										<CheckBox checked={data.content.position ? true : false} onChange={(event) => editContent("position", event.target.checked)} label="Puesto del usuario" />
										<CheckBox checked={data.content.skills ? true : false} onChange={(event) => editContent("skills", event.target.checked)} label="Habilidades del usuario" />
										<CheckBox checked={data.content.hobbies ? true : false} onChange={(event) => editContent("hobbies", event.target.checked)} label="Hobbies del usuario" />
										<CheckBox checked={data.content.bio ? true : false} onChange={(event) => editContent("bio", event.target.checked)} label="Biografía del usuario" />
										<CheckBox checked={data.content.imageProfile ? true : false} onChange={(event) => editContent("imageProfile", event.target.checked)} label="Imagen de Perfil del usuario" />
									</Box>
								</Box>
							</Box>
						</Collapsible>

						<Collapsible open={data.type === "TIMER"}>
							<Box gap="medium">
								<Box>
									<Heading color="titles" level="4" margin="none">
										Misión contador
									</Heading>
								</Box>

								<Box gap="small" margin={{ bottom: "small" }}>
									<Text size="small">Campos a rellenar por el usuario</Text>
									<TextInput placeholder="Segundos de duración..." type="number" value={data.content.timer} onChange={(event) => editContent("timer", parseInt(event.target.value))} />
								</Box>

								<Box gap="medium">
									<CheckBox checked={data.content.withMedia ? true : false} onChange={(event) => editContent("withMedia", event.target.checked)} label="Ejercicio con Audio" />
									<Collapsible direction="vertical" open={data.content.withMedia}>
										<Box background="light-2" round="small">
											<MediaHandler type="audios" value={media} height="50px" uploadCallback={(ev) => setData({ ...data, media: ev })} galleryCallback={(ev) => setData({ ...data, media: ev.ref })} durationCallback={(ev) => editContent("timer", Math.round(ev))} />
										</Box>
									</Collapsible>
								</Box>

								<Box gap="medium">
									<CheckBox checked={data.content.withSteps ? true : false} onChange={(event) => editContent("withSteps", event.target.checked)} label="Ejercicio con Pasos" />
									<Collapsible direction="vertical" open={data.content.withSteps}>
										<TimerGenerator value={data.content} title="Pasos de la ejercicio" stepsCallback={(ev) => editContent("steps", ev)} />
									</Collapsible>
								</Box>
							</Box>
						</Collapsible>
					</Box>
				</Box>

				<Box basis="1/3" gap="medium">
					{/* BOTONES PUBLICACION */}
					<Box background="white" pad="medium" gap="medium" round="small" elevation="medium">
						<Box>
							<Heading color="titles" level="4" margin="none">
								Publicación
							</Heading>
						</Box>

						<Box>
							<Button disabled={data.title !== "" ? false : true} primary label={id ? "Actualizar Mision" : "Crear Mision"} onClick={() => tooglePublishModal(true)} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}

					{/* PUNTOS DE LA MISION */}
					<Box background="white" overflow="hidden" pad="medium" gap="medium" round="small" elevation="medium">
						<Box>
							<Heading color="titles" level="4" margin="none">
								Puntos de la Misión
							</Heading>
						</Box>

						<Box>
							<TextInput
								placeholder="Escribe aquí..."
								type="number"
								value={data.score}
								onChange={(event) => {
									setData({ ...data, score: parseInt(event.target.value) });
								}}
							/>
						</Box>
					</Box>
					{/* FIN PUNTOS DE LA MISION */}

					{/* TIPO DE CONTENIDO */}
					<Box background="white" pad="medium" gap="medium" round="small" elevation="medium">
						<Box>
							<Heading color="titles" level="4" margin="none">
								Tipo de Misión
							</Heading>
						</Box>

						<Box gap="small">
							<Button plain={false} label="Contenido" icon={<DocumentText />} onClick={() => setData({ ...data, type: "POST" })} primary={data.type === "POST" && true} />
							<Button plain={false} label="Pregunta" icon={<Task />} onClick={() => setData({ ...data, type: "TEST" })} primary={data.type === "TEST" && true} />
							<Button plain={false} label="Video" icon={<CirclePlay />} onClick={() => setData({ ...data, type: "VIDEO" })} primary={data.type === "VIDEO" && true} />
							<Button plain={false} label="Desarrolla" icon={<TextAlignFull />} onClick={() => setData({ ...data, type: "ANSWER" })} primary={data.type === "ANSWER" && true} />
							<Button plain={false} label="Evento" icon={<Schedule />} onClick={() => setData({ ...data, type: "EVENT" })} primary={data.type === "EVENT" && true} />
							<Button plain={false} label="Subir Video" icon={<FormUpload />} onClick={() => setData({ ...data, type: "UPLOAD" })} primary={data.type === "UPLOAD" && true} />
							<Button plain={false} label="Completar Perfil" icon={<ContactInfo />} onClick={() => setData({ ...data, type: "BIO" })} primary={data.type === "BIO" && true} />
							<Button
								// disabled={true}
								plain={false}
								label="Timer"
								icon={<Clock />}
								onClick={() => setData({ ...data, type: "TIMER" })}
								primary={data.type === "TIMER" && true}
							/>
							{/* <Button plain={false} icon={<Music />} onClick={() => setData({ ...data, contentType: "Audio" })} primary={data.contentType === "Audio" && true} /> */}
						</Box>
					</Box>
					{/* FIN TIPO DE CONTENIDO */}
				</Box>
			</Box>
			{/* MODALES */}

			{scheduleModal && (
				<ScheduleModal
					prevDate={data.publishDate}
					cancelCallback={() => {
						toogleScheduleModal(!scheduleModal);
					}}
					dateCallback={(ev) => {
						editContent("date", ev);
						toogleScheduleModal(!scheduleModal);
					}}
				/>
			)}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" pad="medium" gap="medium" align="center" justify="center">
						<Heading level="3" margin="none">
							{id ? "Actualizar" : "Publicar"} misión
						</Heading>
						<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este misión, ¿estás seguro que deseas hacerlo?</Text>

						<Box direction="row" gap="small" width="100%" justify="between">
							<Button label="Cancelar" onClick={() => tooglePublishModal(!publishModal)} />
							<Button
								primary
								label={id ? "Actualizar" : "Publicar"}
								onClick={() => {
									tooglePublishModal(!publishModal);
									id ? editMission() : addMission();
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{addContentModal && (
				<ContentLibrary
					single
					closeCallback={() => toogleAddContentModal(false)}
					submitCallback={(cb) => {
						// console.log(cb)
						editContent("id", cb[0].id);
						editContent("contentType", cb[0].contentType);
						editContent("title", cb[0].title);
						editContent("image", cb[0].image ? image : null);
						setPreviewImage(cb[0].image);
						setPreviewText(cb[0].content);
						toogleAddContentModal(false);
					}}
				/>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ContentForm);
