import React, { useContext } from "react";
import { useField } from "formik";
import { Box, Heading, Text, TextInput } from "grommet";
import AuthContext from "../../context/AuthContext";

const TextField = ({ label, moreInfo, placeholder, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const { customization } = useContext(AuthContext);
	return (
		<Box flex gap="small">
			<Box>
				<Heading color={customization.mainColor} level="4" margin="none">
					{label}
				</Heading>
			</Box>

			<Box>
				<TextInput
					{...field}
					value={field.value || ""}
					{...props}
					style={{ borderRadius: 7 }}
					placeholder={placeholder}
					// disabled={data.contentType === "VIDEO" || data.contentType === "AUDIO"}
					onChange={(event) => helpers.setValue(event.target.value)}
				/>
				{moreInfo && (
					<Text style={{ marginTop: 10 }} size="small">
						{moreInfo}
						{/* El contenido tiene una duración de {secondsToTime(data.contentLength)} minutos */}
					</Text>
				)}
			</Box>
		</Box>

		// <Box background="white" round="small" gap="medium" pad="medium" elevation="medium">
		// 	<Heading color={customization.mainColor} level="4" margin="none">
		// 		{label}
		// 	</Heading>

		// 	<Box>
		// 		<TextareaAutosize {...field} {...props} maxRows={6} rows={1} resize="none" style={{ resize: "none", fontSize: 25, fontWeight: 600, borderRadius: 7, border: 0, padding: 11 }} />
		// 	</Box>
		// </Box>
	);
};

export default TextField;