
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import {
    Box,
    ResponsiveContext
} from 'grommet';

import SideMenu from '../../components/SideMenu'
import Evaluations from './Evaluations';
import EvaluationForm from './EvaluationForm';


const RouterUsers = ({ area }) => {

    const size = useContext(ResponsiveContext)

    const routes = [
        {
            route: "/evaluations",
            icon: "Trophy",
            title: "Evaluaciones"
        },
        {
            route: "/evaluations/new",
            icon: "Add",
            title: "Nueva Evaluación"
        }
    ]

    return (
        <Box direction="row" gap="medium" justify="center" pad="medium">

            {size !== "small" &&
                <Box width="100px">
                    <SideMenu routes={routes} sidebarStatus={true} />
                </Box>
            }

            <Box flex width={{ max: "xlarge" }}>
                <Route path="/evaluations" component={Evaluations} exact />
                <Route path="/evaluations/new" component={EvaluationForm} exact />
            </Box>
        </Box>
    );
}

export default RouterUsers;