import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select, TextArea, Video } from "grommet";
import { Bold, Task, DocumentText, FormPrevious, Duplicate, Ascend, Descend, CirclePlay, Close, Trash, Gallery, CloudUpload, Music } from "grommet-icons";

import { uuid } from "uuidv4";

import MediaHandler from "../../components/MediaHandler";
import { Editor } from "@tinymce/tinymce-react";

import { withRouter, useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import { firestore } from "firebase";

import { SketchPicker, BlockPicker, SwatchesPicker, TwitterPicker } from "react-color";

const ConfigForm = ({ history }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [loadingMedia, setLoadingMedia] = useState(false);
	const [publishModal, tooglePublishModal] = useState(false);

	const [mainLogo, setMainLogo] = useState(null);
	const [homeLogo, setHomeLogo] = useState(null);
	const [placeholder, setPlaceholder] = useState(null);
	const [userPlaceholder, setUserPlaceholder] = useState(null);

	const [quillCustomization, setQuillCustomization] = useState("");

	const [customizationData, setCustomizationData] = useState({
		name: "",
		mainColor: "",
		accentcolor: "",
		headerBackgroundColor: "",
		footerBackgroundColor: "",
		likePoints: 0,
		commentPoints: 0,
		achievements: [],
	});

	const [data, setData] = useState({});

	// const [createChallenge] = useMutation(mutations.createChallenge, {
	//     refetchQueries: [{ query: queries.getChallenges }]
	// });

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		console.log(tenant.ref.id);
		tenant.ref.get().then((doc) => {
			console.log(doc.data().customization);
			setData(doc.data());
			doc.data().customization && setCustomizationData(doc.data().customization);
		});
	};

	const updateCustomization = () => {
		tenant.ref
			.update({
				customization: { ...data.customization, ...customizationData },
			})
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
	};

	// const publishChallenge = async () => {
	//     let variables = {
	//         name: customizationData.name,
	//         description: quillCustomization,
	//         image: customizationData.image,
	//         often: customizationData.often,
	//         actions: customizationData.actions,
	//         amount: customizationData.amount,
	//         challengeGroup: customizationData.customizationGroup.id
	//     };

	//     createChallenge({ variables })
	//         .then(response => {
	//             console.log(response)
	//             history.push("/challenges")
	//         })
	// }

	const editCustomization = async () => {
		let variables = {
			customizationID: "5e55137efbbc064fbd0beb24",
			name: customizationData.name,
			description: quillCustomization,
			mainLogo: customizationData.mainLogo,
			homeLogo: customizationData.homeLogo,
			placeholder: customizationData.placeholder,
			placeholderUser: customizationData.placeholderUser,
			primaryColor: customizationData.primaryColor,
			secondaryColor: customizationData.secondaryColor,
			users: customizationData.users.map((u) => u.id),
		};

		updateCustomization({ variables }).then((response) => {
			console.log(response);
			// history.push("/challenges")
		});
	};

	const editSection = (index, input, field) => {
		const { achievements } = customizationData;
		var newArr = achievements;
		newArr[index][field] = input;
		setCustomizationData({ ...customizationData, achievements: [...newArr] });
	};

	const deleteAchievement = (achievementIndex) => {
		let newArr = customizationData;
		setCustomizationData({
			...newArr,
			achievements: [
				...newArr.achievements.filter((el, i) => {
					return achievementIndex !== i ? el : null;
				}),
			],
		});
		updateCustomization();
	};

	const handleTrophiesChange = (e, index) => {
		const { value } = e.target;
		const newArr = customizationData.achievements || [];
		newArr[index][e.target.name] = value;
		setCustomizationData({ ...customizationData, achievements: [...newArr] });
	};

	return (
		<Box>
			{/* <Box margin={{ top: "medium" }} align="start">
                {id && <Button icon={<FormPrevious />} onClick={() => history.goBack()} label="Volver" />}
            </Box> */}
			<Box direction="row-responsive" gap="medium">
				<Box basis="2/3" gap="medium">
					{/* TITULO DE LA PUBLICACIÓN */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Identificador
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<TextInput
								plain
								size="large"
								placeholder="Escribe aquí..."
								value={data.name}
								onChange={(event) => {
									setCustomizationData({ ...customizationData, name: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO DE LA PUBLICACIÓN */}

					<Box direction="row" wrap>
						<Box background="white" width="100%" pad="small" margin={{ bottom: "small", right: "small" }} overflow="hidden" round="small" elevation="medium">
							<Heading level="4">Colores</Heading>

							<Box direction="row" gap="small">
								<Box gap="5px">
									<Text size="small">Color Principal</Text>
									<input
										type="color"
										color={data.mainColor}
										value={data.mainColor}
										onChange={(ev) => {
											setCustomizationData({ ...customizationData, mainColor: ev.target.value });
											console.log(ev.target.value);
										}}
									/>
								</Box>
								<Box gap="5px">
									<Text size="small">Color de Acento</Text>
									<input type="color" value={data.accentColor} onChange={(ev) => setCustomizationData({ ...customizationData, accentColor: ev.target.value })} />
								</Box>
							</Box>

							<Box direction="row" gap="small" margin={{ top: "15px" }}>
								<Box gap="5px">
									<Text size="small">Color Cabecera</Text>
									<input type="color" value={customizationData?.headerBackgroundColor} onChange={(ev) => setCustomizationData({ ...customizationData, headerBackgroundColor: ev.target.value })} />
								</Box>
								<Box gap="5px">
									<Text size="small">Color Elementos Cabecera</Text>
									<input type="color" value={customizationData?.headerElementsColor} onChange={(ev) => setCustomizationData({ ...customizationData, headerElementsColor: ev.target.value })} />
								</Box>
								<Box gap="5px">
									<Text size="small">Color Menu inferior</Text>
									<input type="color" value={customizationData?.footerBackgroundColor} onChange={(ev) => setCustomizationData({ ...customizationData, footerBackgroundColor: ev.target.value })} />
								</Box>
								<Box gap="5px">
									<Text size="small">Color Elementos Menu inferior</Text>
									<input type="color" value={customizationData?.footerButtonColorActive} onChange={(ev) => setCustomizationData({ ...customizationData, footerButtonColorActive: ev.target.value })} />
								</Box>
							</Box>
						</Box>
					</Box>

					{/* COMIENZO ACTIVACION PUNTOS */}
					<Box direction="row" wrap>
						<Box background="white" width="100%" pad="small" margin={{ bottom: "small", right: "small" }} overflow="hidden" round="small" elevation="medium">
							<Box gap="5px">
								<Heading level="4">Puntos</Heading>
								<Box direction="row-responsive" fill="horizontal" align="center">
									<TextInput
										style={{ borderRadius: 7, width: "30rem" }}
										placeholder="Puntos por like"
										value={customizationData?.likePoints}
										type="number"
										size="small"
										onChange={(e) => {
											setCustomizationData({ ...customizationData, likePoints: e.target.value });
										}}
									/>
									<Text size="small" textAlign="start" textAlign="center" margin="10px">
										Likes
									</Text>
								</Box>
								<Box direction="row-responsive" fill="horizontal" align="center" justify="between">
									<TextInput
										style={{ borderRadius: 7, width: "30rem" }}
										placeholder="Puntos por comentario"
										value={customizationData?.commentPoints}
										type="number"
										size="small"
										onChange={(e) => {
											setCustomizationData({ ...customizationData, commentPoints: e.target.value });
										}}
									/>
									<Text size="small" textAlign="center" margin="10px">
										Comentario
									</Text>
								</Box>
							</Box>
						</Box>
					</Box>
					{/* FIN ACTIVACION PUNTOS */}

					{/* COMIENZO LOGROS */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color={customizationData?.mainColor} level="4" margin="none">
								Añadir Logros
							</Heading>
						</Box>
						<Box pad="small" gap="small">
							{customizationData?.achievements?.length === 0 ? (
								<Box pad="small" round="small" background="light-2" align="center">
									<Text textAlign="center">Sin logros</Text>
								</Box>
							) : (
								customizationData.achievements?.map((achievement, i) => {
									const achievementIndex = i;
									return (
										<Box key={i} pad="small" round="small" background="light-1">
											<Box direction="row" gap="small">
												<Box width="200px">
													<MediaHandler
														type="images"
														value={achievement?.img}
														height="200px"
														uploadCallback={(ev) => {
															editSection(i, ev, "image");
															editSection(i, ev, "orgImage");
														}}
														galleryCallback={(ev) => {
															editSection(i, ev.ref, "image");
															editSection(i, ev.ref, "orgImage");
														}}
														durationCallback={(ev) => console.log(ev)}
													/>
												</Box>
												<Box flex>
													<Box pad="small" direction="row" justify="between" gap="small">
														<TextInput placeholder="Nombre del Logro" name="name" value={achievement?.name} onChange={(e) => handleTrophiesChange(e, achievementIndex)} />
														<Button hoverIndicator icon={<Trash />} onClick={() => deleteAchievement(achievementIndex)} />
													</Box>
													<Box pad="small" gap="5px">
														<Text size="xsmall">Descripción</Text>
														<TextArea resize="vertical" placeholder="Descripcion del logro" name="description" hoverIndicator value={achievement?.description} size="small" onChange={(e) => handleTrophiesChange(e, achievementIndex)} />
													</Box>
													<Box pad="small" gap="5px">
														<Text size="xsmall">Puntos</Text>
														<TextInput resize="vertical" placeholder="Puntos" name="pointsToAchieve" hoverIndicator value={achievement?.pointsToAchieve} size="small" onChange={(e) => handleTrophiesChange(e, achievementIndex)} />
													</Box>
												</Box>
											</Box>
											<Box gap="small" pad="small"></Box>
										</Box>
									);
								})
							)}
						</Box>
						<Box align="center" margin="small">
							<Button
								onClick={() =>
									setCustomizationData({
										...customizationData,
										achievements: [
											...customizationData.achievements || [],
											{
												name: "",
												description: "",
												img: "",
												pointsToAchieve: 0,
												id: uuid(),
											},
										],
									})
								}
								label="Añadir Logro"
							/>
						</Box>
					</Box>
					{/* FIN LOGROS */}
					<Box direction="row" wrap>
						{/* IMAGEN DESTACADA */}
						<Box background="white" width="49%" pad="small" margin={{ bottom: "small", right: "small" }} overflow="hidden" round="small" elevation="medium">
							<MediaHandler
								type="images"
								value={mainLogo}
								height="300px"
								title="Logo Principal"
								uploadCallback={(ev) => setCustomizationData({ ...customizationData, mainLogo: ev[0].id })}
								galleryCallback={(ev) => setCustomizationData({ ...customizationData, mainLogo: ev.id })}
								durationCallback={(ev) => console.log(ev)}
							/>
						</Box>

						<Box background="white" width="49%" pad="small" margin={{ bottom: "small" }} overflow="hidden" round="small" elevation="medium">
							<MediaHandler
								type="images"
								value={homeLogo}
								height="300px"
								title="Logo Menu App"
								uploadCallback={(ev) => setCustomizationData({ ...customizationData, homeLogo: ev[0].id })}
								galleryCallback={(ev) => setCustomizationData({ ...customizationData, homeLogo: ev.id })}
								durationCallback={(ev) => console.log(ev)}
							/>
						</Box>
						{/* <Box background="white" width="49%" pad="small" margin={{ bottom: "small", right: "small" }} overflow="hidden" round="small" elevation="medium">
							<MediaHandler
								type="images"
								value={placeholder}
								height="300px"
								title="Placeholder de Imagenes"
								uploadCallback={(ev) => setCustomizationData({ ...customizationData, placeholder: ev[0].id })}
								galleryCallback={(ev) => setCustomizationData({ ...customizationData, placeholder: ev.id })}
								durationCallback={(ev) => console.log(ev)}
							/>
						</Box>
						<Box background="white" width="49%" pad="small" margin={{ bottom: "small" }} overflow="hidden" round="small" elevation="medium">
							<MediaHandler
								type="images"
								value={userPlaceholder}
								height="300px"
								title="Placeholder de Usuarios"
								uploadCallback={(ev) => setCustomizationData({ ...customizationData, placeholderUser: ev[0].id })}
								galleryCallback={(ev) => setCustomizationData({ ...customizationData, placeholderUser: ev.id })}
								durationCallback={(ev) => console.log(ev)}
							/>
						</Box> */}

						{/* FIN IMAGEN DESTACADA */}
					</Box>
				</Box>

				<Box basis="1/3" gap="medium">
					{/* BOTONES PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Actualizar Configuración
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Button disabled={customizationData.name !== "" ? false : true} primary label={"Actualizar"} onClick={() => tooglePublishModal(true)} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}
				</Box>
			</Box>
			{/* MODALES */}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" height="medium">
						<Button
							label="Editar"
							onClick={() => {
								tooglePublishModal(!publishModal);
								updateCustomization();
							}}
						/>
					</Box>
				</Layer>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ConfigForm);
