import React, { useContext, useEffect } from "react";
import { useField } from "formik";
import { Box, Text, Select, Button } from "grommet";
import { Close } from "grommet-icons";
import AuthContext from "../../context/AuthContext";

const SelectField = ({ label, icon, type, callback, clickCallback, options, emptyText, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const { customization } = useContext(AuthContext);
	return (
		<Box gap="small">
			<Select {...field} {...props} multiple options={options} onChange={({ value: nextValue }) => helpers.setValue(nextValue)} value={field.value} />

			<Box gap="small">
				{field.value.length === 0 ? (
					<Box align="center" fill justify="center">
						<Text textAlign="center">{emptyText}</Text>
					</Box>
				) : (
					field.value.map((option, i) => (
						<Button
							key={i}
							onClick={() => {
								helpers.setValue(field.value.filter((el) => el !== option));
							}}
							label={options.find((el) => el.id === option)?.name}
							primary
							color={customization.mainColor}
							icon={<Close />}
						/>
					))
				)}
			</Box>
		</Box>
	);
};

export default SelectField;
