import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { includes as lodashIncludes } from "lodash";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ component: Component, allowed, ...rest }) => {
	const { userData, tenant } = useContext(AuthContext);
	// console.log(tenant.id, userData.tenants.map(el =>el.id), userData.role, "IS OK ?", allowed,  lodashIncludes(allowed, userData.role),  userData.tenants.map(el => el.id).includes(tenant.id))
	return (
		<Route
			{...rest}
			render={(props) => 
				lodashIncludes(allowed, userData.role) && userData.tenants.map(el => el.id).includes(tenant.id) ? (
					
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: `${process.env.PUBLIC_URL}/${tenant.url}/login`,
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
