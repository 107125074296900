import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import { Box, ResponsiveContext } from "grommet";

import Courses from "./Courses";
import CourseForm from "./CourseForm";
import Exams from "./Exams";
import ExamForm from "./ExamForm";
import SmartContentForm from "./SmartContentForm";
import SmartLibrary from "./SmartLibrary";

import SideMenu from "../../components/SideMenu";
import CoursesAssigned from "./CoursesAssigned";

const RouterSmartLearning = ({ area }) => {
	const size = useContext(ResponsiveContext);
	const { url } = useRouteMatch();

	const routes = [
		{
			route: `${url}/newcourse`,
			icon: "Add",
			title: "Añadir Curso",
			allowed: ["Admin"],
		},
		{
			route: `${url}/courses`,
			icon: "Book",
			title: "Cursos",
			allowed: ["Admin", "Teacher"],
		},
		{
			route: `${url}/newcontent`,
			icon: "AddCircle",
			title: "Nuevo Contenido",
			allowed: ["Admin"],
		},
		{
			route: `${url}/library`,
			icon: "Catalog",
			title: "Contenidos",
			allowed: ["Admin"],
		},
		{
			route: `${url}/newexam`,
			icon: "Task",
			title: "Nuevo Examen",
			allowed: ["Admin"],
		},
		{
			route: `${url}/exams`,
			icon: "Bundle",
			title: "Exámenes",
			allowed: ["Admin"],
		},
	];

	return (
		<Box direction="row" gap="medium" justify="center" pad="medium">
			{size !== "small" && (
				<Box width="100px">
					<SideMenu routes={routes} sidebarStatus={true} />
				</Box>
			)}
			<Box flex width={{ max: "xlarge" }}>
				<Route exact path={`${url}/courses`} component={Courses} exact />
				<Route path={`${url}/newcourse`} component={CourseForm} />
				<Route path={`${url}/editcourse/:id`} component={CourseForm} />
				<Route path={`${url}/coursesassigned/:id`} component={CoursesAssigned} />
				<Route path={`${url}/library`} component={SmartLibrary} />
				<Route path={`${url}/newcontent`} component={SmartContentForm} />
				<Route path={`${url}/editcontent/:id`} component={SmartContentForm} />
				<Route path={`${url}/exams`} component={Exams} />
				<Route path={`${url}/newexam`} component={ExamForm} />
				<Route path={`${url}/editexam/:id`} component={ExamForm} />
			</Box>
		</Box>
	);
};

export default RouterSmartLearning;
