import React, { useEffect, useState } from "react";
import { useField } from "formik";
import MediaHandler from "../MediaHandler";
import { storage } from "firebase";

const MediaField = ({ label, height, type, callback, durationCallback, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const [media, setMedia] = useState();

	useEffect(() => {
		field.value &&
			storage()
				.ref(field.value)
				.getDownloadURL()
				.then((url) => setMedia(url))
				.catch((err) => console.log(err));
	}, [field.value]);
	return (
		<MediaHandler
			type={type}
			value={media}
			height={height}
			title={label}
			uploadCallback={(ev) => helpers.setValue(ev)}
			galleryCallback={(ev) => helpers.setValue(ev.ref)}
			durationCallback={(ev) => callback && durationCallback(ev)}
		/>
	);
};

export default MediaField;
