import React, { useState, useEffect, useContext } from "react";
import { Box, DataTable, Heading, Image, CheckBox, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Add, Trash, UserAdd, Duplicate, View, Checkmark, InProgress } from "grommet-icons";

import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { withRouter } from "react-router";

import { firestore, storage } from "firebase";
import UsersLibrary from "../../components/UsersLibrary";
import InviteModal from "../../components/InviteModal";
import AuthContext from "../../context/AuthContext";

const Notifications = ({ location, history }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [data, setData] = useState([]);

	useEffect(() => {
		let unsubscribe = tenant.ref.collection("customnotifications").onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					return { ...doc.data(), ref: doc.ref, id: doc.id };
				})
			).then((res) => {
				setData(res);
				setFiltered(res);
			});
		});

		return () => unsubscribe();
	}, []);

	console.log(data);

	const duplicateElement = async (id) => {
		tenant.ref
			.collection("customnotifications")
			.doc(id)
			.get()
			.then((doc) =>
				tenant.ref.collection("customnotifications").add({
					...doc.data(),
                    notification: {
                        ...doc.data().notificacion,
                        sent: false
                    },
					form: {
						...doc.data().form,
						subject: (doc.data()?.form?.subject ?? "") + " copy",
					},
				})
			);
	};

	const deleteElement = (id) => {
		tenant.ref.collection("customnotifications").doc(id).delete();
	};

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Notificaciones
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow">
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.filter((el) => el.subject.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						{userData.role === "Admin" && <Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/notifications/newnotification`)} icon={<Add />} label="Nueva Notificación" />}
					</Box>
				</Box>

				<ReactTable
					data={filtered}
					defaultPageSize={10}
					// loading={loading}
					loadingText="Cargando Datos..."
					previousText="Notificaciones Anteriores"
					nextText="Notificaciones Siguientes"
					noDataText="Sin Datos"
					pageText="Página"
					ofText="de"
					rowsText="filas"
					columns={[
						{
							Header: "Asunto",
							headerStyle: { padding: 15 },
							id: "name",
							style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) => datum.form?.subject,
						},
						{
							Header: "Fecha",
							headerStyle: { padding: 15 },
							id: "date",
							width: 170,
							style: { display: "flex", fontSize:14, whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) => datum?.notification?.sentAt ? moment(datum?.notification?.sentAt.toDate()).format("DD-MM-YY [a las] HH:MM") : "",
						},
						{
							Header: "Destinatarios",
							id: "users",
							headerStyle: { padding: 15 },
							width: 150,
							resizable: false,
							style: { display: "flex", paddingLeft: 15, justifyContent: "center", paddingRight: 15, alignItems: "center" },
							accessor: (datum) => datum?.users?.length || 0,
						},
						{
							Header: "Estado",
							headerStyle: { padding: 15 },
							id: "status",
							width: 120,
							resizable: false,
							sortable: false,
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (datum.notification?.sent ? <Checkmark /> : <InProgress />),
						},
						{
							Header: "Ver",
							headerStyle: { padding: 15 },
							id: "preview",
							width: 120,
							resizable: false,
							sortable: false,
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/notifications/editnotification/${datum.id}` }}>
									<Button fill color="gray" icon={<View />} hoverIndicator />
								</Link>
							),
						},

						{
							Header: "Duplicar",
							headerStyle: { padding: 15 },
							id: "duplicate",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							// accessor: datum => <Button onClick={() => setUsersModal({ status: true, data: datum })} color="gray" icon={<UserAdd />} hoverIndicator />,
							accessor: (datum) => <Button color="gray" icon={<Duplicate />} hoverIndicator onClick={() => duplicateElement(datum.id)} />,
						},
						{
							Header: "Eliminar",
							headerStyle: { padding: 15 },
							id: "delete",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Button
									color="gray"
									hoverIndicator
									icon={<Trash />}
									onClick={() => {
										toogleDeleteModal(!deleteModal);
										setContentIDToDelete(datum.id);
									}}
								/>
							),
						},
					]}
				/>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar esta Notificación?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									deleteElement(contentIDToDelete);
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Notifications);
