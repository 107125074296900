import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams, useRouteMatch, withRouter } from "react-router-dom";

import { Box, Button, Collapsible, Image, Anchor, Header } from "grommet";
import * as Icons from "grommet-icons";
import logo from "../assets/images/logo.jpg";
import AuthContext from "../context/AuthContext";
import { NavLink } from "react-router-dom";

const TopMenu = ({ history, location, tenantUrl, changeTenantCallback }) => {
	const { authFunctions, customization, userData, tenant } = useContext(AuthContext);
	const { pathname } = location;
	const params = useParams();
	let { path, url } = useRouteMatch();

	if (pathname.includes("/login") || history.location.pathname.split("/").length < 3 || pathname === "/download") {
		return <Box></Box>;
	} else {
		return (
			<Header pad="small" elevation="large" background="brand" style={{ position: "sticky", top: 0, zIndex: 19 }}>
				{/* <Box pad={{ vertical: "small", horizontal: "medium" }} align="center" justify="between" direction="row"> */}

				<Box onClick={() => history.push(`${process.env.PUBLIC_URL}/${tenantUrl}`)} width="130px" height="60px">
					<Image src={customization.logo} fit="contain" />
				</Box>

				<Box direction="row" gap="medium">
					{userData.role === "Admin" && <NavLink activeClassName="menuSelected" style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/content/") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/content/library`}>
						Contenidos
					</NavLink>}
					{/* <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/challenges/") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/challenges`}>
						Retos
					</Link> */}
					<Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/smartlearning/") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/smartlearning/courses`}>
						Cursos
					</Link>
					<Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/training/") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/training/itineraries`}>
						Desafíos
					</Link>
					<Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/forums") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/forums`}>
						Foros
					</Link>
					{/* {userData.role === "Teacher" && <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/config/tutorials") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/config/tutorials`}>
						Tutoriales
					</Link>} */}
					{/* <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/evaluations") ? "#FF9A16" : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/evaluations`}>
						Evaluaciones
					</Link> */}
					{userData.role === "Admin" && <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/users") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/users`}>
						<Icons.UserSettings color={pathname.includes("/users") ? customization.accentColor : customization.headerElementsColor} style={{ color: customization.headerElementsColor }} />
					</Link>}
					{/* {userData.role === "Admin" && <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/analytics") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/analytics`}>
						<Icons.Analytics color={pathname.includes("/analytics") ? customization.accentColor : customization.headerElementsColor} />
					</Link>} */}
					{userData.role === "Admin" && <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/notifications") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/notifications`}>
						<Icons.Notification color={pathname.includes("/notifications") ? customization.accentColor : customization.headerElementsColor} />
					</Link>}
					{userData.role === "Admin" && <Link style={{ fontWeight: "bold", fontSize: 16, color: pathname.includes("/config") ? customization.accentColor : customization.headerElementsColor, textDecoration: "none" }} to={`${process.env.PUBLIC_URL}/${tenantUrl}/config`}>
						<Icons.ShieldSecurity color={pathname.includes("/config") ? customization.accentColor : customization.headerElementsColor} />
					</Link>}
					{userData.tenants?.length > 1 && <Anchor color="white" onClick={changeTenantCallback}>
						<Icons.Update color={customization.headerElementsColor} />
					</Anchor>}
					<Anchor color="white" onClick={() => authFunctions.signOut(tenant.url)}>
						<Icons.Logout color={customization.headerElementsColor} />
					</Anchor>
				</Box>
			</Header>
		);
	}
};

export default withRouter(TopMenu);
