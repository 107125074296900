import React, { Component } from 'react';
import {
    Box,
    Heading,
    Grommet,
    FormField,
    Tab,
    Tabs,
    Text,
    TextInput,
    CheckBox,
    Collapsible,
    Select,
    Button,
    TextArea
} from "grommet";

import {
    Attraction,
    Car,
    CircleInformation,
    Currency,
    TreeOption,
} from "grommet-icons";


const defaultOptions = [];
const objectOptions = [];
for (let i = 1; i <= 200; i += 1) {
    defaultOptions.push(`option ${i}`);
    objectOptions.push({
        lab: `option ${i}`,
        val: i,
        dis: i % 5 === 0,
        sel: i % 13 === 0
    });
}


class notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            value: [],
            // sendAll:false
        };
    }

    onActive = index => this.setState({ index });
    render() {
        const { index } = this.state;
        return (
            <Box flex >
                <Heading>Notificaciones</Heading>
                <Text>Selecciona el grupo de usuarios al que deseas enviar las notificaciones</Text>
                <CheckBox
                    checked={this.state.sendAll}
                    label="Enviar a todo el mundo"
                    onChange={(event) => this.setState({ sendAll: event.target.checked })}
                />

                <Collapsible open={!this.state.sendAll}>
                    <Box
                        background="light-2"
                        round="medium"
                        pad=""
                        align="center"
                        justify="center"
                    >
                        <Select
                            size="medium"
                            placeholder="Select"
                            multiple
                            closeOnChange={false}
                            disabledKey="dis"
                            labelKey="lab"
                            valueKey="val"
                            value={this.state.value}
                            options={this.state.options}
                            onChange={({ value: nextValue }) => {
                                this.setState({ value: nextValue })
                                console.log(this.state.value)
                            }
                            }
                            onClose={() => this.setState({ options: objectOptions })}
                            onSearch={text => {
                                const exp = new RegExp(text, "i");
                                this.setState({
                                    options: objectOptions.filter(o => exp.test(o.lab))
                                });
                            }}
                        />
                    </Box>
                    <Text>
                        {this.state.value.map((option) => {
                            return <Button
                                // icon={<Icons.Edit />}
                                label={option.lab}
                                onClick={() => { }}
                            />
                        }
                        )}
                    </Text>
                </Collapsible>
                <Text>Título de la notificación</Text>
                <TextInput
                    placeholder="Título"
                    value={this.state.title}
                    onChange={event => this.setState({ title: event.target.value })}
                />
                <TextArea
                    placeholder="type here"
                    value={this.state.body}
                    onChange={event => this.setState({body:event.target.value})}
                />
            </Box>
        );
    }
}

export default notifications;