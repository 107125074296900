import React, { useContext } from "react";
import { useField } from "formik";
import { Box, Heading } from "grommet";
import AuthContext from "../../context/AuthContext";
import TextareaAutosize from "react-textarea-autosize";

const TitleField = ({ label, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const { customization } = useContext(AuthContext);
	return (
		<Box background="white" round="small" gap="medium" pad="medium" elevation="medium">
			<Heading color={customization.mainColor} level="4" margin="none">
				{label}
			</Heading>

			<Box>
				<TextareaAutosize {...field} {...props} maxRows={6} rows={1} resize="none" style={{ resize: "none", fontSize: 25, fontWeight: 600, borderRadius: 7, border: 0, padding: 11 }} />
			</Box>
		</Box>
	);
};

export default TitleField;
