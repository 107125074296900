import React, { useState, useMemo, useReducer, useEffect } from "react";
import { Route, Redirect, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Grommet, Layer, Box, Heading, Text } from "grommet";

import Users from "./users/routerUsers";
import Content from "./content/routerContent";
import Training from "./training/routerTraining";
import Login from "./Login";
import NoTenant from "./NoTenant";
import PrivateRoute from "../components/PrivateRoute";
import Courses from "./smartlearning/routerSmartLearning";
import Forums from "./forums/routerForums";
import Challenges from "./challenges/routerChallenges";
import Notifications from "./notifications/notificationsRouter";
import Config from "./config/routerConfig";
import Webinar from "./webinars/routerWebinars";
import Analytics from "./analytics/Analytics";

import AuthContext from "../context/AuthContext";
import AuthReducer from "../reducers/AuthReducer";

import { firestore, auth } from "firebase";

import TopMenu from "../components/topMenu";
import Evaluations from "./socialappraisal/routerSocialAppraisal";

const AppNavigator = () => {
	const initialState = {
		isLoading: true,
		authed: false,
		selectTenant: false,
		resetPass: false,
	};

	const history = useHistory();
	const location = useLocation();
	const { path, url } = useRouteMatch();
	const params = useParams();

	const [state, dispatch] = useReducer(AuthReducer, initialState);
	const [loading, setLoading] = useState(true);
	const [tenantSelected, setTenantSelected] = useState(null);
	const [userData, setUserData] = useState({});
	const [customization, setCustomization] = useState({
		logo: "",
		placeholder: "https://avirato.com/wp-content/uploads/2020/02/placeholder.png",
		mainColor: "black",
		secondaryColor: "red",
		headerButtonColor: "white",
	});

	const customTheme = {
		global: {
			colors: {
				brand: customization.mainColor || "#017676",
				focus: "#",
				"accent-1": customization.accentColor || "white",
				"accent-2": "#235789",
				titles: "#235789",
			},
			edgeSize: {
				small: "10px",
			},
			elevation: {
				dark: {
					xsmall: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
					small: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
					medium: "0 10px 20px rgba(0,0,0,0.49), 0 10px 10px rgba(0,0,0,0.53)",
					large: "0 10px 20px 0px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0,0,0,0.22)",
					xlarge: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
				},
				light: {
					xsmall: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
					small: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
					medium: "0 10px 20px rgb(207, 219, 228)",
					large: "0 10px 20px 0px rgba(0, 0, 0, 0.1)",
					xlarge: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
				},
			},
		},
		text: {
			medium: {
				size: "16px",
			},
		},
		button: {
			// default:{
			// 	padding: {
			// 		vertical: "5px"
			// 	}
			// },
			border: {
				radius: "7px",
			},
			size: {
				medium: {
					pad: {
						vertical: "30px",
					},
				},
			},
			padding: {
				vertical: "5px",
			},
		},
	};

	useEffect(() => {
		findClient();
	}, []);

	const findClient = async () => {
		const tenantUrl = location.pathname.split("/")[1];
		firestore()
			.collection("tenants")
			.where("url", "==", tenantUrl)
			.get()
			.then((snapshot) => {
				if (snapshot.empty) {
					console.log("NONE");
					history.push(`${process.env.PUBLIC_URL}/find`);
				} else {
					const d = { ...snapshot.docs[0].data(), ref: snapshot.docs[0].ref, id: snapshot.docs[0].id };
					setTenantSelected(d);
					bootstrapAsync(d);
				}
			})
			.catch((err) => console.log(err));
	};

	const bootstrapAsync = async (tenant) => {
		auth().onAuthStateChanged(async (user) => {
			if (user) {
				await firestore()
					.collection("users")
					.doc(user.uid)
					.get()
					.then((doc) => {
						if (doc.exists) {
							const tenants = doc.data().tenants;
							if (tenants.length > 1) {
								setUserData({ ...doc.data(), id: doc.id, ref: doc.ref });
								authFunctions.changeTenant(tenant);
								setLoading(false);
							} else {
								setUserData({ ...doc.data(), id: doc.id, ref: doc.ref });
								authFunctions.changeTenant(tenant);
								setLoading(false);
							}
						} else {
							setLoading(false);
							alert("No se ha encontrado ningún usuario con estas credenciales");
						}
					})
					.catch((err) => {
						console.log(err);
						setLoading(false);
						alert("Ha ocurrido un error al intentar iniciar sesión, Intentalo mas tarde");
					});
			} else {
				// No user is signed in.
				setLoading(false);
				dispatch({ type: "RESTORE_TOKEN", token: null, resetPass: false });
				// alert("Ha ocurrido un error al intentar iniciar sesión, Intentalo mas tarde")
			}
		});
	};

	const authFunctions = useMemo(
		() => ({
			signIn: async (form) => {
				const email = form.identifier.trim().toLocaleLowerCase();
				const password = form.password.trim();

				auth()
					.signInWithEmailAndPassword(email, password)
					.then(async (re) => {})
					.catch((error) => {
						console.log(error);
						if ((error.code = "auth/invalid-email")) {
							alert("Usuario o contraseña incorrectas");
						}
					});
			},

			signOut: async (data) => {
				auth()
					.signOut()
					.then(async () => {
						dispatch({ type: "SIGN_OUT" });
						history.push(`${process.env.PUBLIC_URL}/${data}/login`);
						setUserData({});
						setCustomization({ mainColor: "black" });
					})
					.catch((error) => {
						alert(error.message);
					});
			},

			changeConfirmed: async (data) => {
				dispatch({ type: "CHANGE_CONFIRMED", confirmed: data });
			},

			changeTenant: async (tenant) => {
				tenant.ref.onSnapshot((doc) => {
					setCustomization({ ...doc.data().customization });
					setTenantSelected({ ...doc.data(), ref: doc.ref });
					dispatch({ type: "RESTORE_TOKEN", authed: true, selectTenant: false, resetPass: false });
					if (location.pathname) {
						const routeArr = location.pathname.split("/");
						routeArr.shift();
						routeArr.shift();
						const final = routeArr.join("/");
						if (userData.role === "Teacher") {
							final === "" ? history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/smartlearning/courses`) : history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/${final}`);
						} else {
							final === "" ? history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/content/library`) : history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/${final}`);
						}
					} else {
						console.log("GO");
						userData.role === "Teacher" ? history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/smartlearning/courses`) : history.push(`${process.env.PUBLIC_URL}/${doc.data().url}/content/library`);
					}
				});
			},
		}),
		[]
	);

	if (loading || state.isLoading) {
		return null;
	}
	return (
		<Grommet full theme={customTheme} background="#F6F4FD">
			<AuthContext.Provider value={{ customization, authFunctions, state, userData, tenant: tenantSelected }}>
				<TopMenu
					tenantUrl={tenantSelected?.url}
					changeTenantCallback={() => {
						dispatch({ type: "RESTORE_TOKEN", authed: true, selectTenant: true, resetPass: false });
					}}
				/>

				{state.selectTenant && (
					<Layer>
						<Box pad={{ bottom: "medium", horizontal: "medium" }} round="meidum" gap="small">
							<Heading level="3" textAlign="center">
								Selecciona tu espacio de Trabajo
							</Heading>
							<Box direction="row" gap="large" align="center" justify="center">
								{userData.tenants.map((el, i) => {
									return (
										<Box
											onClick={() => {
												authFunctions.changeTenant(el);
											}}
											key={i}
											gap="small">
											<Box width="80px" height="80px" round="40px" background={{ color: "gray" }} />
											<Text textAlign="center">{el.name}</Text>
										</Box>
									);
								})}
							</Box>
						</Box>
					</Layer>
				)}
				{/* <Route exact path="/download" component={Home} /> */}
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/content`} component={Content} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/smartlearning`} component={Courses} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/training`} component={Training} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={"/:tenant/challenges"} component={Challenges} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={"/:tenant/forums"} component={Forums} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/users`} component={Users} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={"/:tenant/notifications"} component={Notifications} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={"/:tenant/webinars"} component={Webinar} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/config`} component={Config} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={"/:tenant/evaluations"} component={Evaluations} />
				<PrivateRoute allowed={["Admin", "Teacher"]} path={`/:tenant/analytics`} component={Analytics} />
				<Route exact path={"/find"}></Route>
				<Route exact path={`/:tenant/login`} component={Login} />
				<Route exact path={"/:tenant"} component={Login} />
			</AuthContext.Provider>
		</Grommet>
	);
};

export default AppNavigator;
