import React, { useState, useEffect, useContext } from "react";
import { Box, DataTable, Heading, Image, CheckBox, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Add, Trash, UserAdd, Duplicate, View, User } from "grommet-icons";

import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { withRouter } from "react-router";

import { firestore, storage } from "firebase";
import UsersLibrary from "../../components/UsersLibrary";
import InviteModal from "../../components/InviteModal";
import AuthContext from "../../context/AuthContext";

const Courses = ({ location, history }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [data, setData] = useState([]);

	useEffect(() => {
		let unsubscribe;

		if (userData.role === "Admin") {
			unsubscribe = tenant.ref.collection("courses").orderBy("name", "asc").onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img
							? await storage()
									.ref(img)
									.getDownloadURL()
									.then((res) => res)
									.catch((err) => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg")
							: "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg";
						return { ...doc.data(), orgImage: doc.data().image, image: url, id: doc.id };
					})
				).then((res) => {
					setData(res);
					setFiltered(res);
				});
			});
		} else if (userData.role === "Teacher") {
			unsubscribe = tenant.ref
				.collection("courses")
				.where("teacherRef", "array-contains", userData.ref)
				.onSnapshot((querySnapshot) => {
					Promise.all(
						querySnapshot.docs.map(async (doc) => {
							const img = doc.data().image;
							const url = img
								? await storage()
										.ref(img)
										.getDownloadURL()
										.then((res) => res)
										.catch((err) => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg")
								: "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg";
							return { ...doc.data(), orgImage: doc.data().image, image: url, id: doc.id };
						})
					).then((res) => {
						setData(res);
						setFiltered(res);
					});
				});
		}

		return () => unsubscribe();
	}, []);

	console.log(data)

	const duplicateCourse = async (id) => {
		tenant.ref
			.collection("courses")
			.doc(id)
			.get()
			.then((doc) =>
				tenant.ref.collection("courses").add({
					...doc.data(),
					name: (doc.data()?.name ?? "") + " copy",
				})
			);
	};

	const deleteCourse = (courseID) => {
		tenant.ref.collection("courses").doc(courseID).delete();
	};

	const inviteUsers = (users, course, initDate, endDate) => {
		users.map((user) => {
			let advance = course.contentDetails.map((section) => {
				return {
					...section,
					status: "NOT INITIATED",
					contents: section.contents.map((content) => {
						return {
							...content,
							lastWatched: 0,
							status: "NOT INITIATED",
						};
					}),
				};
			});

			// let variables = {
			// 	user: firestore().collection("users").doc(user.id),
			// 	userEmail: user.email ?? "",
			// 	userName: user.name ?? "",
			// 	userSurname: user.surname ?? "",
			// 	course: tenant.ref.collection("courses").doc(course.id),
			// 	name: course.name,
			// 	description: course.description,
			// 	image: course.orgImage,
			// 	advance: advance,
			// 	coursePoints: course?.coursePoints,
			// 	state: "INITIATED",
			// 	visible: true,
			// 	initDate: initDate ? firestore.Timestamp.fromDate(moment(initDate || null).toDate()) : false,
			// 	endDate: endDate ? firestore.Timestamp.fromDate(moment(endDate || null).toDate()) : false,
			// };

			let variables = {
				courseRef: tenant.ref.collection("courses").doc(course.id),
				coursePoints: 0,
				name: course.name,
				description: course.description,
				image: course.orgImage,
				userEmail: user.email ?? "",
				userName: user.name ?? "",
				userSurname: user.surname ?? "",
				advance: advance,
				state: "INITIATED",
				advance: advance,
				user: firestore().collection("users").doc(user.id),
				visible: true,
				initDate: initDate ? firestore.Timestamp.fromDate(moment(initDate || null).toDate()) : false,
				endDate: endDate ? firestore.Timestamp.fromDate(moment(endDate || null).toDate()) : false,
			};

			firestore()
				.collection("users")
				.doc(user.id)
				.collection("courseavs")
				.add(variables)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		});
	};

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Cursos
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow">
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						{userData.role === "Admin" && <Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/newcourse`)} icon={<Add />} label="Nuevo Curso" />}
					</Box>
				</Box>

				<ReactTable
					data={filtered}
					defaultPageSize={10}
					// loading={loading}
					loadingText="Cargando Datos..."
					previousText="Cursos Anteriores"
					nextText="Cursos Siguientes"
					noDataText="Sin Contenidos"
					pageText="Página"
					ofText="de"
					rowsText="filas"
					columns={[
						{
							Header: "",
							headerStyle: { padding: 15 },
							id: "image",
							sortable: false,
							width: 100,
							style: { display: "flex", justifyContent: "center", padding: 15 },
							accessor: (datum) => (
								<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
									<Image fit="cover" src={datum.image} />
								</Box>
							),
						},
						{
							Header: "Título",
							headerStyle: { padding: 15 },
							id: "name",
							style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
							accessor: (datum) => datum.name,
						},
						{
							Header: "Secciones",
							id: "sections",
							headerStyle: { padding: 15 },
							sortable: false,
							width: 120,
							style: { display: "flex", justifyContent: "center", alignItems: "center" },
							accessor: (datum) => datum.contentDetails && datum.contentDetails.length,
						},
						{
							Header: "Contenidos",
							id: "contents",
							headerStyle: { padding: 15 },
							width: 150,
							resizable: false,
							style: { display: "flex", paddingLeft: 15, justifyContent: "center", paddingRight: 15, alignItems: "center" },
							accessor: (datum) => datum.contentDetails && datum.contentDetails.map((section) => section.contents.length).reduce((a, b) => a + b, 0),
						},
						{
							Header: "Ver Curso",
							headerStyle: { padding: 15 },
							id: "preview",
							width: 120,
							resizable: false,
							sortable: false,
							show: userData.role !== "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/editcourse/${datum.id}` }}>
									<Button fill color="gray" icon={<View />} hoverIndicator />
								</Link>
							),
						},
						// {
						// 	Header: "Ver Curso",
						// 	headerStyle: { padding: 15 },
						// 	id: "preview",
						// 	width: 120,
						// 	resizable: false,
						// 	sortable: false,
						// 	style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
						// 	accessor: (datum) => <Button onClick={() => window.open("http://localhost:19006/smartnew/curso/" + datum.id)} color="gray" icon={<View />} hoverIndicator />,
						// },
						{
							Header: "Convocados",
							headerStyle: { padding: 15 },
							id: "assigned",
							width: 140,
							resizable: false,
							sortable: false,
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => <Button onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/coursesassigned/${datum.id}`)} color="gray" icon={<User />} hoverIndicator />,
						},

						{
							Header: "Asignar",
							headerStyle: { padding: 15 },
							id: "assign",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => <Button onClick={() => setUsersModal({ status: true, data: datum })} color="gray" icon={<UserAdd />} hoverIndicator />,
						},
						{
							Header: "Duplicar",
							headerStyle: { padding: 15 },
							id: "duplicate",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							// accessor: datum => <Button onClick={() => setUsersModal({ status: true, data: datum })} color="gray" icon={<UserAdd />} hoverIndicator />,
							accessor: (datum) => <Button color="gray" icon={<Duplicate />} hoverIndicator onClick={() => duplicateCourse(datum.id)} />,
						},
						{
							Header: "Editar",
							headerStyle: { padding: 15 },
							id: "edit",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/editcourse/${datum.id}` }}>
									<Button fill color="gray" icon={<Edit />} hoverIndicator />
								</Link>
							),
						},
						{
							Header: "Eliminar",
							headerStyle: { padding: 15 },
							id: "delete",
							width: 100,
							resizable: false,
							sortable: false,
							show: userData.role === "Admin",
							style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
							accessor: (datum) => (
								<Button
									color="gray"
									hoverIndicator
									icon={<Trash />}
									onClick={() => {
										toogleDeleteModal(!deleteModal);
										setContentIDToDelete(datum.id);
									}}
								/>
							),
						},
					]}
				/>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este Curso?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									deleteCourse(contentIDToDelete);
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{usersModal.status && (
				<InviteModal
					closeCallback={() => setUsersModal({ status: false, data: {} })}
					submitCallback={(cb) => {
						inviteUsers(cb.users, usersModal.data, cb.initDate, cb.endDate);
						setUsersModal({ status: false, data: {} });
					}}
				/>
				// <UsersLibrary
				//   closeCallback={() => setUsersModal({ status: false, data: {} })}
				//   submitCallback={(cb) => {
				//     inviteUsers(cb, usersModal.data)
				//     setUsersModal({ status: false, data: {} })
				//   }}
				// />
			)}
		</Box>
	);
};

export default withRouter(Courses);
