import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, Lock, FormViewHide, View, Close, Upload, Send } from "grommet-icons";
import axios from "axios";
import { groupBy } from "lodash";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css"; // important: this line must be placed after react-table css import

import { auth, firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import MasiveUserModal from "../../components/MasiveUserModal";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const UserList = ({ location, history }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [subscription, setSubscription] = useState();
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [changePassModal, setChangePassModal] = useState({ status: false, userID: "" });
	const [changePassError, setChangePassError] = useState({ status: false, message: "" });
	const [formPass, setFormPass] = useState({ password: "", repeatpassword: "" });
	const [reveal, setReveal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [deleting, setDeleting] = useState(false);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const subs = firestore()
			.collection("users")
			// .where("tenants", "array-contains", userData.tenants[0])
			.where("tenants", "array-contains", tenant.ref)
			// .where("tenants", "array-contains", { name: "Solvia", ref: tenant.ref, url: "solvia" })
			.get().then(async (res) => {
				Promise.all(res.docs.map(async (user) => ({ ...user.data(), id: user.id, ref: user.ref }))).then((users) => {
					setData(users);
					setFiltered(users);
					setLoading(false);
					// console.log(users);
					console.log(users);

					// const usersToEdit = users.filter(el => el.usergroups[0]?.name)
					// console.log(users.map(el =>el.ref))
					// users.map( el => {
					// 	el.ref.update({
					// 		// image: `${tenant.ref.id}/users/${el.id}/profilePicture`,
					// 		// userImage: firestore.FieldValue.delete(),
					// 		tenants: [tenant.ref]
					// 	}).then(res => console.log(res, "UPDATEd"))
					// })
					// users.map( el => {
					// 	el.ref.collection("courseavs").get().then(snap => {
					// 		snap.docs.map(d => d.ref.delete().then(res => console.log(res, "deleted")))
					// 	})
					// })
					// usersToEdit.map( el => {
					// 	el.ref.update({
					// 		usergroups: el.usergroups.map(t => t.ref)
					// 	}).then(res => console.log(res)).catch(err => console.log(res))
					// })
				});
			});

		// return () => subs();
	}, []);

	// useEffect(() => {
	// 	firestore()
	// 		.collection("users")
	// 		// .where("tenants", "array-contains", userData.tenants[0])
	// 		.where("tenants", "array-contains", tenant.ref)
	// 		.where("customAttributes.group", "==", "2")
	// 		.get()
	// 		.then((res) => {
	// 			const p = res.docs.map((el) => ({ ...el.data(), ref: el.ref, id: el.id }));
	// 			console.log(p);
	// 			p.map((u) => {
	// 				u.ref.collection("courseavs").where("name", "==", "Grupo 2 - Desarrollo de Habilidades para la dirección de Personas copy").get().then(res => {
	// 					const l = res.docs.map(d => d.id)
	// 					console.log(l)
	// 				})
	// 				// changeUserPasswordMass(u.customAttributes.dni, u.id);
	// 			});
	// 			// p.map( e => e.ref)
	// 		});
	// 	// VFir4VhCiZoFpEYyq0bm
	// }, []);

	const deleteUser = (uid) => {
		setDeleting(true);
		axios
			.post("https://europe-west1-uno-work.cloudfunctions.net/deleteUser", {
				method: "POST",
				body: JSON.stringify({ uid }),
			})
			.then((res) => {
				console.log(res);
				toogleDeleteModal(!deleteModal);
				setDeleting(false);
			})
			.catch((res) => {
				console.log(res);
				toogleDeleteModal(!deleteModal);
				setDeleting(false);
			});
	};

	const updateUserPass = () => {};

	const changeUserPasswordMass = (password, userID) => {
		const variables = {
			userID: userID,
			password: password,
		};

		// console.log(variables)

		axios
			.post("https://europe-west1-uno-work.cloudfunctions.net/updateUser", {
				method: "POST",
				body: JSON.stringify({ ...variables }),
			})
			.then((response) => {
				console.log(response.data);
				if (response.data.code === null) {
					firestore().collection("users").doc(response.data).set({
						password: variables.password,
						repeatPassword: variables.password,
					});
					console.log("CHANGED");
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const changeUserPassword = () => {
		if (formPass.password !== formPass.repeatpassword) {
			setChangePassError({ status: true, message: "Las contraseñas no coinciden" });
		} else {
			const variables = {
				userID: changePassModal.userID,
				password: formPass.password,
			};
			updateUserPass({ variables })
				.then((data) => {
					console.log(data);
					setChangePassError({ status: false, message: "" });
					setChangePassModal({ status: false, userID: null });
				})
				.catch((err) => {
					console.log(err);
					setChangePassError({ ...changePassError, message: err.message });
				});
		}
	};

	const sendEmail = async () => {
		const p = data.filter((el) => el.role === "Teacher");
		// const p = data.filter((el) => el.email === "mmmojeda@gmail.com");
		// console.log(p);
		p.map(async (el) => {
			const e = `
			<!DOCTYPE html>
			<html>
				<head>
					<meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
					<style type="text/css">
						p {
							color: #000;
							font-size: 16px;
							font-family: Helvetica;
						}
						h1 {
							color: #000;
							font-size: 34px;
							font-family: Helvetica; 
						}
					</style>
				</head>
				<body>
					<img src="https://firebasestorage.googleapis.com/v0/b/uno-work.appspot.com/o/EiaXou4FV7fkgZsdBuJz%2Fadmin%2Fimages%2Fintrum-brand-landscapes-001.jpg?alt=media&token=5189965f-38a9-4652-b6c2-5ad4fc0ea866" height="200" width="100%" border="0" style="object-fit: cover; height:200px;"/>
	
					<h1 style="z-index:2">Hola ${el.name}</h1>
	
					<div style="margin-top: 30px">
						<p>Bienvenidos a la plataforma de gestión de Go For It!</p>
						<p>Desde el enlace podréis acceder a la herramienta para gestionar la actividad de los usuarios de vuestros itinerarios y sus foros asignados.</p>
						<p>Te indicamos que tus datos de acceso son los siguientes:</p>
						<br />
						<p>Usuario: ${el.email}</p>
						<p>Contraseña: intrum1</p>
						<br />
						<a
							href="https://admin.uno.work/intrum"
							style="
								font-family: Helvetica;
								font-size: 16px;
								font-weight: 500;
								padding: 10px;
								text-align: center;
								background-color: black;
								width: 180px;
								display: flex;
								align-items: center;
								text-decoration: none;
								justify-content: center;
								color: white;
								border-radius: 25px;
								display: block;
							"
							>Click para Acceder</a
						>
						<p>Muchas gracias</p>
					</div>
	
					<p><b>Un saludo!</b></p>
				</body>
			</html>
			`;

			// return axios
			// 	.post("http://localhost:5001/uno-work/europe-west1/sendMail", {
			// 		method: "POST",
			// 		body: JSON.stringify({ email: el.email, name: el.name, body: e }),
			// 	})
			// 	.then((res) => {
			// 		return res.data;
			// 	})
			// 	.catch((res) => {
			// 		return res.data;
			// 	});
		});
	};

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Usuarios ({data.filter((el) => el.role !== "Admin").length})
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "450px" }}>
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data?.filter((el) => el?.email?.toLowerCase()?.includes(ev.target.value.toLowerCase()) || el?.name.toLowerCase()?.includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => history.push("/" + tenant?.url + "/users/newuser")} icon={<Add />} label="Nuevo Usuario" />
						{/* <Button primary onClick={() => sendEmail()} icon={<Send />} label="Enviar Invitaciones" /> */}
					</Box>
				</Box>

				<Box>
					{data && (
						<ReactTableFixedColumns
							data={filtered}
							defaultPageSize={10}
							loading={loading}
							loadingText="Cargando..."
							previousText="Usuarios Anteriores"
							nextText="Usuarios Siguientes"
							noDataText="Sin Contenidos"
							pageText="Página"
							ofText="de"
							rowsText="filas"
							columns={[
								{
									Header: "Email",
									fixed: "left",
									headerStyle: { padding: 15 },
									id: "email",
									width: 250,
									style: { fontSize: 14, display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => datum.email,
								},
								{
									Header: "Nombre",
									id: "name",
									headerStyle: { padding: 15 },
									style: { fontSize: 14, display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => datum.name + " " + (datum.surname ?? ""),
								},

								// {
								// 	Header: "Store",
								// 	id: "store",
								// 	headerStyle: { padding: 15 },
								// 	width: 250,
								// 	style: { fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center" },
								// 	accessor: (datum) => datum.store,
								// },
								// {
								// 	Header: "Ref",
								// 	id: "ref",
								// 	headerStyle: { padding: 15 },
								// 	width: 130,
								// 	style: { fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center" },
								// 	accessor: (datum) => datum.apiRef,
								// },
								{
									Header: "Estado",
									id: "status",
									headerStyle: { padding: 15 },
									width: 130,
									style: { fontSize: 14, display: "flex", justifyContent: "center", alignItems: "center" },
									accessor: (datum) =>
										datum.blocked === true ? (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} label="Inactivo" primary color="status-error" size="small" />
										) : (
											<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} primary label="Activo" color="status-ok" size="small" />
										),
								},
								{
									Header: "",
									headerStyle: { padding: 15 },
									id: "password",
									width: 60,
									resizable: false,
									sortable: false,
									fixed: "right",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => <Button onClick={() => setChangePassModal({ status: true, userID: datum.id })} color="gray" icon={<Lock />} hoverIndicator />,
								},
								{
									Header: "",
									headerStyle: { padding: 15 },
									id: "edit",
									width: 60,
									resizable: false,
									sortable: false,
									fixed: "right",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/users/edituser/${datum.id}` }}>
											<Button fill color="gray" icon={<Edit />} hoverIndicator />
										</Link>
									),
								},
								{
									Header: "",
									headerStyle: { padding: 15 },
									id: "delete",
									width: 60,
									resizable: false,
									sortable: false,
									fixed: "right",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button
											color="gray"
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContentIDToDelete(datum.id);
											}}
										/>
									),
								},
							]}
						/>
					)}
				</Box>
			</Box>
			{userData?.email?.includes("blacknosaur") && (
				<Box align="start" margin={{ top: "small" }}>
					<Button primary onClick={() => setVisible(!visible)} icon={<Upload />} width="20" label="Carga Masiva" />
				</Box>
			)}

			<MasiveUserModal visible={visible} toggle={() => setVisible(!visible)} />

			{changePassModal.status && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => setChangePassModal({ status: false, userID: null })} onEsc={() => setChangePassModal({ status: false, userID: null })}>
					<Box pad="medium" gap="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								Cambiar contraseña
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez cambiada la contraseña el usuario no podrá acceder con su contraseña anterior.</Text>
						</Box>
						<Box gap="small">
							<Box direction="row" border round="7px">
								<TextInput plain type={reveal ? "text" : "password"} value={formPass.password} onChange={(ev) => setFormPass({ ...formPass, password: ev.target.value })} placeholder="Introduzca la contraseña..." />
								<Button icon={reveal ? <View size="medium" /> : <FormViewHide size="medium" />} onClick={() => setReveal(!reveal)} />
							</Box>

							<Box direction="row" border round="7px">
								<TextInput plain type={reveal ? "text" : "password"} value={formPass.repeatpassword} onChange={(ev) => setFormPass({ ...formPass, repeatpassword: ev.target.value })} placeholder="Repita la contraseña..." />
								<Button icon={reveal ? <View size="medium" /> : <FormViewHide size="medium" />} onClick={() => setReveal(!reveal)} />
							</Box>
						</Box>

						{changePassError.status && (
							<Box align="center" pad={{ horizontal: "medium" }} direction="row" justify="between" round="small" animation={changePassError.status ? "fadeIn" : "fadeOut"} background="neutral-4">
								<Text>{changePassError.message}</Text>
								<Button icon={<Close color="white" />} onClick={() => setChangePassError({ status: false, message: "" })} />
							</Box>
						)}

						<Box direction="row" justify="between" width="100%">
							<Button label="Cancelar" onClick={() => setChangePassModal({ status: false, userID: null })} />
							<Button type="submit" label="Cambiar" color="brand" disabled={formPass.password === ""} primary onClick={() => changeUserPassword()} />
						</Box>
					</Box>
				</Layer>
			)}

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este foro?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el foro no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancelar" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								disabled={deleting}
								label={deleting ? "Eliminando" : "Eliminar"}
								color="status-critical"
								primary
								onClick={() => {
									deleteUser(contentIDToDelete);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(UserList);
