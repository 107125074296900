
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Box, ResponsiveContext } from 'grommet';

import Itineraries from './Itineraries'
import ItineraryForm from './ItineraryForm'
import MissionForm from './MissionForm'
import Missions from './Missions'

import SideMenu from '../../components/SideMenu'
import ItinerariesAssigned from './ItinerariesAssigned';
import { useRouteMatch } from 'react-router-dom';

const RouterTraining = () => {

    const size = useContext(ResponsiveContext)
    const {url} = useRouteMatch()
    const routes = [
        {
            route: `${url}/newitinerary`,
            icon: "Add",
            title: "Nuevo Itinerario",
            allowed: ["Admin"]
        }, {
            route: `${url}/itineraries`,
            icon: "Book",
            title: "Itinerarios",
            allowed: ["Admin"]
        }, {
            route: `${url}/newmission`,
            icon: "AddCircle",
            title: "Nueva Misión",
            allowed: ["Admin"]
        }, {
            route: `${url}/missions`,
            icon: "Deploy",
            title: "Misiones",
            allowed: ["Admin"]
        }
    ]

    return (
        <Box direction="row" gap="medium" justify="center" pad="medium">

            {size !== "small" &&
                <Box width="100px">
                    <SideMenu routes={routes} sidebarStatus={true} />
                </Box>
            }
            <Box flex width={{ max: "xlarge" }}>
                <Route path={`${url}/itineraries`} component={Itineraries} />
                <Route path={`${url}/newitinerary`} component={ItineraryForm} />
                <Route path={`${url}/itineraryresults/:id`} component={ItinerariesAssigned} />
                <Route path={`${url}/edititinerary/:id`} component={ItineraryForm} />
                <Route path={`${url}/missions`} component={Missions} />
                <Route path={`${url}/newmission/`} component={MissionForm} />
                <Route path={`${url}/editmission/:id`} component={MissionForm} />
            </Box>
        </Box>
    );
}

export default RouterTraining;