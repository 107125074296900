import React, { useContext, useEffect, useState } from "react";
import { Box, Text, Select, DateInput, Image, Heading } from "grommet";
import { firestore, storage } from "firebase";
import moment from "moment";
import { uniq, orderBy, uniqBy } from "lodash";
import AuthContext from "../../context/AuthContext";

const RankingItemUser = ({ item, level, score }) => (
	<Box direction="row" justify="between">
		<Box direction="row" align="center" gap="small" pad={{ horizontal: "small" }} flex>
			{/* <Text weight="bold" size="xlarge">{level}</Text> */}
			<Box
				background="light-3"
				border="all"
				height="60px"
				width="60px"
				round="full"
				overflow="hidden"
				elevation="small"
				border={{
					color: level === 1 ? "#FEE101" : level === 2 ? "#A7A7AD" : level === 3 ? "#A77044" : false,
					size: level > 3 ? false : "medium",
				}}>
				<Image fit="cover" src={item?.image} />
			</Box>
			<Box flex>
				<Text size="14px">
					{item?.name} {item?.surname}
				</Text>
			</Box>
		</Box>
		<Box direction="row" pad={{ horizontal: "small" }} align="center" justify="center" width="50px">
			<Text color="green" weight="bold" size="xxlarge">
				{score}
			</Text>
		</Box>
	</Box>
);

const RankingItemContent = ({ item, level, score }) => (
	<Box direction="row" justify="between" flex="grow">
		<Box direction="row" align="center" gap="small" flex pad={{ horizontal: "small" }}>
			{/* <Text weight="bold" size="xlarge">{level}</Text> */}
			<Box
				background="light-3"
				border="all"
				flex="grow"
				height="60px"
				width={{ max: "60px" }}
				round="full"
				overflow="hidden"
				border={{
					color: level === 1 ? "#FEE101" : level === 2 ? "#A7A7AD" : level === 3 ? "#A77044" : false,
					size: level > 3 ? false : "2px",
				}}>
				<Image fit="cover" src={item?.image} />
			</Box>
			<Box>
				<Text size="14px">{item?.title}</Text>
			</Box>
		</Box>
		<Box direction="row" pad={{ horizontal: "small" }} align="center" justify="center" width="50px">
			<Text color="green" weight="bold" size="xxlarge">
				{score}
			</Text>
		</Box>
	</Box>
);

const Analytics = ({}) => {
	const { tenant, userData } = useContext(AuthContext);

	const [userGrops, setUserGroups] = useState(null);
	const [selectedUserGroups, setSelectedUserGroup] = useState(null);
	const [categories, setCategories] = useState(null);
	const [selectedCategories, setSelectedCategories] = useState(null);
	const [contents, setContents] = useState(null);
	const [analytics, setAnalytics] = useState(null);
	const [startDate, setStartDate] = useState(moment().subtract(1, "week").toDate());
	const [endDate, setEndDate] = useState(moment().toDate());

	const beginQuery = () =>
		tenant.ref
			.collection("analytics")
			.where("createdAt", ">=", firestore.Timestamp.fromDate(startDate))
			.where("createdAt", "<=", firestore.Timestamp.fromDate(endDate))
			.onSnapshot(async (data) =>
				Promise.all(
					data.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : await storage().ref("placeholderUser.png").getDownloadURL();
						return { ...doc.data(), id: doc.id, image: url };
					})
				).then((res) => setAnalytics(res))
			);

	useEffect(() => {
		tenant.ref
			.collection("usergroups")
			.get()
			.then((data) => Promise.all(data.docs.map(async (doc) => ({ ...doc.data(), id: doc?.id, ref: doc?.ref }))).then((res) => setUserGroups(res)));
		tenant.ref
			.collection("categories")
			.get()
			.then((data) => Promise.all(data.docs.map(async (doc) => ({ ...doc.data(), id: doc?.id, ref: doc?.ref }))).then((res) => setCategories(res)));
		tenant.ref
			.collection("contents")
			.get()
			.then((data) =>
				Promise.all(
					data.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : "";
						return { ...doc.data(), image: url, id: doc.id };
					})
				).then((res) => {
					setContents(res);
				})
			);
		beginQuery();
	}, []);

	useEffect(() => {
		if (selectedUserGroups && selectedUserGroups.length > 0) {
			tenant.ref
				.collection("analytics")
				.where("createdAt", ">=", firestore.Timestamp.fromDate(startDate))
				.where("createdAt", "<=", firestore.Timestamp.fromDate(endDate))
				.where(
					"usergroups",
					"array-contains-any",
					selectedUserGroups?.map((x) => x.ref)
				)
				.onSnapshot(async (data) =>
					Promise.all(
						data.docs.map(async (doc) => {
							const img = doc.data().image;
							const url = img ? await storage().ref(img).getDownloadURL() : await storage().ref("placeholderUser.png").getDownloadURL();
							return { ...doc.data(), id: doc.id, image: url };
						})
					).then((res) => setAnalytics(res))
				);
		} else {
			beginQuery();
		}
	}, [selectedUserGroups]);

	useEffect(() => {
		beginQuery();
	}, [startDate, endDate]);

	const listLogin = analytics?.filter((analytic) => analytic.type === "LOGIN");
	const listViews = analytics?.filter((analytic) => analytic.type === "POST");
	const listLikes = analytics?.filter((analytic) => analytic.type === "LIKE");
	const listComments = analytics?.filter((analytic) => analytic.type === "COMMET");

	const rankingLoginUser = orderBy(
		uniqBy(listLogin, "userid")?.map((analytic) => ({
			id: analytic.id,
			user: {
				name: analytic.username,
				surname: analytic.usersurname,
				email: analytic.useremail,
				image: analytic.image,
			},
			numberLogin: listLogin.filter((x) => x.userid === analytic.userid)?.length,
		})),
		["numberLogin"],
		["desc"]
	);

	const rankingViews = orderBy(
		uniq(listViews?.map((analytic) => analytic.pageId))?.map((analytic) => ({ id: analytic, content: contents?.find((x) => x.id === analytic), numberViews: listViews.filter((x) => x.pageId === analytic)?.length })),
		["numberViews"],
		["desc"]
	).filter((x) => (selectedCategories?.length > 0 ? x?.content?.categories?.some((item) => selectedCategories?.map((y) => y.id).includes(item)) : true));
	const rankingLikes = orderBy(
		uniq(listLikes?.map((analytic) => analytic.pageId))?.map((analytic) => ({ id: analytic, content: contents?.find((x) => x.id === analytic), numberLikes: listLikes.filter((x) => x.pageId === analytic)?.length })),
		["numberLikes"],
		["desc"]
	).filter((x) => (selectedCategories?.length > 0 ? x?.content?.categories?.some((item) => selectedCategories?.map((y) => y.id).includes(item)) : true));
	const rankingComments = orderBy(
		uniq(listComments?.map((analytic) => analytic.pageId))?.map((analytic) => ({ id: analytic, content: contents?.find((x) => x.id === analytic), numberComments: listComments.filter((x) => x.pageId === analytic)?.length })),
		["numberComments"],
		["desc"]
	).filter((x) => (selectedCategories?.length > 0 ? x?.content?.categories?.some((item) => selectedCategories?.map((y) => y.id).includes(item)) : true));

	return (
		<Box align="center">
			<Box direction="row" gap="small" pad="small">
				{categories && <Select labelKey="name" valueKey="id" multiple placeholder="Seleccione Categorias" options={categories} onChange={({ value: nextValue }) => setSelectedCategories(nextValue)} value={selectedCategories ?? []} />}
				{userGrops && <Select labelKey="name" valueKey="id" multiple placeholder="Seleccione Grupo" options={userGrops} onChange={({ value: nextValue }) => setSelectedUserGroup(nextValue)} value={selectedUserGroups ?? []} />}
				<Box direction="row">
					<DateInput
						format="DD/MM/YYYY hasta DD/MM/YYYY"
						value={[startDate, endDate]}
						onChange={({ value }) => {
							const start = moment(value[0]);
							const end = moment(value[1]);
							if (end.diff(start, "month", true) <= 1) {
								setStartDate(start.toDate());
								setEndDate(end.toDate());
							} else {
								alert("Maximo 1 mes");
							}
						}}
					/>
				</Box>
			</Box>
			<Box pad="small" gap="medium" direction="row" justify="center">
				<Box round="small" elevation="small" flex={true}>
					<Box background={tenant?.customization?.mainColor} round={{ size: "small", corner: "top" }} pad="small">
						<Text weight="bold">Ranking de inicio de sesión</Text>
					</Box>
					<Box gap="medium" pad="small">
						{rankingLoginUser?.slice(0, 5)?.map((item, i) => (
							<RankingItemUser key={`rankingLoginUser-${i}`} item={item?.user} level={i + 1} score={item?.numberLogin} />
						))}
					</Box>
				</Box>
				<Box round="small" elevation="small" flex={true}>
					<Box background={tenant?.customization?.mainColor} round={{ size: "small", corner: "top" }} pad="small">
						<Text weight="bold">Ranking de visitas</Text>
					</Box>
					<Box gap="medium" pad="small">
						{rankingViews?.slice(0, 5)?.map((item, i) => (
							<RankingItemContent key={`rankingViews-${i}`} level={i + 1} item={item?.content} score={item?.numberViews} />
						))}
					</Box>
				</Box>
				<Box round="small" elevation="small" flex={true}>
					<Box background={tenant?.customization?.mainColor} round={{ size: "small", corner: "top" }} pad="small">
						<Text weight="bold">Ranking de likes</Text>
					</Box>
					<Box gap="medium" pad="small">
						{rankingLikes?.slice(0, 5)?.map((item, i) => (
							<RankingItemContent key={`rankingLikes-${i}`} level={i + 1} item={item?.content} score={item?.numberLikes} />
						))}
					</Box>
				</Box>
				<Box round="small" elevation="small" flex={true}>
					<Box background={tenant?.customization?.mainColor} round={{ size: "small", corner: "top" }} pad="small">
						<Text weight="bold">Ranking de comentarios</Text>
					</Box>
					<Box gap="medium" pad="small">
						{rankingComments?.slice(0, 5)?.map((item, i) => (
							<RankingItemContent key={`rankingComments-${i}`} level={i + 1} item={item?.content} score={item?.numberComments} />
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Analytics;
