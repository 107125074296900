import React, { useState, useEffect } from "react";
import { Box, Heading, Text, TextInput, Button, CheckBox, Collapsible, TextArea } from "grommet";
import { Trash, Contract, Expand, Add } from "grommet-icons";
import TextareaAutosize from "react-textarea-autosize";

const QuestionGenerator = ({
	value,
	onlyOne,
	withFeedback = false,
	allowCollapsible,
	allowDeleteQuestion,
	deleteQuestionCallback,
	answersCallback,
	question,
	questionCallback,
	withFeedbackCallback,
	points = false,
	openAnswer = false,
	withOpenAnswerCallback,
	openAnswerFeedback = "",
	openAnswerFeedbackCallback,
	multipleAnswer = false,
	multipleAnswerCallback,
}) => {
	const answerInit = { text: "", correctAnswer: false, ...(points ? { points: 0 } : {}), ...(withFeedback ? { feedback: "" } : {}) };
	const [answers, setAnswers] = useState([{ ...answerInit }, { ...answerInit }, { ...answerInit }]);
	const [show, setShow] = useState(true);

	useEffect(() => {
		value.answers && Array.isArray(value.answers) && setAnswers(value.answers);
	}, [value]);

	const addAnswer = () => {
		setAnswers([...answers, { text: "", correctAnswer: false }]);
	};

	const editAnswer = (index, field, value) => {
		const newarr = answers;
		if (onlyOne && field === "correctAnswer" && value) {
			console.log("Test");
			const double = newarr.map((aw) => {
				return { ...aw, correctAnswer: false };
			});
			double[index][field] = value;
			setAnswers([...double]);
			answersCallback([...double]);
		} else {
			newarr[index][field] = value;
			setAnswers([...newarr]);
			answersCallback([...newarr]);
		}
	};

	const deleteAnswer = (index) => {
		console.log(index);
		setAnswers([...answers.filter((a, i) => i !== index)]);
		answersCallback([...answers.filter((a, i) => i !== index)]);
	};

	return (
		<Box round="small" flex="grow">
			<Box round="7px" direction="row" justify="between" align="center">
				{allowDeleteQuestion && <Button hoverIndicator onClick={() => deleteQuestionCallback()} icon={<Trash />} />}
				<Box direction="row" flex="grow">
					<TextareaAutosize
						maxRows={6}
						rows={1}
						resize="none"
						style={{ background: "none", fontSize: 16, fontWeight: 600, width: "100%", borderRadius: 7, borderColor: "gainsboro", padding: 11 }}
						placeholder="Escribe aquí la pregunta..."
						value={question}
						onChange={(event) => questionCallback(event)}
					/>
				</Box>
				{allowCollapsible && <Button hoverIndicator onClick={() => setShow(!show)} icon={show ? <Contract /> : <Expand />} />}
			</Box>
			<Collapsible open={show}>
				{!openAnswer && (
					<Box direction="row" margin={{ top: "small" }}>
						<Box round={{ corner: "left", size: "7px" }} width="5px" background="accent-1" />
						<Box flex="grow" round={{ corner: "right", size: "7px" }} background="light-1">
							<Box height="48px" direction="row" direction="row" justify="between" align="center">
								<Box pad="small" flex="grow">
									<Heading color="gray" level="4" margin="none">
										Respuestas ({answers.length})
									</Heading>
								</Box>
								<Button hoverIndicator icon={<Add />} onClick={addAnswer} />
							</Box>
							<Box gap="small" pad="small" round={{ corner: "bottom", size: "7px" }}>
								{answers.map((answer, i) => (
									<Box gap="small" key={i}>
										<Box flex="grow" background="white" direction="row" align="center" border={{ side: "all", color: answer.correctAnswer ? "status-ok" : "gainsboro" }} round={"7px"}>
											<Box flex="shrink" pad="small">
												<CheckBox size="large" reverse checked={answer.correctAnswer ? true : false} onChange={(ev) => editAnswer(i, "correctAnswer", ev.target.checked)} />
											</Box>

											<Box flex="grow">
												<TextInput plain size="small" onChange={(ev) => editAnswer(i, "text", ev.target.value)} placeholder={`Escribe la respuesta ${i + 1}...`} value={answer.text} />
											</Box>

											{points && (
												<Box width="150px" border={{ side: "vertical" }}>
													<TextInput plain type="number" size="small" onChange={(ev) => editAnswer(i, "points", parseInt(ev.target.value))} placeholder={`Puntos`} value={answer.points} />
												</Box>
											)}
											<Button disabled={answers.length === 1} hoverIndicator onClick={() => deleteAnswer(i)} icon={<Trash />} />
										</Box>
										{withFeedback && (
											<Box border={{ side: "left" }}>
												<TextInput plain size="small" onChange={(ev) => editAnswer(i, "feedback", ev.target.value)} placeholder={`Escribe feedback ${i + 1}...`} value={answer.feedback} />
											</Box>
										)}
									</Box>
								))}
							</Box>
						</Box>
					</Box>
				)}
				{openAnswer && withFeedback && (
					<Box direction="row" margin={{ top: "small" }}>
						<Box round={{ corner: "left", size: "7px" }} width="5px" background="accent-1" />
						<Box flex="grow" round={{ corner: "right", size: "7px" }} background="light-1">
							<TextInput plain size="small" onChange={(ev) => openAnswerFeedbackCallback(ev.target.value)} placeholder={`Escribe feedback...`} value={openAnswerFeedback} />
						</Box>
					</Box>
				)}
				<Box pad="small" gap="small" margin={{ top: "small" }}>
					<CheckBox toggle={true} checked={withFeedback} label="Dar feedback al usuario al responder" onChange={(event) => withFeedbackCallback(event)} />
					{!multipleAnswer && <CheckBox toggle={true} checked={openAnswer} label="Respuesta Abierta" onChange={(event) => withOpenAnswerCallback(event)} />}
					{!openAnswer && <CheckBox toggle={true} checked={multipleAnswer} label="Respuesta Múltiple" onChange={(event) => multipleAnswerCallback(event)} />}
				</Box>
			</Collapsible>
		</Box>
	);
};

export default QuestionGenerator;
