import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput, TextArea } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, Flag } from "grommet-icons";

import { useParams, withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import ModerateLibrary from "../../components/ModerateModal";

const Topics = ({ location, history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState(null);
	const [contentIDToUpdate, setContentIDToUpdate] = useState(null);
	const [createTopicModal, setCreateTopicModal] = useState(false);
	const [forum, setForum] = useState({});
	const [topicData, setTopicData] = useState({
		title: "",
		body: "",
	});
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [moderateModal, toogleModerateModal] = useState(false);

	const { userData, tenant } = useContext(AuthContext);

	const { id } = useParams();

	useEffect(() => {
		const unsubscribe = tenant.ref
			.collection("forums")
			.doc(id)
			.collection("topics")
			.onSnapshot((querySnapshot) => {
				const d = querySnapshot.docs.map((res) => ({ ...res.data(), id: res.id, ref: res.ref }));
				setLoading(false);
				setData(d);
				setFiltered(d);
			});
		getForum();
		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		data && setFiltered(data.forums);
	}, [data]);

	const getForum = () => {
		tenant.ref
			.collection("forums")
			.doc(id)
			.get()
			.then((doc) => {
				setForum(doc.data());
			})
			.catch((err) => console.log(err));
	};

	const createTopic = async () => {

		const variables = {
			...topicData,
			forum: tenant.ref.collection("forums").doc(id),
			lastActive: firestore.FieldValue.serverTimestamp(),
			responses: 1,
			tenant: tenant.ref,
			usergroups: forum.usergroups,
			user: {
				id: userData.id,
				image: `${tenant.id}/users/${userData.id}/profilePicture`,
				name: userData.name,
			},
		};

		tenant.ref
			.collection("forums")
			.doc(id)
			.collection("topics")
			.add(variables)
			.then((res) => {
				console.log(res);
				resetTopicModal();
			})
			.catch((err) => {
				console.log(err);
				alert("Ha ocurrido un error creando el hilo");
			});

		tenant.ref
			.collection("forums")
			.doc(id)
			.update({
				topics: firestore.FieldValue.increment(1),
			})
			.then((res) => console.log(res))
			.catch((err) => {
				console.log(err);
				alert("Ha ocurrido un error actualizando el foro");
			});
	};

	const updateTopic = async (ref) => {

		ref.update({
			...topicData,
			usergroups: forum.usergroups,
			lastActive: firestore.FieldValue.serverTimestamp(),
		})
			.then((res) => {
				console.log(res);
				resetTopicModal();
			})
			.catch((err) => {
				alert("Ha ocurrido un error creando el hilo");
			});
	};

	const deleteTopic = (ref) => {
		contentIDToDelete
			.delete()
			.then((res) => console.log(res))
			.catch((err) => {
				alert("Ha ocurrido un error eliminando el hilo");
			});
		tenant.ref
			.collection("forums")
			.doc(id)
			.update({
				topics: firestore.FieldValue.increment(-1),
			})
			.then((res) => console.log(res))
			.catch((err) => {
				alert("Ha ocurrido un error eliminando el hilo");
			});
	};

	const resetTopicModal = () => {
		setCreateTopicModal(false);
		setContentIDToUpdate(null);
		setTopicData({ title: "", body: "" });
	};

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Hilos
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "450px" }}>
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.forums.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => setCreateTopicModal(true)} icon={<Add />} label="Nuevo Hilo" />
					</Box>
				</Box>

				<Box>
					{data && (
						<ReactTable
							data={filtered}
							defaultPageSize={10}
							loading={loading}
							loadingText="Cargando Hilos..."
							previousText="Hilos Anteriores"
							nextText="Hilos Siguientes"
							noDataText="Sin Hilos"
							pageText="Página"
							ofText="de"
							rowsText="filas"
							columns={[
								{
									Header: "",
									headerStyle: { padding: 15 },
									id: "image",
									sortable: false,
									width: 100,
									style: { display: "flex", justifyContent: "center", padding: 15 },
									accessor: (datum) => (
										<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
											<Image fit="cover" src={datum.image && datum.image} />
										</Box>
									),
								},
								{
									Header: "Título",
									headerStyle: { padding: 15 },
									id: "title",
									style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.title}</Text>
										</Box>
									),
								},
								{
									Header: "Respuestas",
									id: "respuestas",
									headerStyle: { padding: 15 },
									width: 160,
									style: { display: "flex", justifyContent: "center", alignItems: "center" },
									accessor: (datum) => datum.responses,
								},
								{
									Header: "Moderar",
									headerStyle: { padding: 15 },
									id: "moderate",
									width: 120,
									resizable: false,
									sortable: false,
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button onClick={() => toogleModerateModal(datum.id)} fill color="gray" icon={<Flag />} hoverIndicator />
										// <Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/forums/edittopic/${datum.id}` }}>
										// </Link>
									),
								},
								{
									Header: "Editar",
									headerStyle: { padding: 15 },
									id: "edit",
									width: 100,
									resizable: false,
									sortable: false,
									show: userData.role === "Admin",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button
											fill
											color="gray"
											onClick={() => {
												setContentIDToUpdate(datum.ref);
												setTopicData({
													title: datum.title,
													body: datum.body,
												});
											}}
											icon={<Edit />}
											hoverIndicator
										/>
									),
								},
								{
									Header: "Eliminar",
									headerStyle: { padding: 15 },
									id: "delete",
									width: 100,
									resizable: false,
									sortable: false,
									show: userData.role === "Admin",
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => (
										<Button
											color="gray"
											hoverIndicator
											icon={<Trash />}
											onClick={() => {
												toogleDeleteModal(!deleteModal);
												setContentIDToDelete(datum.ref);
												console.log(datum.id);
											}}
										/>
									),
								},
							]}
						/>
					)}
				</Box>
			</Box>

			{moderateModal && <ModerateLibrary closeCallback={() => toogleModerateModal(false)} forumId={id} topicId={moderateModal} />}

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este foro?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el foro no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" width="100%" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									deleteTopic();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{(createTopicModal || contentIDToUpdate) && (
				<Layer full="vertical" position="left" onEsc={() => resetTopicModal()} onClickOutside={() => resetTopicModal()}>
					<Box width={{ min: "large" }}>
						<Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
							<Box direction="row" gap="small" pad="small">
								<Button label="Cancelar" onClick={() => resetTopicModal()} />
								<Button primary label={contentIDToUpdate ? "Actualizar" : "Crear"} onClick={() => (contentIDToUpdate ? updateTopic(contentIDToUpdate) : createTopic())} />
							</Box>
						</Box>
						<Box direction="row" gap="medium" pad="medium">
							<Box gap="medium" flex>
								<Box gap="5px">
									<Text size="xsmall">Nombre del Hilo</Text>
									<TextInput placeholder="Escribe el nombre del hilo..." onChange={(e) => setTopicData({ ...topicData, title: e.target.value })} value={topicData.title} />
								</Box>
								<Box gap="5px">
									<Text size="xsmall">Contenido</Text>
									<TextArea style={{ background: "white", minHeight: 160 }} resize="vertical" placeholder="Contenido del hilo..." hoverIndicator value={topicData.body} size="small" onChange={(ev) => setTopicData({ ...topicData, body: ev.target.value })} />
								</Box>
							</Box>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Topics);
