import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, TextInput, Button, CheckBox } from 'grommet';
import { Trash, Add } from 'grommet-icons'

const TimerGenerator = ({ value, stepsCallback, title, maxLength }) => {

    const [steps, setSteps] = useState([{ text: "", timeOffset: 0 }])

    useEffect(() => {
        Array.isArray(value.steps) && setSteps(value.steps)
    }, [value])

    useEffect(() => {
        stepsCallback(steps)
    }, [steps])

    const addStep = () => {
        setSteps([...steps, { text: "", timeOffset: steps[steps.length + 1] + 60 }])
    }

    const editStep = (index, field, value) => {
        const newarr = steps
        newarr[index][field] = value
        setSteps([...newarr])
    }

    const deleteStep = (index) => {
        setSteps([...steps.filter((a, i) => i !== index)])
    }

    return (
        <Box direction="row">
            <Box background="accent-1" width="5px" round={{ corner: "left", size: "7px" }} />
            <Box background="light-1" flex="grow" round={{ corner: "right", size: "7px" }}>
                <Box direction="row" height="48px" align="center" justify="between">
                    <Box pad="small">
                        <Heading color="gray" level="4" margin="none">{title} ({steps.length})</Heading>
                    </Box>
                    <Button hoverIndicator onClick={addStep} icon={<Add />} />
                </Box>
                <Box gap="small" pad="small">
                    {steps.map((step, i) =>
                        <Box key={i} direction="row">
                            <Box direction="row" align="center" flex="grow" round="7px" border="all">
                                <Box width="140px" border={{ side: "right" }}>
                                    <TextInput plain size="small" type="number" onChange={(ev) => editStep(i, "timeOffset", parseInt(ev.target.value))} placeholder={`Segundo Inicial`} value={step.timeOffset} />
                                </Box>
                                <Box flex>
                                    <TextInput plain size="small" onChange={(ev) => editStep(i, "text", ev.target.value)} placeholder={`Descripción...`} value={step.text} />
                                </Box>
                                <Button hoverIndicator onClick={() => deleteStep(i)} icon={<Trash />} />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default TimerGenerator;