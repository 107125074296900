import React, { useState, useEffect, useContext } from "react";
import { Box, DataTable, Heading, Image, CheckBox, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, View, Trash, UserAdd, FormViewHide, Duplicate, Flag, Download } from "grommet-icons";

import moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { useHistory, useParams, withRouter } from "react-router";

import InviteModal from "../../components/InviteModal";
import AuthContext from "../../context/AuthContext";
import AdvanceModal from "../../components/AdvanceModal";

import { CSVLink, CSVDownload } from "react-csv";

const CoursesAssigned = () => {
	const { userData, tenant } = useContext(AuthContext);
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [viewCourse, setViewCourse] = useState(false);
	const [data, setData] = useState([]);
	const [course, setCourse] = useState({});
	const history = useHistory();
	const { id } = useParams();
	const [ex, setExport] = useState([]);

	// useEffect(() => {
	// 	console.log(data);
	// }, [data]);

	useEffect(() => {
		tenant.ref
			.collection("courses")
			.doc(id)
			.get()
			.then((doc) => {
				setCourse({ ...doc.data(), ref: doc.ref });
			});
	}, []);

	useEffect(() => {
		tenant.ref
			.collection("courses")
			.doc(id)
			.collection("courseavs")
			.onSnapshot(async (querySnapshot) => {
				const res = await Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const original = await doc
							.data()
							.courseav.get()
							.then((res) => ({ course: res.data() }));
						return { ...doc.data(), id: doc.id, ref: doc.ref, ...original };
					})
				).then((res) => res);
				// await console.log(res);
				await setData(res);
				setFiltered(res);
				setExport(getDataToDownload(res));
			});
	}, []);

	const hideCourse = (courseID) => {
		const originalItem = data.find((el) => el.id === courseID);
		originalItem.courseav
			.update({
				visible: !originalItem.visible,
			})
			.then((res) => {
				originalItem.ref
					.update({
						visible: !originalItem.visible,
					})
					.then((res) => { })
					.catch((err) => {
						alert(err);
					});
			})
			.catch((err) => {
				alert(err);
			});
	};

	const deleteCourse = (courseID) => {
		const originalItem = data.find((el) => el.id === courseID);
		originalItem.courseav
			.delete()
			.then((res) => {
				originalItem.ref
					.delete()
					.then((res) => { })
					.catch((err) => {
						alert(err);
					});
			})
			.catch((err) => {
				alert(err);
			});
	};
	// console.log(data)
	const getDataToDownload = (data) => {
		const p = data.map((course) => ({ advance: course.course.advance, user: course.userName + " " + course.userSurname, email: course.userEmail }));

		const d = p.map((datum) => {
			const extra = datum.advance.map((section, i) => {
				const exams = Object.fromEntries(section.contents.filter(el => el.contentType === "EXAM").map((ex, ei) => {
					const per = ex.advance.reduce((acc, cur) => acc + (cur.answers[cur.response - 1]?.correctAnswer ? 1 : 0), 0) / ex.advance.length * 100
					return [`section ${i + 1} - exam ${ei + 1} percentage`, `${Math.round(per*100)/100}%` ]

				}))
				// console.log(exams)
				const fin = {
					[`section ${i + 1}`]: section.status,
					[`section ${i + 1} completed`]: section.contents.filter(el => el.status === "COMPLETED").length,
					[`section ${i + 1} total`]: section.contents.length,
					...exams
				}
				return fin
		}).reduce((acc, cur) => ({ ...acc, ...cur }), {})
	// console.log(extra)
	return {
			// ...datum,
			email: datum.email,
			user: datum.user,
			completed: datum.advance.reduce((acc, cur) => acc + cur.contents.filter((c) => c.status === "COMPLETED").length, 0),
			total: datum.advance.reduce((acc, cur) => acc + cur.contents.length, 0),
			...extra
			// ...datum.advance
			// 	.map((section, i) => ({
			// 		[`section ${i + 1}`]: section.status,
			// 	}))
			// 	.reduce((acc, cur) => [cur, ...acc], []),
		};
	});
	console.log(d);
	return d;
	// console.log(filtered)
};

// console.log(filtered, course);

return (
	<Box>
		<Box background="white" overflow="hidden" round="small" elevation="medium">
			<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
				<Heading color="gray" level="4" margin="none">
					Listado de Convocados ({filtered.length})
					</Heading>

				<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
					<Box flex="grow" width={{ max: "450px" }}>
						<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.filter((el) => el.userEmail.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
					</Box>
					<CSVLink data={ex} filename={course.name + "-" + moment().format("DD-MM-YYTHH-MM-A") + ".csv"}>
						<Button primary icon={<Download />} label="Exportar Datos" />
					</CSVLink>
				</Box>
			</Box>

			<ReactTable
				data={filtered}
				defaultPageSize={10}
				// loading={loading}
				loadingText="Cargando Datos..."
				previousText="Convocados Anteriores"
				nextText="Convocados Siguientes"
				noDataText="Sin Convocatorias"
				pageText="Página"
				ofText="de"
				rowsText="filas"
				columns={[
					{
						Header: "Email",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "email",
						style: { display: "flex", whiteSpace: "unset", padding: 15, fontSize: 14, alignItems: "center" },
						accessor: (datum) => datum.userEmail,
					},
					{
						Header: "Nombre",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "name",
						style: { display: "flex", whiteSpace: "unset", padding: 15, fontSize: 14, alignItems: "center" },
						accessor: (datum) => datum.userName,
					},
					{
						Header: "Inicio",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "initDate",
						width: 100,
						style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
						accessor: (datum) => (datum.initDate ? moment(datum.initDate.toDate()).format("DD-MM-YYYY") : ""),
					},
					{
						Header: "Cierre",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "endDate",
						width: 100,
						style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
						accessor: (datum) => (datum.endDate ? moment(datum.endDate.toDate()).format("DD-MM-YYYY") : ""),
					},
					{
						Header: "Completados",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "status",
						width: 150,
						style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
						accessor: (datum) => datum.course?.advance?.reduce((acc, cur) => acc + cur.contents.filter((c) => c.status === "COMPLETED").length, 0) + " de " + datum.course?.advance?.reduce((acc, cur) => acc + cur.contents.length, 0),
					},
					{
						Header: "Ver Avance",
						headerStyle: { padding: 15, fontSize: 16 },
						id: "advance",
						width: 150,
						style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
						accessor: (datum) => (
							<Button
								color="gray"
								hoverIndicator
								icon={<Flag />}
								onClick={() => {
									setViewCourse(datum.courseav);
								}}
							/>
						),
					},
					{
						Header: "",
						headerStyle: { padding: 15 },
						id: "visible",
						width: 50,
						resizable: false,
						sortable: false,
						show: userData.role === "Admin",
						style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
						accessor: (datum) => (
							<Button
								color="gray"
								hoverIndicator
								icon={!datum.visible ? <FormViewHide /> : <View />}
								onClick={() => {
									hideCourse(datum.id);
								}}
							/>
						),
					},
					{
						Header: "",
						headerStyle: { padding: 15 },
						id: "delete",
						width: 50,
						resizable: false,
						sortable: false,
						show: userData.role === "Admin",
						style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
						accessor: (datum) => (
							<Button
								color="gray"
								hoverIndicator
								icon={<Trash />}
								onClick={() => {
									// toogleDeleteModal(!deleteModal);
									// setContentIDToDelete(datum.id);
									deleteCourse(datum.id);
								}}
							/>
						),
					},
				]}
			/>
		</Box>

		{deleteModal && (
			<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
				<Box pad="medium" width={{ min: "300px", max: "600px" }}>
					<Box gap="medium">
						<Heading level="3" margin="none">
							¿Está seguro que desea eliminar esta Convocatoria?
							</Heading>
						<Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
					</Box>

					<Box direction="row" justify="between" margin={{ top: "medium" }}>
						<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
						<Button
							type="submit"
							label="Eliminar"
							color="status-critical"
							primary
							onClick={() => {
								deleteCourse(contentIDToDelete);
								toogleDeleteModal(!deleteModal);
							}}
						/>
					</Box>
				</Box>
			</Layer>
		)}

		{viewCourse && (
			<AdvanceModal
				closeCallback={() => setViewCourse(false)}
				advanceRef={viewCourse}
				submitCallback={(cb) => {
					setViewCourse(false);
				}}
			/>
		)}

		{usersModal.status && (
			<InviteModal
				closeCallback={() => setUsersModal({ status: false, data: {} })}
				submitCallback={(cb) => {
					setUsersModal({ status: false, data: {} });
				}}
			/>
		)}
	</Box>
);
};

export default CoursesAssigned;
