import React, { useState, useEffect, useContext } from 'react';

import { Box, Text, Heading, TextInput, Select, DataTable, CheckBox, Button } from 'grommet';
import { Close } from 'grommet-icons'


import { withRouter, useParams } from "react-router";
import { firestore } from 'firebase'
import AuthContext from '../../context/AuthContext';


const UsergroupData = ({ history }) => {

    const { id } = useParams()
    const { userData,tenant } = useContext(AuthContext)

    const [groupData, setGroupData] = useState({
        name: "",
        description: "",
        parentUserGroup: [],
        childUserGroups: []
    })

    const [userGroups, setUserGroups] = useState([])

    useEffect(() => {
        getGroups()
    }, [])

    const getData = (grps) => {
        tenant.ref.collection("usergroups").doc(id).get()
            .then(async (doc) => {
                if (doc.exists) {
                    const childUserGroups = doc.data().childUserGroups
                    setGroupData({
                        ...groupData,
                        ...doc.data(),
                        childUserGroups: grps.filter(gr => childUserGroups.map(c => c.id).includes(gr.id))
                    })
                } else {
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
    }


    const getGroups = () => {
        tenant.ref.collection("usergroups")
        .get()
            .then((querySnapshot) => {
                const grps = querySnapshot.docs.map(doc => ({ ...doc.data(), ref: doc.ref, id: doc.id }))
                setUserGroups(grps)
                id && getData(grps)
            })
    }


    const createUserGroup = () => {
        const grpRefs = groupData.childUserGroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id))
        const variables = {
            ...groupData,
            name: groupData.name,
            childUserGroups: grpRefs
        }
        tenant.ref.collection("usergroups").add(variables)
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/${tenant.url}/users/groups`)
            }).catch(err => console.log(err))
    }


    const updateUserGroup = () => {
        const grpRefs = groupData.childUserGroups.map((gr, i) => tenant.ref.collection("usergroups").doc(gr.id))
        const variables = {
            ...groupData,
            name: groupData.name,
            childUserGroups: grpRefs
        }
        tenant.ref.collection("usergroups").doc(id).update(variables)
            .then(res => {
                history.push(`${process.env.PUBLIC_URL}/${tenant.url}/users/groups`)
            }).catch(err => console.log(err))
    }


    // const onCheck = (event, value) => {
    //     if (event.target.checked) {
    //         setGroupData({ ...groupData, users: [...groupData.users, value] });
    //     } else {
    //         setGroupData({ ...groupData, users: [...groupData.users.filter(item => item !== value)] });
    //     }
    // };

    // const onCheckAll = event => setGroupData({ ...groupData, users: event.target.checked ? data.users.map(datum => datum.id) : [] });




    return (
        <Box gap="medium" pad={{ vertical: "medium", right: "medium" }}>
            <Box background="white" overflow="hidden" round="small" elevation="medium" >
                <Box pad="small" >
                    <Heading color="gray" level="4" margin="none">Nombre del Grupo</Heading>
                </Box>
                <Box pad="small" >
                    <TextInput background="light-1" placeholder="Nombre del Grupo" value={groupData.name} onChange={event => setGroupData({ ...groupData, name: event.target.value })} />
                </Box>
            </Box>

            {/* <Heading level="3" margin="none">Grupo Padre</Heading> */}

            {/* <Select
                labelKey="name"
                valueKey="id"
                placeholder="Grupo Padre"
                options={dataUserGroups.usergroups.filter(el => el.id !== id)}
                onChange={({ value }) => {
                    setGroupData({
                        ...groupData,
                        parentUserGroup: value,
                        childUserGroups: groupData.childUserGroups.filter(el => el.id !== value.id)
                    })
                }}
                value={groupData.parentUserGroup}
            /> */}

            <Box background="white" overflow="hidden" round="small" elevation="medium" >

                <Box pad="small" >
                    <Heading color="gray" level="4" margin="none">Grupos Hijos</Heading>
                </Box>
                <Box pad="small">
                    <Select
                        labelKey="name"
                        valueKey="id"
                        multiple
                        placeholder="Selecciona un grupo..."
                        options={userGroups
                            //     .filter(el => {
                            //     if (groupData.parentUserGroup && groupData.parentUserGroup.id) {
                            //         return el.id !== id && el.id !== groupData.parentUserGroup.id
                            //     } else {
                            //         return el.id !== id
                            //     }
                            // })
                        }
                        onChange={({ value: nextValue }) => setGroupData({ ...groupData, childUserGroups: nextValue })}
                        value={groupData.childUserGroups}
                    />
                </Box>
                <Box direction="row" wrap gap="small" pad="small">
                    {groupData.childUserGroups.map((el, i) => <Button onClick={() => setGroupData({ ...groupData, childUserGroups: groupData.childUserGroups.filter(gr => gr.id !== el.id) })} key={i} color="black" icon={<Close />} primary label={el.name} />)}
                </Box>
            </Box>


            {/* <Box background="white" overflow="hidden" round="small" elevation="medium" >

                <Box pad="small" >
                    <Heading color="gray" level="4" margin="none">Usuarios del Grupo</Heading>
                </Box>

                <DataTable
                    primaryKey="id"
                    columns={[
                        {
                            property: "checkbox",
                            render: datum => (
                                <CheckBox
                                    key={datum.id}
                                    checked={groupData.users.indexOf(datum.id) !== -1}
                                    onChange={e => onCheck(e, datum.id)}
                                />
                            ),
                            header: <CheckBox
                                checked={groupData.users.length === data.users.length}
                                indeterminate={
                                    groupData.users.length > 0 && groupData.users.length < data.users.length
                                }
                                onChange={onCheckAll}
                            />,
                            sortable: false,
                        }, {
                            property: "name",
                            header: <Text>Nombre del Usuario</Text>,
                        }, {
                            property: "email",
                            header: <Text>email</Text>,
                            primary: true
                        }
                    ]}
                    data={data.users}
                    sortable
                />
            </Box> */}
            <Box align="end">
                {!id ?
                    <Button margin="small" primary label="Crear Grupo" onClick={() => createUserGroup()} />
                    :
                    <Button margin="small" primary label="Editar Grupo" onClick={() => updateUserGroup()} />
                }
            </Box>

        </Box>
    );
}

export default withRouter(UsergroupData);