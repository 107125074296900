import React, { useContext, useRef } from 'react'
import { Box, Button, Heading, Layer } from "grommet";
import { CloudUpload } from "grommet-icons"
import XLSX from 'xlsx'
import { firestore } from "firebase";
import AuthContext from '../context/AuthContext';
import axios from 'axios';

const MasiveUserModal = ({ visible, toggle, file }) => {

    const fileInput = useRef()
    const { tenant, userData } = useContext(AuthContext)

    const createUsers = (e) => {
        if (e.target.files) {
            let fileF = e.target.files[0], read = new FileReader();
            read.readAsBinaryString(fileF)
            read.onloadend = async () => {
                try {
                    var oFile = XLSX.read(read.result, { type: 'binary', password: "1234" })
                    const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], {
                        cellNF: true,
                        raw: false,
                    }).filter(row => row["STORE"])

                    console.log({ data })
                    const usergroupsTotal = await tenant.ref.collection("usergroups").get().then((data) => data.docs.map(doc => ({ ...doc.data(), id: doc.id, ref: doc.ref })))
                    const publicGroup = usergroupsTotal.find(item => item.public)

                    Promise.all(
                        data.map(row => {
                            const email = row["CORREO ELECTRÓNICO"]?.trim().split(";")[0].toLowerCase()
                            const name = row["NOMBRE Y APELLIDO"]?.trim()
                            const rol = row["ROL"]?.trim()
                            const store = row["STORE"]?.trim()
                            const password = row["CONTRASEÑA"]?.trim()
                            const apiRef = row["REF"].trim()

                            firestore().collection("users").where("email", "==", email).get().then(data => {
                                const usergroups = [publicGroup?.ref, usergroupsTotal.find(x => x.name === rol)?.ref]
                                const variables = {
                                    email: !email ? store.replace(/[^a-zA-Z0-9_/-@.]/g, '') + "@solviastores.com" : email,
                                    password: !password ? "solvia1" : password,
                                    name: !name ? store : name,
                                    surname: ""
                                }

                                const restVariables = {
                                    role: "User",
                                    zonas: {
                                        content: true,
                                        focus: false,
                                        forum: false,
                                        itinerary: false,
                                        profile: true,
                                        smartlearning: (rol === "Asesor") ? false : true
                                    },
                                    usergroups,
                                    store,
                                    apiRef,
                                    disabled: false,
                                    blocked: false,
                                    tenants: [{
                                        name: tenant.name,
                                        ref: tenant.ref,
                                        url: tenant.url
                                    }]
                                }


                                if (data.empty) {
                                    console.log({ variables, restVariables })
                                    // El usuario no existe, pasamos a crearlo
                                    axios.post("https://europe-west1-uno-work.cloudfunctions.net/createUser", {
                                        method: 'POST',
                                        body: JSON.stringify(variables)
                                    }).then((response) => {
                                        console.log('Success:', response)
                                        if (!response.data.code) {
                                            return firestore().collection("users").doc(response.data).set({
                                                ...variables,
                                                ...restVariables,
                                                image: `${tenant.id}/users/${response.data}/profilePicture`
                                            }).then(res => {
                                                console.log({ res })
                                                return res
                                            })

                                        } if (response.data.code === "auth/invalid-password") {
                                            throw ("La contraseña debe tener al menos 6 caracteres")
                                        } if (response.data.code === "auth/invalid-email") {
                                            throw ("El formato del email no es el adecuado")
                                        } if (response.data.code === "auth/email-already-exists") {
                                            throw ("El email introducido ya se encuentra en la Base de Datos")
                                        }
                                        // setLoading(false)

                                    }).catch((error) => {
                                        throw (error)
                                        // setLoading(false)
                                    })
                                }
                                //  else {
                                //     // El usuario existe, pasamos a editarlo

                                //     // axios.post("https://europe-west1-hermes-god.cloudfunctions.net/updateUser", {
                                //     //     method: 'POST',
                                //     //     body: JSON.stringify({
                                //     //         ...variables,
                                //     //         usergroups
                                //     //     })
                                //     // }).then((response) => {
                                //     //     console.log('Success:', response)
                                //     //     if (response.data.code === null) {
                                //     //         firestore().collection("users").doc(response.data).set({
                                //     //             ...variables,
                                //     //             ...restVariables,
                                //     //             image: `${tenant.id}/users/${response.data}/profilePicture`
                                //     //         })
                                //     //     } if (response.data.code === "auth/invalid-password") {
                                //     //         alert("La contraseña debe tener al menos 6 caracteres")
                                //     //     } if (response.data.code === "auth/invalid-email") {
                                //     //         alert("El formato del email no es el adecuado")
                                //     //     } if (response.data.code === "auth/email-already-exists") {
                                //     //         alert("El email introducido ya se encuentra en la Base de Datos")
                                //     //     } if (response.data.code === "auth/invalid-uid") {
                                //     //         alert("El usuario que intenta editar no existe en la Base de Datos")
                                //     //     }
                                //     // }).catch((error) => {
                                //     //     console.log(error)
                                //     // })
                                // }
                            })

                        })
                    )//.then(() => toggle())
                }

                catch (err) {
                    alert(err)
                }
            }
        }
    }

    return visible ? <Layer position='center' modal margin='small' responsive={false} onClickOutside={() => toggle()} onEsc={() => toggle()}>
        <Box pad='medium' width={{ min: "300px", max: "600px" }}>
            <Box gap='medium'>
                <Heading level='3' margin='none'>
                    Subida masiva de usuarios.
                </Heading>
                <Box>
                    <input
                        type='file'
                        accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                        ref={fileInput}
                        onChange={(e) => createUsers(e)}
                        style={{ display: "none" }}
                        multiple={false}
                    />
                    <Button hoverIndicator icon={<CloudUpload />} onClick={() => fileInput.current.click()} />
                </Box>
            </Box>

            <Box direction='row' justify='between' margin={{ top: "medium" }}>
                <Button label='Cancel' onClick={() => toggle()} />
                {/* <Button hoverIndicator icon={<Gallery />} onClick={() => setModalMedia({ type, status: true })} /> */}
            </Box>
        </Box>
    </Layer> : <></>
}

export default MasiveUserModal