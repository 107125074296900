
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import {
    Box,
    ResponsiveContext
} from 'grommet';

import SideMenu from '../../components/SideMenu'
import Webinar from './Webinar'
import WebinarViewer from './WebinarViewer';


const RouterUsers = ({ area }) => {

    const size = useContext(ResponsiveContext)

    const routes = [
        {
            route: "/webinars",
            icon: "UserAdd",
            title: "Nuevo Usuario"
        }
    ]

    return (
        <Box direction="row" gap="medium" justify="center" pad="medium">

            {size !== "small" &&
                <Box width="100px">
                    <SideMenu routes={routes} sidebarStatus={true} />
                </Box>
            }

            <Box flex width={{ max: "xlarge" }}>
                <Route exact path="/webinars" component={Webinar} exact />
                <Route exact path="/webinars/viewer" component={WebinarViewer} exact />
            </Box>
        </Box>
    );
}

export default RouterUsers;