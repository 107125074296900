import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select, TextArea, Video } from "grommet";
import { Bold, Task, DocumentText, FormPrevious, Duplicate, Ascend, Descend, CirclePlay, Close, Trash, Gallery, CloudUpload, Music } from "grommet-icons";

import { uuid } from "uuidv4";

import MediaHandler from "../../components/MediaHandler";
import { Editor } from "@tinymce/tinymce-react";

import { withRouter, useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import { firestore } from "firebase";

import { SketchPicker, BlockPicker, SwatchesPicker, TwitterPicker } from "react-color";

const Tutorials = ({ history }) => {
	const { userData, tenant } = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	return (
		<Box>
			<Box direction="row-responsive" gap="medium">
				<Box basis="1/1" fill="horizontal" gap="medium">
					<Box background="white" pad="small" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading level="3" margin="none">
								Tutorial de uso de la plataforma
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<Text>En este video disponeis de un tutorial para el empleo de la plataforma</Text>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<Video width="100%" height="medium" src="https://firebasestorage.googleapis.com/v0/b/uno-work.appspot.com/o/general%2FTutorial%20profesores.mp4?alt=media&token=0760dcc3-5453-40ef-af3d-5fd532d3028a"/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default withRouter(Tutorials);
