import React, { useState, useRef, useCallback } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select, TextArea, Video } from "grommet";
import { Bold, Task, DocumentText, FormPrevious, Duplicate, Ascend, Descend, CirclePlay, Close, Trash, Gallery, CloudUpload, Music } from "grommet-icons";

import MediaHandler from "../../components/MediaHandler";

import { Editor } from "@tinymce/tinymce-react";

import { withRouter, useParams } from "react-router";

const ChallengeForm = ({ history }) => {
	const [loadingMedia, setLoadingMedia] = useState(false);
	const [publishModal, tooglePublishModal] = useState(false);

	const [modalMedia, setModalMedia] = useState({ status: false, type: "images", callback: (cb) => console.log(cb) });

	const { id } = useParams();

	const [image, setImage] = useState(null);
	const [data, setData] = useState([]);

	const [challengeQuill, setChallengeQuill] = useState("");

	const [challengeData, setChallengeData] = useState({
		name: "",
		image: "",
		often: 0,
		amount: 0,
		actions: [],
		notification: false,
		challengeGroup: {},
	});

	const createChallenge = () => {};
	const updateChallenge = () => {};

	const fetchData = () => {
		data.challenge.image && setImage(data.challenge.image.url);
		setChallengeQuill(data.challenge.description);
		setChallengeData({
			...challengeData,
			image: data.challenge.image.id || "",
			name: data.challenge.name,
			often: data.challenge.often,
			actions: data.challenge.actions,
			amount: data.challenge.amount,
			challengeGroup: data.challenge.challengeGroup || {},
		});
	};


	const publishChallenge = async () => {
		let variables = {
			name: challengeData.name,
			description: challengeQuill,
			image: challengeData.image,
			often: challengeData.often,
			actions: challengeData.actions,
			amount: challengeData.amount,
			challengeGroup: challengeData.challengeGroup.id,
		};

		createChallenge({ variables }).then((response) => {
			console.log(response);
			history.push("/challenges");
		});
	};

	const editChallenge = async () => {
		let variables = {
			challengeID: id,
			name: challengeData.name,
			description: challengeQuill,
			image: challengeData.image,
			often: challengeData.often,
			actions: challengeData.actions,
			amount: challengeData.amount,
			challengeGroup: challengeData.challengeGroup.id,
		};

		updateChallenge({ variables }).then((response) => {
			console.log(response);
			history.push("/challenges");
		});
	};

	const editSection = (index, input, field) => {
		var newarr = challengeData;
		newarr.actions[index][field] = input;
		setChallengeData({ ...newarr });
	};

	return (
		<Box>
			{/* <Box margin={{ top: "medium" }} align="start">
                {id && <Button icon={<FormPrevious />} onClick={() => history.goBack()} label="Volver" />}
            </Box> */}
			<Box direction="row-responsive" gap="medium">
				<Box pad={{ vertical: "medium" }} basis="2/3" gap="medium">
					{/* TITULO DE LA PUBLICACIÓN */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Título del Reto
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							<TextInput
								plain
								placeholder="Escribe aquí..."
								value={challengeData.name}
								onChange={(event) => {
									setChallengeData({ ...challengeData, name: event.target.value });
								}}
							/>
						</Box>
					</Box>
					{/* FIN TITULO DE LA PUBLICACIÓN */}

					<Box direction="row" gap="small">
						{/* IMAGEN DESTACADA */}
						<Box background="white" width="50%" overflow="hidden" round="small" elevation="medium">
							<MediaHandler
								type="images"
								title="Imagen Destacada"
								value={image}
								height="350px"
								uploadCallback={(ev) => setChallengeData({ ...challengeData, image: ev[0].id })}
								galleryCallback={(ev) => setChallengeData({ ...challengeData, image: ev.ref })}
								durationCallback={(ev) => console.log(ev)}
							/>
						</Box>
						{/* FIN IMAGEN DESTACADA */}

						{/* CONTENIDO */}
						<Box background="white" width="50%" overflow="hidden" round="small" elevation="medium">
							<Box pad="small">
								<Heading color="gray" level="4" margin="none">
									Descripción del Reto
								</Heading>
							</Box>

							<Box pad={{ bottom: "30px" }}>
								<Editor
									initialValue={challengeQuill ?? "<p></p>"}
									init={{
										height: 300,
										branding: false,
										menubar: "file edit view insert format tools table tc help",
										plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
										toolbar: "undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help",
									}}
									onEditorChange={setChallengeQuill}
									apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
								/>
								)
							</Box>
						</Box>
						{/* FIN CONTENIDO */}
					</Box>

					{/* REPETICIONES */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Acciones del Reto
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							{/* <Box pad="small">
                                <TextInput
                                    placeholder="Numero de Repeticiones"
                                    value={challengeData.amount}
                                    onChange={event => { setChallengeData({ ...challengeData, amount: event.target.value }) }}
                                />
                            </Box>
                            <Box pad="small">
                                <TextInput
                                    placeholder="Distancia entre Recordatorios"
                                    value={challengeData.often}
                                    onChange={event => { setChallengeData({ ...challengeData, often: event.target.value }) }}
                                />
                            </Box> */}

							{challengeData.actions.map((action, i) => (
								<Box key={i} round="small" pad="small" gap="small" background="light-2">
									<Box direction="row">
										<Button hoverIndicator icon={<Duplicate />} />
										<Button hoverIndicator icon={<Descend />} />
										<Button hoverIndicator icon={<Ascend />} />
										<Button hoverIndicator icon={<Trash />} />
									</Box>
									<Box direction="row">
										<Box round="medium" width="40%" background="white" round="small" pad="small">
											<MediaHandler type="images" value={action.media} height="250px" uploadCallback={(ev) => editSection(i, ev[0].url, "media")} galleryCallback={(ev) => editSection(i, ev.url, "media")} durationCallback={(ev) => console.log(ev)} />
										</Box>
										<Box pad="small" flex="grow" gap="small">
											<Box gap="small">
												<Heading color="gray" level="4" margin="none">
													Título de la Acción
												</Heading>
												<TextInput onChange={(ev) => editSection(i, ev.target.value, "title")} placeholder="Escribe aquí..." value={action.title} />
											</Box>
											<Box gap="small">
												<Heading color="gray" level="4" margin="none">
													Descripción de la Acción
												</Heading>
												<TextArea onChange={(ev) => editSection(i, ev.target.value, "description")} size="small" resize="vertical" placeholder="Escribe Aquí..." value={action.description} />
											</Box>
											<Box gap="small">
												<Heading color="gray" level="4" margin="none">
													Puntos de la Acción
												</Heading>
												<TextInput type="number" onChange={(ev) => editSection(i, ev.target.value, "points")} placeholder="Puntos al completarlo" value={action.points} />
											</Box>
										</Box>
									</Box>
									<TextInput plain onChange={(ev) => editSection(i, ev.target.value, "media")} placeholder="" value={action.media} />
								</Box>
							))}

							<Box>
								<Button
									onClick={() =>
										setChallengeData({
											...challengeData,
											actions: [
												...challengeData.actions,
												{
													title: "",
													description: "",
													points: "",
													media: null,
												},
											],
										})
									}
									label="Añadir acción"
								/>
							</Box>
						</Box>
					</Box>
					{/* FIN REPETICIONES */}
				</Box>

				<Box basis="1/3" pad="medium" margin={{ top: "medium" }} round={{ size: "medium", corner: "top-left" }} gap="medium" background="brand">
					{/* BOTONES PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Publicación
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							<Button disabled={challengeData.name !== "" ? false : true} primary label={id ? "Actualizar Reto" : "Publicar Reto"} onClick={() => tooglePublishModal(true)} />
						</Box>
					</Box>
					{/* FIN BOTONES PUBLICACION */}

					{/* SELECCIONAR CATEGORIA  */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Categorías
							</Heading>
						</Box>

						{data && data.challengeGroups && (
							<Box pad="small" gap="small">
								<Select labelKey="name" valueKey="id" placeholder="Selecciona..." options={data.challengeGroups} onChange={({ value }) => setChallengeData({ ...challengeData, challengeGroup: value })} value={challengeData.challengeGroup} />
							</Box>
						)}
					</Box>
					{/* FIN SELECCIONAR CATEGORIA */}

					{/* BLOQUE PUBLICACION */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color="gray" level="4" margin="none">
								Filtrar Usuarios
							</Heading>
						</Box>

						<Box pad="small" gap="medium">
							<CheckBox checked={challengeData.notification} label="Curso Público" onChange={(event) => setChallengeData({ ...challengeData, notification: event.target.checked })} />

							<Collapsible open={!challengeData.notification}>
								<Box background="light-2" gap="small" round="small" elevation="large" pad="small">
									<Heading level="5" margin="none">
										Grupo de Usuarios
									</Heading>

									<Select options={["Group 1", "Group 2", "Group 3"]} value={challengeData.usersGroup} onChange={({ option }) => setChallengeData({ ...challengeData, usersGroup: option })} />
								</Box>
							</Collapsible>
						</Box>
					</Box>
					{/* FIN BLOQUE PUBLICACION */}
				</Box>
			</Box>
			{/* MODALES */}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" height="medium">
						<Button
							label="Publicar"
							onClick={() => {
								tooglePublishModal(!publishModal);
								id ? editChallenge() : publishChallenge();
							}}
						/>
					</Box>
				</Layer>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(ChallengeForm);
