import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import { Box, ResponsiveContext } from "grommet";

import Notifications from "./Notifications";
import NotificationForm from "./NotificationForm";

import SideMenu from "../../components/SideMenu";

const RouterForums = ({ area }) => {
	const size = useContext(ResponsiveContext);
	const { url } = useRouteMatch();

	const routes = [
		// {
		//     route: "/notifications",
		//     icon: "Notification",
		//     title: "Dashboard"
		// },
		{
			route: `${url}/newnotification`,
			icon: "Send",
			title: "Nueva Notificación",
			allowed: ["Admin"],
		},
        {
			route: `${url}`,
			icon: "Mail",
			title: "Notificaciones",
			allowed: ["Admin"],
		},
	];

	return (
		<Box direction="row" gap="medium" justify="center" pad="medium">
			{size !== "small" && (
				<Box width="100px">
					<SideMenu routes={routes} sidebarStatus={true} />
				</Box>
			)}
			<Box flex width={{ max: "xlarge" }}>
				<Route exact path={`${url}`} component={Notifications} exact />
				<Route path={`${url}/newnotification`} component={NotificationForm} />
				<Route path={`${url}/editnotification/:id`} component={NotificationForm} />
			</Box>
		</Box>
	);
};

export default RouterForums;
