import React, { useState, useEffect } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, Lock, FormViewHide, View, Close } from "grommet-icons";

import moment from "moment";
import { withRouter } from "react-router";
import Loader from "react-loader-spinner";

import ReactTable from "react-table";
import "react-table/react-table.css";

const UserList = ({ location, history }) => {
	const [filtered, setFiltered] = useState([]);
	const [logs, showLogs] = useState({ status: false, data: [] });

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	const millisToMinutesAndSeconds = (millis) => {
		var minutes = Math.floor(millis / 60000);
		var seconds = ((millis % 60000) / 1000).toFixed(0);
		return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
	};

	useEffect(() => {
		data && setFiltered(data.users.filter((u) => u.navigationLogs.length > 0));
	}, [data]);

	console.log(data);

	return (
		<Box>
			<Box background="white" overflow="hidden" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Acciones de Usuarios
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "450px" }}>
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.users.filter((el) => el.email.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => history.push("/forums/newforum")} icon={<Add />} label="Nuevo Usuario" />
					</Box>
				</Box>

				<Box>
					{data && (
						<ReactTable
							data={filtered}
							defaultPageSize={10}
							loading={loading}
							loadingText="Cargando Datos..."
							previousText="Usuarios Anteriores"
							nextText="Usuarios Siguientes"
							noDataText="Sin datos"
							pageText="Página"
							ofText="de"
							rowsText="filas"
							columns={[
								{
									Header: "Email",
									headerStyle: { padding: 15 },
									id: "email",
									style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.email}</Text>
										</Box>
									),
								},
								{
									Header: "Comentarios",
									headerStyle: { padding: 15 },
									id: "comments",
									width: 100,
									style: { display: "flex", textAlign: "center", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.comments.length}</Text>
										</Box>
									),
								},
								{
									Header: "Likes",
									headerStyle: { padding: 15 },
									id: "likes",
									width: 100,
									style: { display: "flex", textAlign: "center", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.liked.length}</Text>
										</Box>
									),
								},
								{
									Header: "Contenidos",
									headerStyle: { padding: 15 },
									id: "contents",
									width: 200,
									style: { display: "flex", textAlign: "center", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.contents.length}</Text>
										</Box>
									),
								},
								{
									Header: "Logs",
									headerStyle: { padding: 15 },
									id: "actions",
									width: 200,
									style: { display: "flex", textAlign: "center", whiteSpace: "unset", padding: 15, alignItems: "center" },
									accessor: (datum) => (
										<Box>
											<Text>{datum.navigationLogs.length}</Text>
										</Box>
									),
								},
								{
									Header: "Ver Logs",
									headerStyle: { padding: 15 },
									id: "logs",
									width: 100,
									resizable: false,
									sortable: false,
									style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
									accessor: (datum) => <Button onClick={() => showLogs({ status: true, data: datum.navigationLogs })} color="gray" icon={<View />} hoverIndicator />,
								},
							]}
						/>
					)}
				</Box>
			</Box>

			{logs.status && (
				<Layer position="right" full="vertical" onClickOutside={() => showLogs({ status: false, data: [] })} onEsc={() => showLogs({ status: false, data: [] })}>
					<Box width="large">
						<Box background="brand" justify="between" align="center" flex="grow" pad="medium" direction="row">
							<Heading level="2" margin="none">
								Navegación del Usuario
							</Heading>
							<Button hoverIndicator onClick={() => showLogs({ status: false, data: [] })} icon={<Close color="white" />} />
						</Box>

						<Box overflow="scroll" gap="medium" pad="medium">
							{logs.data.map((e, i, arr) => (
								<Box key={i} flex="grow" gap="small" pad="medium">
									<Heading level="3" margin="none">
										{i}. {e.pageName}
									</Heading>
									<Text size="small">Realizado el: {moment(e.createdAt).format("DD MMMM YYYY, hh:mm:ss")}</Text>
									<Text size="small">Tiempo en Pantalla: {millisToMinutesAndSeconds(moment(arr[i + 1] ? arr[i + 1].createdAt : 0).diff(e.createdAt))}</Text>
									{/* <Box direction="row" justify="between">
                    {e.userResponse && <Text size="small">Respondido el: {moment(e.userCompleted).format("DD MMMM YYYY, hh:mm")}</Text>}
                  </Box>
                  <Box background="light-1" pad="small" round="small">
                    {e.type === "UPLOAD" ?
                      <Box>
                        {e.userResponse ?
                          <video key={e.userResponse.url} style={{ maxHeight: 350 }} width="100%" height="100%" controls onLoadedMetadata={dat => console.log(dat.currentTarget.duration)}>
                            <source src={e.userResponse.url} type="video/mp4" />
                          </video>
                          :
                          <Text>"Sin Completar el reto"</Text>
                        }
                      </Box>
                      :
                      <Text>{e.userResponse ? JSON.stringify(e.userResponse) : "Sin Completar el reto"}</Text>
                    }
                  </Box> */}
								</Box>
							))}
						</Box>
					</Box>
				</Layer>
			)}

			{loading && (
				<Layer>
					<Box pad="medium">
						<Loader type="Puff" color="#00BFFF" height={100} width={100} />
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(UserList);
