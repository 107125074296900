import React, { useState, useEffect, useContext } from "react";
import { Box, TextInput, Image, Heading, Text, Layer, Button } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add, UserAdd, View } from "grommet-icons";
import UsersLibrary from "../../components/UsersLibrary";

import moment from "moment";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const Itineraries = ({ history }) => {
	const [deleteModal, toogleDeleteModal] = useState(false);
	const [usersModal, setUsersModal] = useState({ status: false, data: {} });
	const [loading, setLoading] = useState(true);
	const [filtered, setFiltered] = useState([]);
	const [data, setData] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");

	const { userData, tenant } = useContext(AuthContext);
	useEffect(() => {
		setLoading(true);

		const unsubscribe = tenant.ref.collection("itineraries").onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					const img = doc.data().image;
					const url = img
						? await storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg")
						: "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg";
					const postData = doc.data();
					return { id: doc.id, ...postData, image: url };
				})
			).then((res) => {
				// console.log(res[0])
				setLoading(false);
				setData(res);
				setFiltered(res);
			});
		});

		return () => {
			unsubscribe();
		};
	}, []);

	// const [deleteItinerary] = useMutation(mutation.deleteItinerary, {
	//   refetchQueries: [{ query: queries.getItineraries }]
	// });

	// const [createAvitinerary] = useMutation(mutation.createAvitinerary, {
	//   refetchQueries: [{ query: queries.getItineraries }]
	// });

	const inviteUsers = (users, itinerary) => {
		const advance = [...itinerary.content];
		users.map((user) => {
			const variables = {
				itinerary: itinerary.id,
				name: itinerary.name,
				image: itinerary.image,
				user: user.id,
				advance: advance,
				state: "STARTED",
				// initDate: new Date().toISOString()
			};
			firestore()
				.collection("users")
				.doc(user.id)
				.collection("itineraryavs")
				.add(variables)
				.then((res) => console.log(res))
				.catch((res) => console.log(res));
			// createAvitinerary({ variables })
			//   .then(res => {
			//     console.log(res)

			//     // const variables = {
			//     //   title: `¡Tienes un nuevo itinerario!`,
			//     //   body: `Has sido asignado al itinerario ${itinerary.name}`,
			//     //   data: {
			//     //     screen: "Itinerary",
			//     //     params: {
			//     //     }
			//     //   },
			//     //   date: moment()
			//     //     .utc()
			//     //     .format(),
			//     //   users: [user.id]
			//     // };
			//     // console.log(variables);
			//     // newNotification({ variables }).then(res => console.log(res));
			//   })
			//   .catch(err => console.log(err))
		});
	};

	if (loading) {
		return null;
	}
	return (
		<Box>
			<Box background="white" round="small" elevation="medium">
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
					<Heading color="gray" level="3" margin="none">
						Listado de Desafíos
					</Heading>

					<Box justify="end" gap="medium" direction="row" flex="grow" wrap>
						<Box flex="grow" width={{ max: "450px" }}>
							<TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/training/newitinerary`)} icon={<Add />} label="Nuevo Desafio" />
					</Box>
				</Box>

				<Box>
					<ReactTable
						data={filtered}
						defaultPageSize={10}
						loading={loading}
						loadingText="Cargando Datos..."
						previousText="Entradas Anteriores"
						nextText="Entradas Siguientes"
						noDataText="Sin Contenidos"
						pageText="Página"
						ofText="de"
						rowsText="filas"
						columns={[
							{
								Header: "Imagen",
								headerStyle: { padding: 15 },
								id: "image",
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", padding: 15 },
								accessor: (datum) => (
									<Box background="light-3" border="all" height="60px" width="60px" round="full" overflow="hidden">
										<Image fit="cover" src={datum.image} />
									</Box>
								),
							},
							{
								Header: "Título",
								headerStyle: { padding: 15 },
								id: "name",
								style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
								accessor: (datum) => (
									<Box>
										<Text>{datum.name}</Text>
									</Box>
								),
							},
							{
								Header: "N Contenidos",
								id: "content",
								headerStyle: { padding: 15 },
								sortable: false,
								width: 150,
								style: { display: "flex", justifyContent: "center", alignItems: "center" },
								accessor: (datum) => datum.content.length,
							},
							{
								Header: "Puntos Totales",
								id: "points",
								headerStyle: { padding: 15 },
								width: 150,
								resizable: false,
								style: { display: "flex", paddingLeft: 15, justifyContent: "center", paddingRight: 15, alignItems: "center" },
								accessor: (datum) => (datum.content.length !== 0 ? datum.content.map((m) => m.score).reduce((a, b) => a + b) : 0),
							},
							{
								Header: "Invitar",
								headerStyle: { padding: 15 },
								id: "invite",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => <Button hoverIndicator onClick={() => setUsersModal({ status: true, data: datum })} icon={<UserAdd color="gray" style={{ cursor: "pointer" }} />} />,
							},
							{
								Header: "Resultados",
								headerStyle: { padding: 15 },
								id: "results",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Link to={{ pathname: `${process.env.PUBLIC_URL}/${tenant.url}/training/itineraryresults/${datum.id}` }}>
										<Button fill color="gray" icon={<View />} hoverIndicator />
									</Link>
								),
							},
							{
								Header: "Editar",
								headerStyle: { padding: 15 },
								id: "edit",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Link to={{ pathname: `${process.env.PUBLIC_URL}/${tenant.url}/training/edititinerary/${datum.id}` }}>
										<Button fill color="gray" icon={<Edit />} hoverIndicator />
									</Link>
								),
							},
							{
								Header: "Eliminar",
								headerStyle: { padding: 15 },
								id: "delete",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Button
										color="gray"
										hoverIndicator
										icon={<Trash />}
										onClick={() => {
											toogleDeleteModal(!deleteModal);
											setContentIDToDelete(datum.id);
										}}
									/>
								),
							},
						]}
					/>
				</Box>
			</Box>

			{deleteModal && (
				<Layer position="center" modal margin="small" responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad="medium" width={{ min: "300px", max: "600px" }}>
						<Box gap="medium">
							<Heading level="3" margin="none">
								¿Está seguro que desea eliminar este Desafío?
							</Heading>
							<Text>Esta acción no puede deshacerse, una vez eliminado el desafío no podrá ser recuperado</Text>
						</Box>

						<Box direction="row" justify="between" margin={{ top: "medium" }}>
							<Button label="Cancel" onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type="submit"
								label="Eliminar"
								color="status-critical"
								primary
								onClick={() => {
									firestore().collection("itineraries").doc(contentIDToDelete).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{usersModal.status && (
				<UsersLibrary
					closeCallback={() => setUsersModal({ status: false, data: {} })}
					submitCallback={(cb) => {
						inviteUsers(cb, usersModal.data);
						setUsersModal({ status: false, data: {} });
					}}
				/>
			)}
		</Box>
	);
};

export default withRouter(Itineraries);
