import React, { useEffect } from "react";
import { useField } from "formik";
import { Button } from "grommet";

const ButtonField = ({ label, icon, type, ...props }) => {
	const [field, meta, helpers] = useField(props);

	return <Button {...field} {...props} size="small" plain={false} tip={{ dropProps: { background: "white" }, content: label }} icon={icon} onClick={() => helpers.setValue(type)} primary={field.value === type} />
};

export default ButtonField;
