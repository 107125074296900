import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import { Box, Button, Collapsible, Heading, Text, Image } from "grommet";

import * as Icon from "grommet-icons";
import AuthContext from "../context/AuthContext";

const SideMenu = ({ hidden, routes = [], location }) => {

	const { authFunctions, customization, userData } = useContext(AuthContext);
	const { pathname } = location;
	return (
		<Box gap="medium" elevation="medium" pad={{ vertical: "medium" }} background="brand" style={{ position: "absolute" }} round="7px">
			{routes.map((route, i) => {
				const TagName = Icon[route.icon];
				if (route?.allowed?.includes(userData.role)) {
					return (
						<Link className="grow" style={{ textDecoration: "none" }} key={i} to={route.route}>
							<Box width={{ max: "100px" }} pad={{ horizontal: "medium" }} align="center">
								<TagName color={pathname === route.route ? customization.accentColor : customization.headerElementsColor} />

								<Text size="xsmall" textAlign="center" color={pathname === route.route ? "accent-1" : customization.headerElementsColor}>
									{route.title}
								</Text>
							</Box>
						</Link>
					);
				} else {
					return null
				}
			})}
		</Box>
	);
};

export default withRouter(SideMenu);
