import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch, useRouteMatch } from "react-router-dom";

import { Box, ResponsiveContext } from "grommet";

import ConfigForm from "./ConfigForm";

import SideMenu from "../../components/SideMenu";
import Tutorials from "./Tutorials";

const RouterChallenges = ({ area }) => {

    const size = useContext(ResponsiveContext);
	const { url } = useRouteMatch();

	const routes = [
		{
			route: `${url}`,
			icon: "Paint",
			title: "Diseño",
			allowed: ["Admin"]
		},
	];

	

	return (
		<Box direction="row" gap="medium" justify="center" pad="medium">
			{size !== "small" && (
				<Box width="100px">
					<SideMenu routes={routes} sidebarStatus={true} />
				</Box>
			)}

			<Box flex width={{ max: "xlarge" }}>
				<Route exact path={`${url}`} component={ConfigForm} exact />
				<Route exact path={`${url}/tutorials`} component={Tutorials} exact />
			</Box>
		</Box>
	);
};

export default RouterChallenges;
