import React, { useState, useEffect, useContext } from "react";
import { Box, Image, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Star, Edit, Trash, Add, FormView, FormViewHide, DocumentText, CirclePlay, FormSearch, Music, Task } from "grommet-icons";

import moment from "moment";

import { withRouter } from "react-router";

import ReactTable from "react-table";
import "react-table/react-table.css";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";

const Categories = ({ history }) => {
	const { userData, tenant } = useContext(AuthContext);

	const [deleteModal, toogleDeleteModal] = useState(false);
	const [data, setData] = useState([]);
	const [filtered, setFiltered] = useState([]);
	const [contentIDToDelete, setContentIDToDelete] = useState("");

	useEffect(() => {
		const unsubscribe = tenant.ref.collection("categories").onSnapshot((querySnapshot) => {
			Promise.all(
				querySnapshot.docs.map(async (doc) => {
					const img = doc.data().image;
					const url = img ? await storage().ref(img).getDownloadURL() : "";
					return { ...doc.data(), image: url, id: doc.id };
				})
			).then((res) => {
				setData(res);
				setFiltered(res);
			});
		});

		return () => unsubscribe();
	}, []);

	const deleteCategory = () => {};

	return (
		<Box>
			<Box background='white' round='small' elevation='medium'>
				<Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap='large' direction='row' justify='between' align='center'>
					<Heading color='gray' level='3' margin='none'>
						Listado de Categorias
					</Heading>

					<Box justify='end' gap='medium' direction='row' flex='grow' wrap>
						<Box flex='grow' width={{ max: "450px" }}>
							<TextInput placeholder='Buscar...' type='search' onChange={(ev) => setFiltered([...data?.filter((el) => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
						</Box>
						<Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/content/newcategory`)} icon={<Add />} label='Nueva Categoría' />
					</Box>
				</Box>

				<Box>
					<ReactTable
						data={filtered}
						defaultPageSize={10}
						// loading={loading}
						loadingText='Cargando Datos...'
						previousText='Categorías Anteriores'
						nextText='Categorías Siguientes'
						noDataText='Sin Contenidos'
						pageText='Página'
						ofText='de'
						rowsText='filas'
						columns={[
							{
								Header: "",
								headerStyle: { padding: 15 },
								id: "image",
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", padding: 15 },
								accessor: (datum) => (
									<Box background='light-3' border='all' height='60px' width='60px' round='full' overflow='hidden'>
										<Image fit='cover' src={datum.image} />
									</Box>
								),
							},
							{
								Header: "Título",
								headerStyle: { padding: 15 },
								id: "name",
								style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
								accessor: (datum) => (
									<Box>
										<Text>{datum.name}</Text>
									</Box>
								),
							},
							{
								Header: "Estado",
								id: "active",
								headerStyle: { padding: 15 },
								sortable: false,
								width: 100,
								style: { display: "flex", justifyContent: "center", alignItems: "center" },
								accessor: (datum) =>
									datum.active ? (
										<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} primary label='Visible' color='status-ok' size='small' />
									) : (
										<Button style={{ color: "white" }} hoverIndicator={false} active={false} focusIndicator={false} label='Oculta' primary color='status-error' size='small' />
									),
							},
							{
								Header: "Contenidos",
								id: "contents",
								headerStyle: { padding: 15 },
								width: 150,
								resizable: false,
								style: { display: "flex", paddingLeft: 15, justifyContent: "center", paddingRight: 15, alignItems: "center" },
								accessor: (datum) => datum.contents && datum.contents.length,
							},
							{
								Header: "Editar",
								headerStyle: { padding: 15 },
								id: "edit",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/content/editcategory/${datum.id}` }}>
										<Button fill color='gray' icon={<Edit />} hoverIndicator />
									</Link>
								),
							},
							{
								Header: "Eliminar",
								headerStyle: { padding: 15 },
								id: "delete",
								width: 100,
								resizable: false,
								sortable: false,
								style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
								accessor: (datum) => (
									<Button
										color='gray'
										hoverIndicator
										icon={<Trash />}
										onClick={() => {
											toogleDeleteModal(!deleteModal);
											setContentIDToDelete(datum.id);
										}}
									/>
								),
							},
						]}
					/>
				</Box>
			</Box>

			{deleteModal && (
				<Layer position='center' modal margin='small' responsive={false} onClickOutside={() => toogleDeleteModal(!deleteModal)} onEsc={() => toogleDeleteModal(!deleteModal)}>
					<Box pad='medium' width={{ min: "300px", max: "600px" }}>
						<Box gap='medium'>
							<Heading level='3' margin='none'>
								¿Está seguro que desea eliminar esta Categoría?
							</Heading>
							<Text>Esta acción no puede deshacerse. Los contenidos asociados a esta categoría perderan la relación. Una vez eliminada la categoría no podrá ser recuperada</Text>
						</Box>

						<Box direction='row' justify='between' margin={{ top: "medium" }}>
							<Button label='Cancel' onClick={() => toogleDeleteModal(!deleteModal)} />
							<Button
								type='submit'
								label='Eliminar'
								color='status-critical'
								primary
								onClick={() => {
									tenant.ref.collection("categories").doc(contentIDToDelete).delete();
									toogleDeleteModal(!deleteModal);
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
};

export default withRouter(Categories);
