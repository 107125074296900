import React, { useState, useEffect, useContext } from 'react';
import { Box, Layer, Button, Select, DataTable, Text, CheckBox, TextInput } from 'grommet';

import { intersection } from 'lodash'

import { firestore } from 'firebase'
import AuthContext from '../context/AuthContext';
import ScheduleComponent from './ScheduleComponent';
import ScheduleModal from './ScheduleModal';
import moment from 'moment'

const ContentLibrary = ({ closeCallback, submitCallback, withDates = true }) => {
    const { tenant, userData } = useContext(AuthContext)
    const [checked, setChecked] = useState([]);
    const [users, setUsers] = useState([])
    const [usergroups, setUsergroups] = useState([])
    const [selectedUsersGroup, setSelectedUsersGroup] = useState([])
    const [initDate, setInitDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [openInitDate, toogleInitDate] = useState(false)
    const [openEndDate, toogleEndDate] = useState(false)

    const columns = [
        {
            property: "email",
            header: <Text>Email</Text>,
            render: datum => (<Text>{datum.email}</Text>),
            primary: true,
            search: true
        },
        {
            property: "name",
            header: "Nombre"
        },
        {
            property: "surname",
            header: "Apellido"
        },
        {
            property: "usergroups",
            header: <Text>Grupos</Text>,
            render: datum => usergroups?.length > 0 && <Text>{datum.usergroups?.map(x => usergroups.find(y => y.id === x.id)?.name)}</Text>
        },
    ];

    useEffect(() => {
        setChecked(users.filter(user => intersection(user.usergroups.map(ugroup => ugroup.id), selectedUsersGroup.map(us => us.id))?.length > 0))
    }, [selectedUsersGroup])

    useEffect(() => {
        const unsubscribe = firestore().collection("users")
            .where("tenants", "array-contains", tenant.ref)
            .onSnapshot((querySnapshot) => {
                Promise.all(querySnapshot.docs.map(async (doc) => {
                    return { ...doc.data(), id: doc.id }
                })).then(async res => {
                    setUsers(res)
                })
            })

        const getUsergroups = tenant.ref.collection("usergroups")
            .onSnapshot((querySnapshot) => {
                Promise.all(querySnapshot.docs.map(async (doc) => {
                    return { ...doc.data(), id: doc.id }
                })).then(res => {
                    // console.log({ res })
                    setUsergroups(res)
                })
            })

        return () => {
            unsubscribe()
            getUsergroups()
        }
    }, [])


    const onCheck = (event, value) => {
        event.target.checked && setChecked([...checked, value]);
        !event.target.checked && setChecked(checked.filter(item => item !== value));
    };

    const onCheckAll = event => {
        setChecked(
            event.target.checked ? users.map(datum => datum) : []
        );
    };

    return (
        <Layer full="vertical" position="left" onEsc={() => closeCallback()} onClickOutside={() => closeCallback()}>
            <Box width={{ min: "large" }}>
                <Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
                    {/* <Heading color="gray" level="4" margin="none">Selecciona uno o varios Usuarios</Heading> */}
                    <Box direction="row" gap="small" pad="small">
                        <Button label="Cancelar" onClick={() => closeCallback()} />
                        <Button primary label="Añadir" onClick={() => {
                            if (checked && checked.length > 0) {
                                submitCallback({ users: checked, initDate, endDate })
                            }
                        }} />
                    </Box>
                </Box>
                {withDates && <Box direction="row" gap="medium" pad="medium">
                    <Box gap="5px" flex>
                        <Text size="xsmall">Fecha de Inicio</Text>
                        <TextInput placeholder="Fecha Inicio" onClick={() => toogleInitDate(true)} value={initDate ? initDate : ""} />
                        {openInitDate && <ScheduleModal prevDate={initDate}
                            dateCallback={(ev) => {
                                setInitDate(ev)
                                toogleInitDate(false)
                            }}
                            cancelCallback={ev => toogleInitDate(false)} />}
                    </Box>

                    <Box gap="5px" flex>
                        <Text size="xsmall">Fecha de Final</Text>
                        <TextInput placeholder="Fecha Fin" onClick={() => toogleEndDate(true)} value={endDate ? endDate : ""} />
                        {openEndDate && <ScheduleModal prevDate={initDate}
                            dateCallback={(ev) => {
                                setEndDate(ev)
                                toogleEndDate(false)
                            }}
                            cancelCallback={ev => toogleEndDate(false)} />}
                    </Box>
                </Box>}
                <Box gap="medium" pad="medium">
                    <Text size="xsmall">Seleccionar usuarios por grupo</Text>
                    <Select
                        labelKey="name"
                        valueKey="id"
                        placeholder="Seleccionar por grupo..."
                        multiple={true}
                        options={usergroups}
                        onChange={({ value }) => setSelectedUsersGroup(value)}
                        value={selectedUsersGroup}
                    />
                </Box>
                <Box overflow="scroll" flex>
                    <DataTable
                        columns={[
                            {
                                property: "checkbox",
                                render: datum => (
                                    <CheckBox
                                        key={datum.id}
                                        checked={checked.map(el => el.id).indexOf(datum.id) !== -1}
                                        onChange={e => onCheck(e, datum)}
                                    />
                                ),
                                header: (
                                    <CheckBox
                                        checked={checked.length === users.length}
                                        indeterminate={
                                            checked.length > 0 && checked.length < users.length
                                        }
                                        onChange={onCheckAll}
                                    />
                                ),
                                sortable: false
                            },
                            ...columns
                        ]}
                        data={users}
                        sortable
                        resizeable
                    />
                </Box>
            </Box>
        </Layer>
    );
}

export default ContentLibrary;