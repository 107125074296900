import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, TextInput } from 'grommet'
import {firestore, storage} from 'firebase'

// import OpvSession from 'openvidu-react';
// import { OpenVidu, Session } from 'openvidu-browser';

// const OV = new OpenVidu()

const Webinar = () => {

    // const [session, setSession] = useState(OV.initSession())
    const [sessionID, setSessionID] = useState("")
    const [user, setUser] = useState("")
    const [init, setInit] = useState(false)

    
    return (
        <Box>
            <TextInput onChange={(ev) => setSessionID(ev.target.value)} value={sessionID} placeholder="session" />
            <TextInput onChange={(ev) => setUser(ev.target.value)} value={user} placeholder="username" />
            {/* <Button label="Join" onClick={() => joinChannel()} /> */}
        </Box>
    );
}

export default Webinar;