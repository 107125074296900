import React, { useState, useEffect, useContext } from "react";
import { Box, Heading, Text, Layer, Button, TextInput } from "grommet";
import { Link } from "react-router-dom";
import { Edit, Trash, Add } from "grommet-icons";

import { firestore, storage } from 'firebase'

import { withRouter } from "react-router";

import ReactTable from 'react-table'
import 'react-table/react-table.css'
import AuthContext from "../../context/AuthContext";

const Exams = ({ location, history }) => {

  const [deleteModal, toogleDeleteModal] = useState(false);
  const [filtered, setFiltered] = useState([])
  const [contentIDToDelete, setContentIDToDelete] = useState("");
  const [data, setData] = useState([])

  const { userData, tenant } = useContext(AuthContext)

  useEffect(() => {
    const unsubscribe = tenant.ref.collection("exams")
      .onSnapshot((querySnapshot) => {
        Promise.all(querySnapshot.docs.map(async (doc) => {
          return { ...doc.data(), id: doc.id }
        })).then(res => {
          setData(res)
          setFiltered(res)
        })
      })

    return () => unsubscribe()
  }, [])


  const deleteExam = (examID) => {
    tenant.ref.collection("exams").doc(examID).delete()
  }

  return (
    <Box>

      <Box background="white" overflow="hidden" round="small" elevation="medium" >
        <Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
          <Heading color="gray" level="3" margin="none">Listado de Examenes</Heading>

          <Box justify="end" gap="medium" direction="row" flex="grow" wrap>
            <Box flex="grow" width={{ max: "450px" }}>
              <TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.exams.filter(el => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
            </Box>
            <Button primary onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/newexam`)} icon={<Add />} label="Nuevo Examen" />
          </Box>
        </Box>

        <Box>
          {data &&
            <ReactTable
              data={filtered}
              defaultPageSize={10}
              loadingText="Cargando Datos..."
              previousText='Examenes Anteriores'
              nextText='Examenes Siguientes'
              noDataText='Sin Contenidos'
              pageText='Página'
              ofText='de'
              rowsText='filas'
              columns={[
                {
                  Header: 'Título',
                  headerStyle: { padding: 15 },
                  id: 'title',
                  style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
                  accessor: datum => (
                    <Box>
                      <Text >{datum.name}</Text>
                    </Box>
                  )
                }, {
                  Header: "Preguntas",
                  id: "questions",
                  headerStyle: { padding: 15 },
                  width: 130,
                  style: { display: "flex", justifyContent: "center", alignItems: "center" },
                  accessor: datum => datum.advance ? datum.advance.length : 0
                }, {
                  Header: "Editar",
                  headerStyle: { padding: 15 },
                  id: "edit",
                  width: 100,
                  resizable: false,
                  sortable: false,
                  style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
                  accessor: datum => (
                    <Link to={{ pathname: `${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/editexam/${datum.id}` }}>
                      <Button fill color="gray" icon={<Edit />} hoverIndicator />
                    </Link>
                  )
                }, {
                  Header: "Eliminar",
                  headerStyle: { padding: 15 },
                  id: "delete",
                  width: 100,
                  resizable: false,
                  sortable: false,
                  style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
                  accessor: datum => (
                    <Button color="gray" hoverIndicator icon={<Trash />}
                      onClick={() => {
                        toogleDeleteModal(!deleteModal);
                        setContentIDToDelete(datum.id);
                      }}
                    />
                  )
                }
              ]}
            />
          }
        </Box>
      </Box>


      {deleteModal && (
        <Layer position="center" modal margin="small" responsive={false}
          onClickOutside={() => toogleDeleteModal(!deleteModal)}
          onEsc={() => toogleDeleteModal(!deleteModal)}
        >
          <Box pad="medium" width={{ min: "300px", max: "600px" }} >
            <Box gap="medium">
              <Heading level="3" margin="none">¿Está seguro que desea eliminar este contenido?</Heading>
              <Text>Esta acción no puede deshacerse, una vez eliminado el contenido no podrá ser recuperado</Text>
            </Box>

            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Button
                label="Cancel"
                onClick={() => toogleDeleteModal(!deleteModal)}
              />
              <Button
                type="submit"
                label="Eliminar"
                color="status-critical"
                primary
                onClick={() => {
                  deleteExam(contentIDToDelete);
                  toogleDeleteModal(!deleteModal);
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default withRouter(Exams);