import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Box, Button, Heading, Text, Image } from "grommet";
import { Edit, Favorite, Like, Chat } from "grommet-icons";
import AuthContext from "../../context/AuthContext";
import { storage } from "firebase";

const Dashboard = ({ history }) => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	const { userData, tenant } = useContext(AuthContext);

	useEffect(() => {
		const unsubscribe = tenant.ref
			.collection("contents")
			.orderBy("publishDate", "desc")
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						const img = doc.data().image;
						const url = img ? await storage().ref(img).getDownloadURL() : "";
						return { ...doc.data(), image: url, id: doc.id };
					})
				).then((res) => {
					setData(res);
                    console.log(res)
                    setLoading(false)
				});
			});

		return () => unsubscribe();
	}, []);

	if (loading) {
		return null;
	}
	return (
		<Box direction='row' gap='small'>
			<Box basis='1/3'>
				<Box pad='small'>
					<Heading color='gray' level='4' margin='none'>
						Últimos Contenidos
					</Heading>
				</Box>
				<Box gap='medium' pad='small'>
					{data.map((content, i) => (
						<Box key={i} animation={{ type: "fadeIn", delay: i * 300 }} elevation='large' gap='small' round='small' background='white'>
							<Box round='small' overflow='hidden' height='small' background='gainsboro'>
								<Image src={content.image ? content.image : null} fit='cover' />
							</Box>
							<Box pad={{ horizontal: "small" }}>
								<Text>{content.title}</Text>
							</Box>
							<Box direction='row' justify='between'>
								<Box direction='row'>
									<Box direction='row' align='center' pad='small' gap='5px'>
										<Favorite 
										onClick={() => console.log(`this like sums up to ${tenant.customization.likePoints} points`)} 
										color='red' 
										/> 
										<Text>{content.likeCount || 0}</Text>
									</Box>
									<Box direction='row' align='center' pad='small' gap='5px'>
										<Chat
										onClick={() => console.log(`this like sums up to ${tenant.customization.commentPoints} points`)} 
										color='#0d47a1'
										 /> 
										<Text>{content.likeCount || 0}</Text>
									</Box>
								</Box>

								<Box direction='row' align='center' round pad='small' gap='5px'>
									<Button onClick={() => history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/content/editcontent/${content.id}`)} icon={<Edit />} hoverIndicator round />
								</Box>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
			<Box basis='33%' round='small'>
				<Box pad='small'>
					<Heading color='gray' level='4' margin='none'>
						Contenidos Programados
					</Heading>
				</Box>
				<Box gap='medium' pad='small'>
					{/* {data.community.map((content, i) => (
						<Box key={i} animation={{ type: "fadeIn", delay: i * 300 }} elevation='medium' gap='small' round='small' background='white'>
							<Box direction='row' gap='small' align='center' pad='small'>
								<Box round='100%' height='50px' width='50px' overflow='hidden'>
									<Image src={content.user.image ? content.user.image.url : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRKjyFdkTGcTZzPRrjooApHIHJGiBtTW9pgGfhJDE-B1Vg4Jf-i"} fit='cover' />
								</Box>
								<Text>{content.user.username}</Text>
							</Box>
							{content.image && (
								<Box round='small' overflow='hidden' height={{ max: "small" }}>
									<Image src={content.image.url} fit='cover' />
								</Box>
							)}
							<Box pad='small'>
								<Text>{content.content}</Text>
							</Box>
							<Box direction='row' justify='between'>
								<Box direction='row'>
									<Box direction='row' align='center' pad='small' gap='5px'>
										<Favorite color='red' /> <Text>{content.likes.length}</Text>
									</Box>
									<Box direction='row' align='center' pad='small' gap='5px'>
										<Chat color='#0d47a1' /> <Text>{content.likes.length}</Text>
									</Box>
								</Box>

								<Box direction='row' align='center' round pad='small' gap='5px'>
									<Button onClick={() => history.push(`/content/editcontent/${content.id}`)} icon={<Edit />} hoverIndicator round />
								</Box>
							</Box>
						</Box>
					))} */}
				</Box>
			</Box> 
			<Box basis='33%' round='small'>
				<Box pad='small'>
					<Heading color='gray' level='4' margin='none'>
						Últimos comentarios
					</Heading>
				</Box>
				<Box gap='medium' pad='small'>
					{/* {data.comments.map((comment, i) => (
						<Box key={i} round='small' background='white' elevation='medium'>
							<Box direction='row' gap='small' align='center' pad='small'>
								<Box round='100%' height='50px' width='50px' overflow='hidden'>
									<Image src={comment.user.image ? comment.user.image.url : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRKjyFdkTGcTZzPRrjooApHIHJGiBtTW9pgGfhJDE-B1Vg4Jf-i"} fit='cover' />
								</Box>
								<Text>{comment.user.username}</Text>
							</Box>
							<Box pad='small'>
								<Text>{comment.message}</Text>
							</Box>
						</Box>
					))} */}
				</Box>
			</Box>
		</Box>
	);
};

export default withRouter(Dashboard);
