import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, Image, TextInput, CheckBox, Select, DataTable, TextArea } from "grommet";
import { Bold, Sync, Task, Add, DocumentText, CirclePlay, Close, Trash, Gallery, CloudUpload, Music, Edit, Indicator, Search, Up, Down } from "grommet-icons";

import { Editor } from "@tinymce/tinymce-react";

import { withRouter, useParams } from "react-router";
import ContentLibrary from "../../components/ContentLibrary";
import ExamsLibrary from "../../components/ExamsLibrary";
import GoogleMapReact from "google-map-react";
import ScheduleModal from "../../components/ScheduleModal";

import moment from "moment";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MediaHandler from "../../components/MediaHandler";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import ForumLibrary from "../../components/ForumLibrary";
import { isArray } from "lodash-es";

const CourseForm = ({ history }) => {
	const { customization, tenant, userData } = useContext(AuthContext);

	const [loadingMedia, setLoadingMedia] = useState(false);
	const [publishModal, tooglePublishModal] = useState(false);
	const [contentsUsed, setContentsUsed] = useState([]);
	const [addContentModal, setAddContentModal] = useState({ status: false, index: 0 });
	const [aditionalMaterials, setAditionalMaterials] = useState(false);
	const [addExamModal, setAddExamModal] = useState({ status: false, index: 0 });
	const [createForumModal, setCreateForumModal] = useState(false);
	const [assignForumModal, setAssignForumModal] = useState(false);
	const [forum, setForum] = useState({});
	const [onCreateForum, setOnCreateForum] = useState({
		image: "",
		name: "",
		usergroups: [],
	});
	const [modalMedia, setModalMedia] = useState({ status: false, type: "images" });
	const [eventTimeModal, toogleEventTimeModal] = useState({ active: false, sectionIndex: null, contentIndex: null });

	const { id } = useParams();
	const [materials, setMaterials] = useState([]);
	const [image, setImage] = useState(null);
	const [sectionImages, setSectionImages] = useState([]);
	const [video, setVideo] = useState(null);
	const [groupCollapse, setGroupCollapse] = useState(false);

	const [richEditor, setRichEditor] = useState(null);
	const [teachers, setTeachers] = useState([]);
	const [courseData, setCourseData] = useState({
		name: "",
		image: "",
		contentDetails: [],
		coursePoints: 0,
		teacherRef: [],
		aditionalMaterials: [],
	});

	useEffect(() => {
		id === undefined && setRichEditor("");
		id !== undefined && getData();
	}, []);

	const getData = (cats, grps) => {
		firestore()
			.collection("users")
			.where("role", "==", "Teacher")
			.where("tenants", "array-contains", tenant.ref)
			.get()
			.then(async (doc) => {
				const t = doc.docs.map((teacher) => ({ ...teacher.data(), id: teacher.id, ref: teacher.ref }));
				setTeachers([...t]);
			});

		tenant.ref
			.collection("courses")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// SI HAY IMAGEN CONSIGUE LA URL PUBLICA
					let course;
					if (doc.data().courseRef) {
						course = await doc
							.data()
							.course.get()
							.then(async (res) => {
								const img = res.data().image;
								const image =
									img &&
									(await storage()
										.ref(img)
										.getDownloadURL()
										.then((url) => url));
							})
							.catch((err) => null);
					}
					if (doc.data().forumRef) {
						doc.data()
							.forumRef.get()
							.then(async (res) => {
								console.log(res.data());
								const img = res.data()?.image;
								const image = img
									? await storage()
											.ref(img)
											.getDownloadURL()
											.then((url) => url)
											.catch((err) => "")
									: "";
								setForum({ ...res.data(), id: res.id, ref: res.ref, image });
							});
					}
					const img = doc.data()?.image;
					img &&
						storage()
							.ref(img)
							.getDownloadURL()
							.then((url) => setImage(url));
					// console.log("HOLA 1")

					doc.data().aditionalMaterials &&
						Promise.all(
							doc.data().aditionalMaterials.map(async (material) => {
								const p = await material
									.get()
									.then((res) => res.data())
									.catch((err) => console.log(err));

								return p;
							})
						).then((res) => setMaterials(res));

					Promise.all(
						doc.data().contentDetails.map(async (section) => {
							const img = section.image;
							const url =
								img &&
								(await storage()
									.ref(img)
									.getDownloadURL()
									.then((url) => url));
							return { ...section, orgImage: section.image, image: url };
						})
					).then((res) => {
						setCourseData({
							...courseData,
							...doc.data(),
							contentDetails: res,
						});
						setSectionImages(res.map((el) => el.image || ""));

						setContentsUsed(
							doc
								.data()
								.contentDetails.map((section) => section.contents)
								.reduce((arr, currentValue) => [...arr, ...currentValue], [])
						);
						// console.log(doc.data().contentDetails.map(section => section.contents).reduce((arr, currentValue) => [...arr, ...currentValue]))
						setRichEditor(doc.data().description);
					});
				} else {
					console.log("No such document!");
				}
			})
			.catch(function (error) {
				console.log("Error getting document:", error);
			});
	};

	const publishCourse = async () => {
		let variables = {
			...courseData,
			name: courseData.name,
			description: richEditor,
			image: courseData.image,
			contentDetails: courseData?.contentDetails,
			forumRef: courseData?.forumRef || "",
			teacherRef: courseData?.teacherRef || [],
			coursePoints: parseInt(courseData?.coursePoints || 0),
			// advance: { ...courseData?.contentDetails?.map((section) => ({ ...section, contents: { ...section.contents } })) },
		};

		tenant.ref
			.collection("courses")
			.add(variables)
			.then((res) => {
				history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/courses`);
			})
			.catch((err) => console.log(err));
	};

	const editCourse = async () => {
		let variables = {
			...courseData,
			name: courseData?.name,
			description: richEditor,
			image: courseData?.image,
			coursePoints: parseInt(courseData?.coursePoints || 0),
			forumRef: courseData?.forumRef || "",
			teacherRef: courseData?.teacherRef || [],
			contentDetails: courseData?.contentDetails?.map((section) => {
				const fixed = section;
				section.orgImage = section?.orgImage;
				fixed.image = section?.orgImage || "";
				delete fixed?.orgImage;
				return { ...fixed };
			}),
			// advance: { ...courseData?.contentDetails?.map((section) => ({ ...section, contents: { ...section.contents } })) },
		};

		tenant.ref
			.collection("courses")
			.doc(id)
			.update(variables)
			.then((res) => {
				history.push(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/courses`);
			})
			.catch((err) => console.log(err));
	};

	const deleteSection = (sectionIndex) => {
		var newarr = courseData;
		setContentsUsed(contentsUsed.filter((el) => !newarr.contentDetails[sectionIndex].contents.map((c) => c.id).includes(el.id)));
		newarr.contentDetails.splice(sectionIndex, 1);
		setCourseData({ ...newarr });
	};

	const reorderSection = (sectionIndex, newSectionIndex) => {
		var newarr = courseData;
		const imgarr = sectionImages;
		const ai = imgarr[sectionIndex];
		const bi = imgarr[newSectionIndex];
		const a = newarr.contentDetails[sectionIndex];
		const b = newarr.contentDetails[newSectionIndex];
		imgarr[sectionIndex] = bi;
		imgarr[newSectionIndex] = ai;
		newarr.contentDetails[sectionIndex] = b;
		newarr.contentDetails[newSectionIndex] = a;
		setSectionImages([...imgarr]);
		setCourseData({ ...newarr });
	};

	const editSection = (index, input, field) => {
		var newarr = courseData;
		newarr.contentDetails[index][field] = input;
		setCourseData({ ...newarr });
	};

	const addContent = (sectionIndex, cb) => {
		console.log(cb)
		setContentsUsed([...contentsUsed, ...cb]);
		const newarr = courseData?.contentDetails;

		const newcontents = cb.map((el) => ({
			id: el.id,
			ref: el.ref,
			code: el.code,
			content: el.content ? el.content : null,
			media: el.media ? el.media : null,
			title: el.title ? el.title : null,
			contentType: el.contentType ? el.contentType : "EXAM",
			name: el.title ? el.title : el.name,
            code: el.code ? el.code : "",
			mandatory: false,
			startAfter: 0,
			image: el.image ? el.image : null,
			contentLength: el.contentType ? el.contentLength : el.advance.length,
			advance: el.advance ? el.advance : null,
			contentDetails: el.contentDetails ? el.contentDetails : null,
		}));
		newarr[sectionIndex].contents = [...newarr[sectionIndex].contents, ...newcontents];
		setCourseData({
			...courseData,
			contentDetails: newarr,
		});
	};

	const editContent = (sectionIndex, contentIndex, field, value) => {
		console.log(value);
		console.log("sectionIndex", eventTimeModal.sectionIndex, "contentIndex", eventTimeModal.contentIndex);
		const newarr = courseData?.contentDetails;
		!(field === "date" || field === "eventType") ? (newarr[sectionIndex].contents[contentIndex][field] = value) : (newarr[sectionIndex].contents[contentIndex].contentDetails[field] = value);
		setCourseData({
			...courseData,
			contentDetails: newarr,
		});
	};

	const deleteContent = (sectionIndex, contentID) => {
		setContentsUsed(contentsUsed.filter((c) => c.id !== contentID));
		const newarr = courseData.contentDetails;
		newarr[sectionIndex].contents = newarr[sectionIndex].contents.filter((el) => el.id !== contentID);
		setCourseData({
			...courseData,
			contentDetails: newarr,
		});
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const grid = 8;

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		borderRadius: 12,
		// padding: grid * 2,
		margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",

		// styles we need to apply on draggables
		...draggableStyle,
	});

	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "lightgrey",
		padding: grid,
		borderRadius: 12,
		width: "100%",
	});

	const onDragEnd = (result, sectionIndex) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(courseData.contentDetails[sectionIndex].contents, result.source.index, result.destination.index);

		const newarr = courseData.contentDetails;
		newarr[sectionIndex].contents = items;
		setCourseData({
			...courseData,
			contentDetails: newarr,
		});
	};

	const refreshData = (id, contentIndex, sectionIndex, type) => {
		tenant.ref
			.collection(type === "EXAM" ? "exams" : "smartcontents")
			.doc(id)
			.get()
			.then((doc) => {
				const newarr = courseData.contentDetails;
				const dat = {
					...newarr[sectionIndex].contents[contentIndex],
					id: doc.id,
					content: doc.data().content ? doc.data().content : null,
					media: doc.data().media ? doc.data().media : null,
					title: doc.data().title ? doc.data().title : null,
					contentType: doc.data().contentType ? doc.data().contentType : "EXAM",
					name: doc.data().title ? doc.data().title : doc.data().name,
					code: doc.data().code ? doc.data().code : "",
					mandatory: newarr[sectionIndex].contents[contentIndex].mandatory,
					startAfter: 0,
					image: doc.data().image ? doc.data()?.image : null,
					usergroups: doc.data().usergroups ? doc.data().usergroups : null,
					contentLength: doc.data().contentType ? doc.data().contentLength : doc.data().advance.length,
				};
				newarr[sectionIndex].contents[contentIndex] = { ...dat };

				setCourseData({
					...courseData,
					contentDetails: [...newarr],
				});
				// return doc.data()
			})
			.catch((err) => {
				// return
			});
	};

	const handleAssignModal = () => {
		setAssignForumModal((prevState) => !prevState);
	};

	const formatURL = (str) => {
		str = str.replace(/^\s+|\s+$/g, ""); // trim
		str = str.toLowerCase();
		// remove accents, swap ñ for n, etc
		let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
		let to = "aaaaeeeeiiiioooouuuunc------";
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
		}
		str = str
			.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
			.replace(/\s+/g, "-"); // collapse whitespace and replace by -

		return str;
	};

	const getCoordinates = async (address) => {
		// Primero geocoding, sino error
		let baseuri = formatURL(address);

		const coordenadas = await fetch("https://maps.google.com/maps/api/geocode/json?address=" + baseuri + "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I").then((data) => data.json());

		if (coordenadas.results.length > 0) {
			const coord = coordenadas.results[0].geometry.location;
			return { lat: coord.lat, lng: coord.lng };
		} else {
			alert("Error", "Dirección no encontrada.");
		}
	};

    // console.log(courseData)

	return (
		<Box>
			<Box direction="row-responsive" gap="medium">
				<Box basis="2/3" gap="medium">
					{/* TITULO DE LA PUBLICACIÓN */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color={customization.mainColor} level="4" margin="none">
								Título del Curso
							</Heading>
						</Box>

						<Box round="medium" pad={{ left: "small", right: "small" }}>
							{userData.role === "Admin" ? (
								<TextInput
									plain
									size="xlarge"
									placeholder="Escribe aquí..."
									value={courseData.name}
									onChange={(event) => {
										setCourseData({ ...courseData, name: event.target.value });
									}}
								/>
							) : (
								<Text style={{ marginBottom: 15 }} size="xlarge">
									{courseData.name}
								</Text>
							)}
						</Box>
					</Box>
					{/* FIN TITULO DE LA PUBLICACIÓN */}

					{/* CONTENIDO */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color={customization.mainColor} level="4" margin="none">
								Descripción del Curso
							</Heading>
						</Box>

						<Box pad="medium">
							{richEditor !== null && userData.role === "Admin" ? (
								<Editor
									initialValue={richEditor}
									init={{
										height: 300,
										branding: false,
										menubar: "file edit view insert format tools table tc help",
										plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime table paste code help wordcount"],
										toolbar: "undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help",
									}}
									onEditorChange={setRichEditor}
									apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
								/>
							) : (
								<div style={{ paddingTop: 0, paddingBottom: 0 }} dangerouslySetInnerHTML={{ __html: richEditor ? richEditor : "<p></p>" }} />
							)}
						</Box>
					</Box>
					{/* FIN CONTENIDO */}

					{/* SECCIONES CURSO */}
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small">
							<Heading color={customization.mainColor} level="4" margin="none">
								Secciones del Curso
							</Heading>
						</Box>
						<Box pad="small" gap="small">
							{courseData.contentDetails.length === 0 ? (
								<Box pad="small" round="small" background="light-2" align="center">
									<Text textAlign="center">Sin Secciones</Text>
								</Box>
							) : (
								courseData.contentDetails.map((section, i, arr) => {
									const sectionIndex = i;
									return (
										<Box key={i} pad="small" round="small" background="light-1">
											<Box direction="row" gap="small">
												<Box width="200px">
													<MediaHandler
														type="images"
														value={sectionImages[i]}
														height="200px"
														uploadCallback={(ev) => {
															editSection(i, ev, "image");
															editSection(i, ev, "orgImage");
														}}
														galleryCallback={(ev) => {
															editSection(i, ev.ref, "image");
															editSection(i, ev.ref, "orgImage");
														}}
														durationCallback={(ev) => console.log(ev)}
													/>
												</Box>
												<Box flex>
													<Box pad="small" direction="row" justify="between" gap="small">
														<TextInput style={{ background: "white" }} placeholder="Título de la Sección..." value={section.name} onChange={(ev) => editSection(i, ev.target.value, "name")} />
														{userData.role === "Admin" && (
															<Box direction="row" margin={{ right: "small" }}>
																<Button disabled={i === 0} hoverIndicator icon={<Up />} onClick={() => reorderSection(i, i - 1)} />
																<Button disabled={i === arr.length - 1} hoverIndicator icon={<Down />} onClick={() => reorderSection(i, i + 1)} />
																<Button hoverIndicator icon={<Trash />} onClick={() => deleteSection(i)} />
															</Box>
														)}
													</Box>
													<Box pad="small" gap="5px">
														<Text size="xsmall">Descripción</Text>
														<TextArea style={{ background: "white", minHeight: 160 }} resize="vertical" placeholder="Descripción de la Sección..." hoverIndicator value={section.description} size="small" onChange={(ev) => editSection(i, ev.target.value, "description")} />
													</Box>
													{/* <Box pad="small" gap="5px">
                                                        <Text size="xsmall">Activar tras</Text>
                                                        <TextInput size="small" placeholder="Activar tras X días..." value={section.delay || ""} onChange={(ev) => editSection(i, ev.target.value, "delay")} />
                                                    </Box> */}
												</Box>
											</Box>
											<Box gap="small" pad="small">
												<Box pad="small">
													<Heading color={customization.mainColor} level="4" margin="none">
														Contenidos del Módulo
													</Heading>
												</Box>
												{section.contents.length === 0 ? (
													<Box pad="small" round="small" background="light-2" align="center">
														<Text textAlign="center">Sin Contenidos</Text>
													</Box>
												) : (
													<DragDropContext onDragEnd={(ev) => onDragEnd(ev, sectionIndex)}>
														<Droppable droppableId="droppable">
															{(provided, snapshot) => (
																<Box {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
																	{section.contents.map((content, i) => (
																		<Draggable key={`content-${i}`} draggableId={`content-${i}`} index={i}>
																			{(provided, snapshot) => (
																				<Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
																					<Box justify="between" pad={{ vertical: "small" }} round="small">
																						<Box direction="row" gap="medium" justify="between" pad={{ left: "medium", bottom: "small" }}>
																							{/* <Box align="center" gap="7px" flex="grow" border={{ side: "right" }} width="80px">
																								<Text size="10px">Obligatorio</Text>
																								<CheckBox onChange={(ev) => editContent(sectionIndex, i, "mandatory", ev.target.checked)} checked={content.mandatory} />
																							</Box> */}
																							<Box direction="row" height={{ min: "50px" }} gap="small" align="center">
																								{content.contentType === "EXAM" ? <Task /> : content.contentType === "VIDEO" ? <CirclePlay /> : <DocumentText />}
																								<Text>{content.name}</Text>
																							</Box>

																							<Box direction="row" width={`${content.contentType === "WEBINAR" || content.contentType === "EVENT" ? "200" : "150"}`}>
																								{userData.role === "Admin" &&
																									(!(content.contentType === "WEBINAR" || content.contentType === "EVENT") ? null : <Button margin={{ right: "none" }} icon={<Add />} onClick={() => setGroupCollapse(!groupCollapse)} />)}
																								<Button hoverIndicator icon={<Edit />} onClick={() => window.open(`${process.env.PUBLIC_URL}/${tenant.url}/smartlearning/editcontent/${content.id}`, "_blank")} />
																								{userData.role === "Admin" && <Button hoverIndicator icon={<Sync />} onClick={() => refreshData(content.id, i, sectionIndex, content.contentType)} />}
																								{userData.role === "Admin" && <Button margin={{ right: "small" }} hoverIndicator icon={<Trash />} onClick={() => deleteContent(sectionIndex, content.id)} />}
																							</Box>
																						</Box>

																						{userData.role === "Admin" && (
																							<Box direction="row" flex pad={{ horizontal: "medium", top: "small" }} border={{ side: "top", size: "1px", color: "gainsboro" }} align="center" justify="between">
																								<Box direction="row" gap="small" align="center">
																									<CheckBox onChange={(ev) => editContent(sectionIndex, i, "mandatory", ev.target.checked)} checked={content.mandatory} />
																									<Text size="medium" style={{ marginRight: "10px" }}>
																										Obligatorio
																									</Text>
																								</Box>

																								<Box direction="row" gap="small" align="center">
																									<Text size="medium">Puntos</Text>
																									<TextInput style={{ height: "28px", maxWidth: "150px" }} size="xsmall" type="number" onChange={(ev) => editContent(sectionIndex, i, "sectionPoints", ev.target.value)} value={content.sectionPoints} />
																								</Box>
																							</Box>
																						)}
																					</Box>
																					{/* COLLAPSIBLE DE WEBINARS && EVENTS */}
																					<Collapsible open={!groupCollapse && (content.contentType === "WEBINAR" || content.contentType === "EVENT")}>
																						<Box gap="small" pad={{ bottom: "medium" }} width="100%">
																							<Box style={{ alignSelf: "center", width: "90%" }}>
																								<Heading color="titles" level="4" margin="none" style={{ marginBottom: 10 }}>
																									Link del evento
																								</Heading>
																								<TextInput style={{ height: "28px", maxWidth: "100%" }} size="xsmall" type="text" onChange={(ev) => editContent(sectionIndex, i, "link", ev.target.value)} value={content.link} />
																							</Box>
																							<Box style={{ alignSelf: "center", width: "90%" }} onClick={() => toogleEventTimeModal({ ...eventTimeModal, sectionIndex, contentIndex: i, active: true })}>
																								<Heading color="titles" level="4" margin="none" style={{ marginBottom: 10 }}>
																									Fecha del evento
																								</Heading>
																								<TextInput
																									style={{ height: "28px", maxWidth: "100%" }}
																									size="xsmall"
																									onChange={(ev) => editContent(sectionIndex, i, "date", ev.target.value)}
																									value={moment(content.contentDetails?.date).format("DD-MM-YYYY [a las] hh:mm")}
																								/>
																							</Box>
																							{userData.role === "Admin" && (
																								<Box style={{ alignSelf: "center", width: "90%" }}>
																									<Box margin={{ bottom: "10px" }}>
																										<Heading color="titles" level="4" margin="none">
																											Tipo de Evento
																										</Heading>
																									</Box>
																									<Box direction="row" gap="small" justify="start" style={{ marginBottom: 10, alignSelf: "center" }}>
																										<Button onClick={() => editContent(sectionIndex, i, "eventType", "ON")} label="Evento Presencial" />
																										<Button onClick={() => editContent(sectionIndex, i, "eventType", "OFF")} label="Evento Digital" />
																									</Box>
																								</Box>
																							)}
																						</Box>
																						<Collapsible direction="vertical" open={content.contentDetails?.eventType === "ON"}>
																							<Box>
																								<Box margin={{ bottom: "10px" }} style={{ alignSelf: "center", width: "90%" }}>
																									<Heading color="titles" level="4" margin="none">
																										Lugar del evento
																									</Heading>
																								</Box>
																								<Box>
																									<Box direction="row" align="center" border="all" borderRadius="small">
																										<TextInput plain onChange={(event) => console.log("setting address")} placeholder={`Dirección...`} value={content.contentDetails?.address || ""} />
																										<Button hoverIndicator onClick={() => getCoordinates(content.contentDetails?.address).then((res) => console.log())} icon={<Search />} />
																									</Box>
																									<Box height="250px" width="100%" round="small">
																										<GoogleMapReact
																											bootstrapURLKeys={{ key: "AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I" }}
																											defaultCenter={{
																												lat: 40.4165,
																												lng: -3.70256,
																											}}
																											center={{
																												lat: content.contentDetails?.location?.lat ? content.contentDetails?.location?.lat : 40.4165,
																												lng: content.contentDetails?.location?.lng ? content.contentDetails?.location?.lng : -3.70256,
																											}}
																											defaultZoom={7}
																											zoom={content.contentDetails?.location?.lat ? 20 : 5}>
																											{content.contentDetails?.location?.lat && (
																												<Indicator lat={content.contentDetails?.location?.lat} lng={content.contentDetails?.location?.lng} style={{ position: "absolute", transform: "translate(-50%, -50%)" }} />
																											)}
																										</GoogleMapReact>
																									</Box>
																								</Box>
																							</Box>
																						</Collapsible>
																					</Collapsible>
																					{content.contentType === "EXAM" && (
																						<Box pad={{ vertical: "small", horizontal: "medium" }} gap="small" background="light-2" round={{ size: "small", corner: "bottom" }} direction="row" border={{ side: "top" }}>
																							<CheckBox label="Mostrar Resultados" onChange={(ev) => editContent(sectionIndex, i, "showResults", ev.target.checked)} checked={content.showResults} />
																							<Box flex>
																								<TextInput
																									placeholder="Porcentaje para Pasar"
																									value={content.percentageToPass}
																									onChange={(ev) => {
																										editContent(sectionIndex, i, "percentageToPass", ev.target.value);
																									}}
																								/>
																							</Box>
																							{content.showResults && (
																								<Box flex>
																									<TextInput
																										placeholder="Intentos Máximos"
																										value={content.maxTries}
																										onChange={(ev) => {
																											editContent(sectionIndex, i, "maxTries", ev.target.value);
																										}}
																									/>
																								</Box>
																							)}
																						</Box>
																					)}
																				</Box>
																			)}
																		</Draggable>
																	))}
																	{provided.placeholder}
																</Box>
															)}
														</Droppable>
													</DragDropContext>
												)}
											</Box>
											{userData.role === "Admin" && (
												<Box justify="center" direction="row" gap="small" pad={{ horizontal: "small" }}>
													<Button icon={<Add />} primary onClick={() => setAddContentModal({ status: true, index: i })} label="Añadir Contenidos" />
													<Button icon={<Add />} primary onClick={() => setAddExamModal({ status: true, index: i })} label="Añadir Examen" />
												</Box>
											)}
										</Box>
									);
								})
							)}
						</Box>
						{userData.role === "Admin" && (
							<Box align="center" margin="small">
								<Button
									onClick={() =>
										setCourseData({
											...courseData,
											contentDetails: [
												...courseData.contentDetails,
												{
													name: "",
													description: "",
													type: "SECTION",
													image: "",
													startAfter: "",
													contents: [],
												},
											],
										})
									}
									label="Añadir Sección"
								/>
							</Box>
						)}
					</Box>
					{/* FIN SECCIONES CURSO§ */}
				</Box>

				<Box basis="1/3" gap="medium">
					{/* BOTONES PUBLICACION */}
					{userData.role === "Admin" && (
						<Box background="white" overflow="hidden" round="small" elevation="medium">
							<Box pad="small">
								<Heading color={customization.mainColor} level="4" margin="none">
									Publicación
								</Heading>
							</Box>

							<Box pad="small" gap="small">
								<Button disabled={courseData.name !== "" ? false : true} primary label={id ? "Guardar Cambios" : "Crear Curso"} onClick={() => tooglePublishModal(true)} />
							</Box>
						</Box>
					)}
					{/* FIN BOTONES PUBLICACION */}

					{/* IMAGEN DESTACADA */}
					<Box background="white" overflow="hidden" pad="small" round="small" elevation="medium">
						<MediaHandler type="images" value={image} height="300px" title="Imagen Destacada" uploadCallback={(ev) => setCourseData({ ...courseData, image: ev })} galleryCallback={(ev) => setCourseData({ ...courseData, image: ev.ref })} durationCallback={(ev) => console.log(ev)} />
					</Box>
					{/* FIN IMAGEN DESTACADA */}
					{/* Foro asociado al curso */}
					<Box background="white" overflow="hidden" round="small" elevation="medium" pad="small">
						<Box margin={{ vertical: "small" }}>
							<Heading color={customization.mainColor} level="4" margin="none">
								Foro del curso
							</Heading>
						</Box>

						<Box>
							{!courseData.forumRef ? (
								<Box pad="small" background="#f0f0f0" round="small">
									<Text>Sin foro asignado al curso</Text>
								</Box>
							) : (
								<Box direction="row" gap="small" margin={{ vertical: "small" }}>
									{!forum?.image ? null : (
										<Box background="light-3" border="all" height="40px" width="40px" round="full" overflow="hidden">
											<Image fit="cover" src={forum?.image} />
										</Box>
									)}
									<Box justify="center">
										<Heading level="4" margin="none">
											{forum?.name}
										</Heading>
									</Box>
								</Box>
							)}
							{userData.role === "Admin" && (
								<Box direction="row" gap="small" margin={{ vertical: "small" }}>
									<Button primary style={{ flex: 1 }} label="Asociar foro" onClick={() => handleAssignModal()} />
									<Button primary style={{ flex: 1 }} label="Crear foro" onClick={() => setCreateForumModal((prevState) => !prevState)} />
								</Box>
							)}
						</Box>
					</Box>
					{/* Fin foro asociado al curso */}

					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small" justify="between">
							<Heading color={customization.mainColor} level="4" margin="none" basis="1/2">
								Tutores del Curso
							</Heading>
						</Box>

						<Box pad="small" gap="small">
							{userData.role === "Admin" && (
								<Select
									placeholder="Selecciona el tutor"
									options={teachers}
									labelKey={"name"}
									valueKey={{ key: "ref", reduce: true }}
									multiple={true}
									onChange={({ value }) => {
										console.log(value);
										setCourseData({ ...courseData, teacherRef: value });
										// handleSelectedTeacher(e);
									}}
								/>
							)}
							{courseData?.teacherRef &&
								courseData?.teacherRef !== "" &&
								isArray(courseData?.teacherRef) &&
								courseData?.teacherRef?.map((u, i) => {
									return (
										<Box key={i} align="center" direction="row" pad="small" background="#f3f3f3" round="small">
											<Box flex>
												<Text key={i}>
													{teachers.find((el) => el.id === u.id)?.name} {teachers.find((el) => el.id === u.id)?.surname}
												</Text>
											</Box>
											{userData.role === "Admin" && <Button onClick={() => setCourseData({ ...courseData, teacherRef: courseData.teacherRef.filter((el) => el.id !== u.id) })} icon={<Trash />} />}
										</Box>
									);
								})}
						</Box>
					</Box>
					<Box background="white" overflow="hidden" round="small" elevation="medium">
						<Box pad="small" justify="between">
							<Heading color={customization.mainColor} level="4" margin="none" basis="1/2">
								Materiales Adicionales
							</Heading>
							<Box background="#f0f0f0" pad="small" round="small" margin={{ top: "small" }} gap="small">
								{materials.length === 0 ? (
									<Text>Sin Contenidos Adicionales</Text>
								) : (
									materials.map((material, i) => {
										return (
											<Box key={i} direction="row" align="center" background="white" pad={{ left: "small", vertical: "small" }}>
												<Box flex>
													<Text size="small">{material.title}</Text>
												</Box>
												{userData.role === "Admin" && (
													<Button
														hoverIndicator
														icon={<Trash />}
														onClick={() => {
															{
																console.log(materials);
																const d = courseData.aditionalMaterials.filter((el) => el !== material);
																const p = materials.filter((el) => el.id !== material.id);
																setMaterials(p);
																setCourseData({ ...courseData, aditionalMaterials: d });
															}
														}}
													/>
												)}
											</Box>
										);
									})
								)}
							</Box>
							{/* <Heading level="4" margin="none" basis="1/2">
								{teachers.find((teacher) => teacher.ref === courseData?.teacherRef)?.name} {teachers.find((teacher) => teacher.ref === courseData?.teacherRef)?.surname}
							</Heading> */}
						</Box>
						{userData.role === "Admin" && (
							<Box pad="small">
								<Button primary style={{ flex: 1 }} label="Añadir Materiales" onClick={() => setAditionalMaterials(true)} />
							</Box>
						)}
					</Box>
					
					
					{/* PUNTOS DEL CURSO */}
					{userData.role === "Admin" && (
						<Box background="white" overflow="hidden" pad="medium" gap="medium" round="small" elevation="medium">
							<Box>
								<Heading color="titles" level="4" margin="none">
									Puntos del curso
								</Heading>
							</Box>

							<Box>
								<TextInput
									placeholder="Escribe aquí..."
									type="number"
									value={courseData.coursePoints}
									onChange={(e) => {
										setCourseData({ ...courseData, coursePoints: e.target.value });
									}}
								/>
							</Box>
						</Box>
					)}
					{/* FIN PUNTOS DEL CURSO */}
				</Box>
			</Box>
			{/* MODALES */}

			{/* MODALES ASIGNAR Y CREAR FOROS */}
			{/* MODAL CREAR CURSO */}
			{createForumModal && (
				<Layer full="vertical" position="left" onEsc={() => setCreateForumModal((prevState) => !prevState)} onClickOutside={() => setCreateForumModal((prevState) => !prevState)}>
					<Box width={{ min: "large" }}>
						<Box pad="small" height="80px" background="#f2f2f2" justify="between" direction="row" align="center">
							<Box direction="row" gap="small" pad="small">
								<Button label="Cancelar" onClick={() => setCreateForumModal((prevState) => !prevState)} />
								<Button primary label="Añadir" onClick={() => {}} />
							</Box>
						</Box>
						<Box direction="row" gap="medium" pad="medium">
							<Box gap="5px" flex>
								<Text pad="small" size="xsmall">
									Nombre del foro
								</Text>
								<TextInput placeholder="Escribe el nombre del foro..." onChange={(e) => setOnCreateForum({ ...onCreateForum, name: e.target.value })} value={onCreateForum.name} />
								<Box background="white" overflow="hidden" pad="small" justify="center" round="small" elevation="medium" alignContent="center">
									<MediaHandler
										type="images"
										value={onCreateForum.image || ""}
										height="300px"
										width="150px"
										title="Imagen del foro"
										uploadCallback={(ev) => setOnCreateForum({ ...onCreateForum, image: ev })}
										galleryCallback={(ev) => setCourseData({ ...onCreateForum, image: ev.ref })}
										durationCallback={(ev) => console.log(ev)}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Layer>
			)}
			{/* FIN MODAL CREAR CURSO */}

			{/* MODAL ASIGNAR CURSO */}
			{assignForumModal && (
				<ForumLibrary
					closeCallback={() => setAssignForumModal(false)}
					submitCallback={(cb) => {
						setForum(cb[0]);
						cb[0] && setCourseData({ ...courseData, forumRef: cb[0].ref });
						setAssignForumModal(false);
					}}
				/>
			)}

			{/* FIN MODAL ASIGNAR CURSO */}

			{/* FIN MODALES ASIGNAR Y CREAR FOROS */}

			{/* MODAL ASIGNAR WEBINAR/EVENT DATE */}

			{eventTimeModal.active && (
				<ScheduleModal
					prevDate={courseData?.contentDetails[eventTimeModal?.sectionIndex]?.contents[eventTimeModal.contentIndex]?.contentDetails?.date}
					cancelCallback={() => {
						toogleEventTimeModal(!eventTimeModal);
					}}
					dateCallback={(ev) => {
						editContent(eventTimeModal?.sectionIndex, eventTimeModal?.contentIndex, "date", ev);
						toogleEventTimeModal(!eventTimeModal);
					}}
				/>
			)}

			{/* FIN MODAL ASIGNAR WEBINAR/EVENT DATE */}

			{publishModal && (
				<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
					<Box width="medium" pad="medium" gap="medium" align="center" justify="center">
						<Heading level="3" margin="none">
							{id ? "Actualizar" : "Publicar"} Curso
						</Heading>
						<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este Curso, ¿estás seguro que deseas hacerlo?</Text>

						<Box direction="row" gap="small" width="100%" justify="between">
							<Button label="Cancelar" onClick={() => tooglePublishModal(!publishModal)} />
							<Button
								primary
								label={id ? "Actualizar" : "Publicar"}
								onClick={() => {
									tooglePublishModal(!publishModal);
									id ? editCourse() : publishCourse();
								}}
							/>
						</Box>
					</Box>
				</Layer>
			)}

			{addContentModal.status && (
				<ContentLibrary
					closeCallback={() => setAddContentModal({ ...addContentModal, status: false })}
					contentsUsed={contentsUsed}
					submitCallback={(cb) => {
						addContent(addContentModal.index, cb);
						setAddContentModal({ ...addContentModal, status: false });
					}}
				/>
			)}

			{aditionalMaterials && (
				<ContentLibrary
					closeCallback={() => setAditionalMaterials(false)}
					contentsUsed={materials}
					submitCallback={(cb) => {
						const d = cb.map((el) => el.ref);
						setMaterials([...materials, ...cb]);
						setCourseData({ ...courseData, aditionalMaterials: [...courseData.aditionalMaterials, ...d] });
						setAditionalMaterials(false);
					}}
				/>
			)}

			{addExamModal.status && (
				<ExamsLibrary
					closeCallback={() => setAddExamModal({ ...addExamModal, status: false })}
					submitCallback={(cb) => {
						addContent(addExamModal.index, cb);
						setAddExamModal({ ...addExamModal, status: false });
					}}
				/>
			)}

			{/* FIN MODALES */}
		</Box>
	);
};

export default withRouter(CourseForm);
