import React, { useState, useRef, useEffect, useContext } from "react";
import { Box, Button, Collapsible, Heading, Text, Layer, TextInput, CheckBox, Select } from "grommet";
import { Bold, CirclePlay, Chat, DocumentPdf, Task, Music, Calendar, Youtube, Link, Search, Indicator, Upload, Video, BlockQuote, CloudComputer, Monitor } from "grommet-icons";

import ReactPlayer from "react-player";

import { htmlToText } from "html-to-text";

import ScheduleModal from "../../components/ScheduleModal";

import moment from "moment";

import { firestore } from "firebase";

import { withRouter, useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import CreateNotificationData from "../../utils/CreateNotificationData";
import GoogleMapReact from "google-map-react";
import QuestionGenerator from "../../components/QuestionGenerator";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import ButtonField from "../../components/FormsComponents/ButtonField";
import TitleField from "../../components/FormsComponents/TitleField";
import RichEditorField from "../../components/FormsComponents/RichEditorField";
import TextField from "../../components/FormsComponents/TextField";
import CheckboxField from "../../components/FormsComponents/CheckBoxField";
import MediaField from "../../components/FormsComponents/MediaField";
import SelectField from "../../components/FormsComponents/SelectField";
import TopicLibrary from "../../components/TopicLibrary";

const SmartContentForm = ({ history }) => {
	const [publishModal, tooglePublishModal] = useState(false);
	const [forumModal, toogleForumModal] = useState(false);
	const [eventTimeModal, toogleEventTimeModal] = useState(false);
	const [topic, setTopic] = useState(null);

	const { id } = useParams();
	const { userData, tenant, customization } = useContext(AuthContext);

	const [richEditor, setRichEditor] = useState(null);
	const [data, setData] = useState({
		title: "",
		content: "",
		publishDate: "",
		updatedAt: "",
		code: "",
		link: "",
		image: "",
		media: "",
		contentDetails: {},
		contentType: "TEXT",
		notification: false,
		featured: false,
		public: false,
		draft: true,
		postSchedule: false,
		likesCount: 0,
		commentsCount: 0,
		contentLength: 0,
		user: userData.ref,
		score: 0,
		usergroups: [],
		categories: [],
	});

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		id && getData();
		!id && setLoading(false);
		id === undefined && setRichEditor("");
	}, []);

	const getData = () => {
		tenant.ref
			.collection("smartcontents")
			.doc(id)
			.get()
			.then(async (doc) => {
				if (doc.exists) {
					// COGE LAS CATEGORIAS DEL POST Y LAS FILTRA CON TODAS

					setLoading(false);
					console.log(doc.data())
					if (doc.data().contentDetails?.topicRef) {
						doc.data()
							.contentDetails.topicRef.get()
							.then((res) => setTopic(res.data()))
							.catch((err) => null);
					}
					setData({
						...data,
						...doc.data(),
						publishDate: doc.data().publishDate?.toDate(),
					});

					setRichEditor(doc.data().content);
				} else {
					console.log("No such document!");
					setLoading(false);
				}
			})
			.catch(function (error) {
				setLoading(false);
				console.log("Error getting document:", error);
			});
	};

	const postContent = async (values) => {
		console.log(values)
		setLoading(true);
		let variables = {
			...values,
			publishDate: values.publishDate === "" ? firestore.FieldValue.serverTimestamp() : firestore.Timestamp.fromDate(moment(values.publishDate).toDate()),
			contentLength: parseInt(values.contentLength),
			published: !values.draft ? (values.postSchedule ? false : true) : false,
		};

		if (id) {
			tenant.ref
				.collection("smartcontents")
				.doc(id)
				.update(variables)
				.then((res) => {
					setLoading(false);
					history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/library`);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		} else {
			tenant.ref
				.collection("smartcontents")
				.add(variables)
				.then((res) => {
					setLoading(false);
					history.replace(`${process.env.PUBLIC_URL + "/" + tenant.url}/smartlearning/library`);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
	};

	const formatURL = (str) => {
		str = str.replace(/^\s+|\s+$/g, ""); // trim
		str = str.toLowerCase();
		// remove accents, swap ñ for n, etc
		let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
		let to = "aaaaeeeeiiiioooouuuunc------";
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
		}
		str = str
			.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
			.replace(/\s+/g, "-"); // collapse whitespace and replace by -

		return str;
	};

	const getCoordinates = async (address) => {
		// Primero geocoding, sino error
		let baseuri = formatURL(address);

		const coordenadas = await fetch("https://maps.google.com/maps/api/geocode/json?address=" + baseuri + "&key=AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I").then((data) => data.json());

		if (coordenadas.results.length > 0) {
			const coord = coordenadas.results[0].geometry.location;
			return { lat: coord.lat, lng: coord.lng };
		} else {
			alert("Error", "Dirección no encontrada.");
		}
	};

	const FormSchema = Yup.object().shape({
		title: Yup.string().min(2, "Too Short!").required("Required"),
		// email: Yup.string().email("Invalid email").required("Required"),
	});

	if (loading) return null;

	return (
		<Box>
			<Formik
				initialValues={data}
				enableReinitialize={true}
				validationSchema={FormSchema}
				// validate={(values) => console.log(values)}
				onSubmit={(values) => postContent(values)}>
				{({ values, setFieldValue, setValues, submitForm }) => (
					<Form>
						<Box direction="row-responsive" gap="medium">
							<Box basis="10%" gap="medium">
								{/* TIPO DE CONTENIDO */}
								<Box background="white" round="small" pad="medium" gap="medium" elevation="medium">
									<Heading color={customization.mainColor} level="4" margin="none">
										Tipo
									</Heading>

									<Box gap="small">
										<ButtonField name="contentType" icon={<Bold />} label="Lectura" type="TEXT" />
										<ButtonField name="contentType" icon={<Link />} label="Enlace" type="LINK" />
										<ButtonField name="contentType" icon={<CirclePlay />} label="Video" type="VIDEO" />
										<ButtonField name="contentType" icon={<Youtube />} label="Youtube" type="EMBEDDED" />
										<ButtonField name="contentType" icon={<Music />} label="Audio" type="AUDIO" />
										<ButtonField name="contentType" icon={<DocumentPdf />} label="Documento" type="DOCUMENT" />
										<ButtonField name="contentType" icon={<Calendar />} label="Evento" type="EVENT" />
										<ButtonField name="contentType" icon={<Video />} label="Webinar" type="WEBINAR" />
										<ButtonField name="contentType" icon={<Monitor />} label="Scorm / Embebido" type="SCORM" />
										{/* <ButtonField name="contentType" icon={<BlockQuote />} label="Participar en Foro" type="FORUM" /> */}
										{/* <ButtonField name="contentType" icon={<CloudComputer />} label="Código" type="CODE" /> */}
										{/* <ButtonField name="contentType" icon={<Task />} label="Encuesta" type="TEST" /> */}
										<ButtonField name="contentType" icon={<Upload />} label="Subir" type="UPLOAD" />
									</Box>
								</Box>
								{/* FIN TIPO DE CONTENIDO */}
							</Box>

							<Box basis="60%" gap="medium">
								{/* TITULO DE LA PUBLICACIÓN */}
								<TitleField label="Título de la Publicación" placeholder="Escribe aquí..." name="title" />
								{/* FIN TITULO DE LA PUBLICACIÓN */}

								{/* CONTENIDO */}
								<Box background="white" gap="medium" round="small" pad="medium" elevation="medium">
									<Box>
										<Box margin={{ bottom: values.contentType !== "TEXT" ? "24px" : "0px" }}>
											<Collapsible open={values.contentType === "VIDEO"}>
												<MediaField type="videos" name="media" height="350px" label="Video" />
											</Collapsible>

											<Collapsible open={values.contentType === "AUDIO"}>
												<MediaField type="audios" name="media" height="50px" label="Audio" />
											</Collapsible>

											<Collapsible open={values.contentType === "LINK" }>
												<TextField name="link" label="Enlace" placeholder="Enlace..." />
											</Collapsible>

											<Collapsible open={values.contentType === "CODE" || values.contentType === "SCORM"}>
												<TextField name="code" label="Código Embebido" placeholder="Código a embeber..." />
											</Collapsible>

											<Collapsible open={values.contentType === "FORUM"}>
												{topic ? (
													<Box gap="small">
														<Heading margin="0px" level="3">
															Título del Hilo
														</Heading>
														<Box pad="medium" background="#f2f2f2" gap="medium" round="small">
															<Text weight="bold" size="large">
																{topic.title}
															</Text>
															<Box direction="row" gap="small">
																<Chat size="20px" />
																<Text size="18px">{topic.responses} Respuestas</Text>
															</Box>
														</Box>
													</Box>
												) : (
													<Box background="#f3f3f3" pad="medium" round="small" height="small" align="center" justify="center">
														Sin Hilo Seleccionado
													</Box>
												)}
												<Button primary margin={{ top: "small" }} label="Seleccionar Hilo" onClick={() => toogleForumModal(true)} />
												{/* <TextField name="link" label="Enlace" placeholder="Foro..." /> */}
											</Collapsible>

											<Collapsible open={values.contentType === "EVENT"}>
												<Box gap="small">
													<TextField name="link" label="Enlace al Evento" placeholder="Enlace..." />

													<Box flex onClick={() => toogleEventTimeModal(true)}>
														<TextField value={moment(values.contentDetails?.date).format("DD-MM-YYYY [a las] hh:mm")} name="contentDetails.date" label="Fecha del Evento" placeholder="DD/MM/HHH" />
													</Box>

													<Box>
														<Box margin={{ bottom: "10px" }}>
															<Heading color="titles" level="4" margin="none">
																Tipo de Evento
															</Heading>
														</Box>
														<Box direction="row" gap="small" justify="start">
															<Button primary={values.contentDetails?.eventType === "OFF"} onClick={() => setFieldValue("contentDetails.eventType", "OFF")} label="Evento Presencial" />
															<Button primary={values.contentDetails?.eventType === "ON"} onClick={() => setFieldValue("contentDetails.eventType", "ON")} label="Evento Digital" />
														</Box>
													</Box>

													<Collapsible direction="vertical" open={values.contentDetails?.eventType === "OFF"}>
														<Box>
															<Box margin={{ bottom: "10px" }}>
																<Heading color="titles" level="4" margin="none">
																	Lugar del evento
																</Heading>
															</Box>
															<Box>
																<Box direction="row" align="center" border="all" borderRadius="small">
																	<TextInput plain onChange={(event) => setFieldValue("contentDetails.address", event.target.value)} placeholder={`Dirección...`} value={values.contentDetails?.address || ""} />
																	<Button hoverIndicator onClick={() => getCoordinates(values.contentDetails?.address).then((res) => setFieldValue("contentDetails.location", res))} icon={<Search />} />
																</Box>
																<Box height="250px" width="100%" round="small">
																	<GoogleMapReact
																		bootstrapURLKeys={{ key: "AIzaSyC6Umm0WckCCFOWjff7TLvU4UHmhnxqd7I" }}
																		defaultCenter={{
																			lat: 40.4165,
																			lng: -3.70256,
																		}}
																		center={{
																			lat: values.contentDetails?.location?.lat ? values.contentDetails?.location?.lat : 40.4165,
																			lng: values.contentDetails?.location?.lng ? values.contentDetails?.location?.lng : -3.70256,
																		}}
																		defaultZoom={7}
																		zoom={values.contentDetails?.location?.lat ? 20 : 5}>
																		{values.contentDetails?.location?.lat && <Indicator lat={values.contentDetails?.location?.lat} lng={values.contentDetails?.location?.lng} style={{ position: "absolute", transform: "translate(-50%, -50%)" }} />}
																	</GoogleMapReact>
																</Box>
															</Box>
														</Box>
													</Collapsible>
												</Box>
											</Collapsible>

											<Collapsible open={values.contentType === "WEBINAR"}>
												<Box gap="small">
													<TextField name="link" label="Enlace la Sesión" placeholder="Enlace..." />
													<Box flex onClick={() => toogleEventTimeModal(true)}>
														<TextField value={moment(values.contentDetails?.date).format("DD-MM-YYYY [a las] hh:mm")} name="contentDetails.date" label="Fecha de la Sesión" placeholder="DD/MM/HHH" />
													</Box>
												</Box>
											</Collapsible>

											<Collapsible open={values.contentType === "UPLOAD"}>
												<Box margin={{ bottom: "25px" }}>
													<Heading color={customization.mainColor} level="4" margin="none">
														Tipo de Contenido a Cargar
													</Heading>
												</Box>
												<Box gap="small" margin={{ bottom: values.contentDetails?.materials ? "25px" : "0px" }}>
													<CheckBox checked={values.contentDetails.materials} onChange={(event) => setFieldValue("contentDetails.materials", event.target.checked)} label="Añadir Material Adicional" />
													<CheckBox checked={values.contentDetails.video} onChange={(event) => setFieldValue("contentDetails.video", event.target.checked)} label="Cargar un Video" />
												</Box>
											</Collapsible>

											<Collapsible open={values.contentType === "DOCUMENT" || (values.contentType === "UPLOAD" && values.contentDetails?.materials)}>
												<MediaField type="documents" name="media" height="450px" label="Documento" />
											</Collapsible>

											<Collapsible open={values.contentType === "EMBEDDED"}>
												<TextField name="embedded" label="Enlace al video" placeholder="Enlace..." />
												<Box>
													{values?.embedded && (
														<Box background="gainsboro">
															<ReactPlayer width="100%	" controls={true} url={values?.embedded} />
															{/* <Text>Si no está viendo el vídeo, el enlace no es correcto</Text> */}
														</Box>
													)}
												</Box>
											</Collapsible>

											<Collapsible open={values.contentType === "TEST"}>
												<Box>
													<QuestionGenerator
														points
														// allowDeleteQuestion
														withFeedback={values?.contentDetails?.withFeedback}
														withFeedbackCallback={(event) => setFieldValue("contentDetails.withFeedback", event.target.checked)}
														openAnswer={values?.contentDetails?.openAnswer}
														withOpenAnswerCallback={(event) => setFieldValue("contentDetails.openAnswer", event.target.checked)}
														openAnswerFeedback={values?.contentDetails?.openAnswerFeedback}
														openAnswerFeedbackCallback={(event) => setFieldValue("contentDetails.openAnswerFeedback", event)}
														multipleAnswer={values?.contentDetails?.multipleAnswer}
														multipleAnswerCallback={(event) => setFieldValue("contentDetails.multipleAnswer", event.target.checked)}
														value={values?.contentDetails}
														answersCallback={(ev) => setFieldValue("contentDetails.answers", ev)}
														question={values.contentDetails?.question}
														questionCallback={(event) => setFieldValue("contentDetails.question", event.target.value)}
													/>
												</Box>
											</Collapsible>
										</Box>

										{richEditor !== null && <RichEditorField id="content" name="content" label="Contenido" />}
									</Box>
								</Box>
								{/* FIN CONTENIDO */}

								<Box direction="row" gap="small">
									{/* DURACION DEL CONTENIDO */}
									<Box background="white" flex round="small" gap="medium" pad="medium" elevation="medium">
										<TextField name="contentLength" label="Duración del Contenido" type="number" moreInfo="Esta será la duración del contenido en minutos" />
									</Box>

									{/* FIN DURACION DEL CONTENIDO */}

									{/* PUNTOS DE LA MISION */}
									{/* <Box background="white" flex round="small" gap="medium" pad="medium" elevation="medium">
										<TextField name="score" label="Puntos del Contenido" type="number" moreInfo="Estos puntos se asignarán una vez completado el contenido" />
									</Box> */}
									{/* FIN PUNTOS DE LA MISION */}
								</Box>
							</Box>

							<Box basis="30%" gap="medium">
								<Box background="white" pad="medium" gap="medium" round="small" elevation="medium">
									{/* BOTONES PUBLICACION */}

									<Box>
										<Heading color={customization.mainColor} level="4" margin="none">
											Publicación
										</Heading>
									</Box>

									<Box gap="small">
										<Button
											disabled={loading}
											primary
											type="submit"
											label={id ? "Actualizar Contenido" : "Crear Contenido"}
											// onClick={() => tooglePublishModal(true)}
										/>
									</Box>
								</Box>
								{/* FIN BOTONES PUBLICACION */}

								{/* IMAGEN DESTACADA */}
								<Box background="white" round="small" pad="medium" gap="medium" elevation="medium">
									<MediaField name="image" type="images" height="250px" label="Imagen Destacada" />
								</Box>
								{/* FIN IMAGEN DESTACADA */}
							</Box>
						</Box>

						{/* MODALES */}

						{forumModal && (
							<TopicLibrary
								closeCallback={() => toogleForumModal(false)}
								single
								submitCallback={(cb) => {
									// setForum(cb[0]);
									console.log(cb[0]);
									setTopic(cb[0]);
									cb[0] && setFieldValue("contentDetails.topicRef", cb[0].ref);
									// cb[0] && setCourseData({ ...courseData, forumRef: cb[0].ref });
									toogleForumModal(false);
								}}
							/>
						)}

						{eventTimeModal && (
							<ScheduleModal
								prevDate={values.contentDetails?.date}
								// limitInit={!data.draft}
								cancelCallback={() => {
									toogleEventTimeModal(!eventTimeModal);
									// console.log(data.postSchedule)
								}}
								dateCallback={(ev) => {
									setValues({ ...values, contentDetails: { ...values.contentDetails, date: ev } });
									toogleEventTimeModal(!eventTimeModal);
								}}
							/>
						)}

						{publishModal && (
							<Layer onEsc={() => tooglePublishModal(!publishModal)} onClickOutside={() => tooglePublishModal(!publishModal)}>
								<Box width="medium" pad="medium" gap="medium" align="center" justify="center">
									<Heading level="3" margin="none">
										{id ? "Actualizar" : "Publicar"} contenido
									</Heading>
									<Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este contenido, ¿estás seguro que deseas hacerlo?</Text>
									<Box direction="row" gap="small" justify="between" width="100%">
										<Button label="Cancelar" onClick={() => tooglePublishModal(!publishModal)} />
										<Button
											primary
											label={id ? "Actualizar" : "Publicar"}
											onClick={() => {
												tooglePublishModal(!publishModal);
												submitForm();
												// id ? editContent() : postContent();
											}}
										/>
									</Box>
								</Box>
							</Layer>
						)}

						{/* FIN MODALES */}
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default withRouter(SmartContentForm);
