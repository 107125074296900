import React from 'react';

const AuthReducer = (prevState, action) => {
    switch (action.type) {
        case 'RESTORE_TOKEN':
            return {
                ...prevState,
                authed: action.authed,
                resetPass: action.resetPass,
                selectTenant: action.selectTenant,
                isLoading: false,
            };
        case 'SIGN_IN':
            return {
                ...prevState,
                authed: true,
                isLoading: false,
                resetPass: action.resetPass,
                selectTenant: action.selectTenant
            };
        case 'SIGN_OUT':
            return {
                ...prevState,
                authed: false,
            };
        case 'RECOVER_PASSWORD':
            return prevState
        case 'CHANGE_CONFIRMED':
            return {
                ...prevState,
                resetPass: !action.confirmed
            }
    }
}

export default AuthReducer