import React, { useState, useEffect } from "react";
import { Box, TextInput, Image, Heading, Text, Layer, Button } from "grommet";
import { Link } from "react-router-dom";
import { Close, Trash, Add, StatusGood, InProgress, View } from "grommet-icons";
import UsersLibrary from '../../components/UsersLibrary'
import Servers from '../../constants/Servers'

import moment from "moment";

import { withRouter, useParams } from "react-router";

import ReactTable from 'react-table'
import 'react-table/react-table.css'

const Itineraries = ({ history }) => {

  const [deleteModal, toogleDeleteModal] = useState(false);
  const [advanceModal, setAdvanceModal] = useState({ status: false, data: [] })
  const [filtered, setFiltered] = useState([])
  const [contentIDToDelete, setContentIDToDelete] = useState("");

  const { id } = useParams()

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const deleteAdvanceItinerary = () => {}
  const createAvitinerary = () => {}
  const newNotification = () => {}

  useEffect(() => {
    data && setFiltered(data.avitineraries)
  }, [data])

  console.log(data)

  if (loading) { return null }
  return (
    <Box>

      <Box background="white" round="small" elevation="medium" >
        <Box pad={{ horizontal: "medium", vertical: "medium" }} wrap gap="large" direction="row" justify="between" align="center">
          <Heading color="gray" level="3" margin="none">Estado de los usuarios</Heading>

          <Box justify="end" gap="medium" direction="row" flex="grow" wrap>
            <Box flex="grow" width={{ max: "450px" }}>
              <TextInput placeholder="Buscar..." type="search" onChange={(ev) => setFiltered([...data.itineraries.filter(el => el.name.toLowerCase().includes(ev.target.value.toLowerCase()))])} />
            </Box>
          </Box>
        </Box>

        <Box>
          <ReactTable
            data={filtered}
            defaultPageSize={10}
            loading={loading}
            loadingText="Cargando Datos..."
            previousText='Entradas Anteriores'
            nextText='Entradas Siguientes'
            noDataText='Sin Contenidos'
            pageText='Página'
            ofText='de'
            rowsText='filas'
            columns={[
              {
                Header: 'User',
                headerStyle: { padding: 15 },
                id: 'email',
                style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
                accessor: datum => (
                  <Box>
                    <Text >{datum.user.email}</Text>
                  </Box>
                )
              }, {
                Header: 'Última Actualización',
                headerStyle: { padding: 15 },
                id: 'updatedAt',
                style: { display: "flex", whiteSpace: "unset", padding: 15, alignItems: "center" },
                accessor: datum => (
                  <Box>
                    <Text >{moment(datum.updatedAt).format("DD MMMM YYYY, hh:mm")}</Text>
                  </Box>
                )
              }, {
                Header: "Desafíos Completados",
                id: "completed",
                headerStyle: { padding: 15 },
                sortable: false,
                width: 150,
                style: { display: "flex", justifyContent: "center", alignItems: "center" },
                accessor: datum => datum.advance.filter(e => e.userResponse !== undefined).length
              }, {
                Header: 'Estado',
                headerStyle: { padding: 15 },
                id: 'status',
                width: 100,
                resizable: false,
                sortable: false,
                style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
                accessor: datum => (datum.state === "COMPLETE" ? <StatusGood /> : <InProgress />)
              }, {
                Header: "Ver Avance",
                headerStyle: { padding: 15 },
                id: "view",
                width: 100,
                resizable: false,
                sortable: false,
                style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
                accessor: datum => (
                  <Button color="gray" hoverIndicator icon={<View />}
                    onClick={() => { setAdvanceModal({ status: true, data: datum.advance }) }}
                  />
                )
              }, {
                Header: "Eliminar",
                headerStyle: { padding: 15 },
                id: "delete",
                width: 100,
                resizable: false,
                sortable: false,
                style: { display: "flex", paddingLeft: 15, paddingRight: 15, justifyContent: "center", alignItems: "center" },
                accessor: datum => (
                  <Button color="gray" hoverIndicator icon={<Trash />}
                    onClick={() => {
                      toogleDeleteModal(!deleteModal);
                      setContentIDToDelete(datum.id);
                    }}
                  />
                )
              }
            ]}
          />
        </Box>
      </Box>


      {deleteModal && (
        <Layer position="center" modal margin="small" responsive={false}
          onClickOutside={() => toogleDeleteModal(!deleteModal)}
          onEsc={() => toogleDeleteModal(!deleteModal)}
        >
          <Box pad="medium" width={{ min: "300px", max: "600px" }} >
            <Box gap="medium">
              <Heading level="3" margin="none">¿Está seguro que desea eliminar este Itinerario?</Heading>
              <Text>Esta acción no puede deshacerse, una vez eliminado el itinerario no podrá ser recuperado</Text>
            </Box>

            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Button
                label="Cancel"
                onClick={() => toogleDeleteModal(!deleteModal)}
              />
              <Button
                type="submit"
                label="Eliminar"
                color="status-critical"
                primary
                onClick={() => {
                  deleteAdvanceItinerary({
                    variables: { itineraryAvID: { id: contentIDToDelete } }
                  });
                  toogleDeleteModal(!deleteModal);
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}

      {advanceModal.status && (
        <Layer
          position="right"
          full="vertical"
          onClickOutside={() => setAdvanceModal({ status: false, data: [] })}
          onEsc={() => setAdvanceModal({ status: false, data: [] })}
        >
          <Box width="large">
            <Box background="brand" justify="between" align="center" flex="grow" pad="medium" direction="row">
              <Heading level="2" margin="none">Avance del usuario en el itinerario</Heading>
              <Button hoverIndicator onClick={() => setAdvanceModal({ status: false, data: [] })} icon={<Close color="white" />} />
            </Box>

            <Box overflow="scroll" gap="medium" pad="medium">
              {advanceModal.data.map((e, i) =>
                <Box key={i} flex="grow" gap="small" pad="medium">
                  <Heading level="3" margin="none">{i}. {e.title}</Heading>
                  <Box direction="row" justify="between">
                    <Text size="small">Respuesta del Usuario</Text>
                    {e.userResponse && <Text size="small">Respondido el: {moment(e.userCompleted).format("DD MMMM YYYY, hh:mm")}</Text>}
                  </Box>
                  <Box background="light-1" pad="small" round="small">
                    {e.type === "UPLOAD" ?
                      <Box>
                        {e.userResponse ?
                          <video key={e.userResponse.url}  style={{maxHeight:350}} width="100%" height="100%" controls onLoadedMetadata={dat => console.log(dat.currentTarget.duration)}>
                            <source src={e.userResponse.url} type="video/mp4"/>
                          </video>
                          :
                          <Text>"Sin Completar el reto"</Text>
                        }
                      </Box>
                      :
                      <Text>{e.userResponse ? JSON.stringify(e.userResponse) : "Sin Completar el reto"}</Text>
                    }
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Layer>
      )}



    </Box>
  );
};

export default withRouter(Itineraries);
