import React, { useState, useRef, useCallback, useEffect, useContext } from "react";
import {
    Box,
    Button,
    Heading,
    Text,
    Layer,
    TextInput,
    CheckBox
} from "grommet";

import QuestionGenerator from '../../components/QuestionGenerator'

import { Editor } from '@tinymce/tinymce-react';

import Papa from 'papaparse'

import { withRouter, useParams } from "react-router";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { firestore } from "firebase";
import AuthContext from "../../context/AuthContext";


const ExamForm = ({ history }) => {

    const [publishModal, tooglePublishModal] = useState(false)

    const { id } = useParams()

    const { userData, tenant } = useContext(AuthContext)
    const [richEditor, setRichEditor] = useState("")

    const [data, setData] = useState({
        name: "",
        advance: [],
        courses: [],
    });

    const fileInput = useRef()

    useEffect(() => {
        id === undefined && setRichEditor("")
        id !== undefined && getData()
    }, [])


    const getData = () => {

        tenant.ref.collection("exams").doc(id).get().then(async (doc) => {
            if (doc.exists) {

                setData({
                    ...data,
                    ...doc.data()
                })

                setRichEditor(doc.data().description)
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }



    const publishExam = async () => {
        let variables = {
            ...data,
            name: data.name,
            description: richEditor,
            advance: data.advance
        };

        tenant.ref.collection("exams").add(variables).then(res => {
            console.log(res)
            history.push(`${process.env.PUBLIC_URL}/${tenant.url}/smartlearning/exams`)
        }).catch(err => console.log(err))
    }

    const editExam = async () => {
        let variables = {
            ...data,
            examID: id,
            name: data.name,
            description: richEditor,
            advance: data.advance
        };
        tenant.ref.collection("exams").doc(id).update(variables).then(res => {
            console.log(res)
            history.push(`${process.env.PUBLIC_URL}/${tenant.url}/smartlearning/exams`)
        }).catch(err => console.log(err))
    }


    const editAdvance = (index, field, value) => {
        var newarr = data.advance
        newarr[index][field] = value
        setData({
            ...data,
            advance: newarr
        })
    }
    const addQuestion = () => {
        setData({
            ...data,
            advance: [...data.advance, { answers: [{ text: "", correctAnswer: false }], question: "" }]
        })
    }

    const deleteQuestion = (index) => {
        setData({
            ...data,
            advance: [...data.advance.filter((q, i) => i !== index)]
        })
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        borderRadius: 12,
        // padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        borderRadius: 12,
        width: "100%"
    });


    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            data.advance,
            result.source.index,
            result.destination.index
        );

        setData({
            ...data,
            advance: [...items]
        })
    }

    const loadCSV = (e) => {
        const file = e.target.files[0];
        // const fileURL = URL.createObjectURL(file);
        Papa.parse(file, {
            header: true,
            keepEmptyRows: false,
            skipEmptyLines: false,
            complete: async (results) => {
                console.log(results)
                const parsed = results.data.map((question, i) => {
                    var keys = Object.keys(question).filter(el => el !== "question").filter(el => el !== "correctAnswer")
                    const answers = keys.map((key, i) => question[key] !== "" ? { text: question[key], correctAnswer: question.correctAnswer === key } : null)
                    return {
                        question: question.question,
                        answers: answers.filter(el => el !== null)
                    }
                })
                setData({ ...data, advance: parsed })
            }
        })

    }


    return (
        <Box>

            <Box direction="row-responsive" gap="medium">

                <Box basis="2/3" gap="medium" >

                    {/* TITULO DE LA PUBLICACIÓN */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Título del Examen</Heading>
                        </Box>

                        <Box round="medium" pad={{ left: "small", right: "small" }}>
                            <TextInput
                                plain
                                placeholder="Escribe aquí..."
                                value={data.name}
                                onChange={event => { setData({ ...data, name: event.target.value }) }}
                            />
                        </Box>
                    </Box>
                    {/* FIN TITULO DE LA PUBLICACIÓN */}

                    {/* CONTENIDO */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Descripción del Examen</Heading>
                        </Box>

                        <Box pad="small">

                            {richEditor !== null &&
                                <Editor
                                    initialValue={richEditor}
                                    placeholder="Información del evento..."
                                    init={{
                                        height: 350,
                                        menubar: false,
                                        branding: false,
                                        menubar: 'file edit view insert format tools table tc help',
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                    }}
                                    onEditorChange={setRichEditor}
                                    apiKey="vddtdrd53at88cw7si2dcb3zfjifzm0dqohi9ctr3gzsn5kp"
                                />
                            }

                        </Box>
                    </Box>
                    {/* FIN CONTENIDO */}


                    {/* SECCIONES CURSO */}

                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Preguntas del Examen</Heading>
                        </Box>
                        <Box gap='medium' pad={{ horizontal: 'medium' }}>
                            <CheckBox checked={data.random} label='Preguntas y respuestas aleatorias' onChange={(event) => setData({ ...data, random: event.target.checked })} />
                        </Box>
                        <Box pad="small">
                            {data.advance.length === 0 ?
                                <Box pad="small" round="small" background="light-2" align="center">
                                    <Text textAlign="center">Sin Preguntas</Text>
                                </Box>
                                :
                                <DragDropContext onDragEnd={(ev) => onDragEnd(ev)}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <Box {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                {data.advance.map((question, i) =>
                                                    <Draggable key={`${i}-${question.title}`} draggableId={`${i}-${question.title}`} index={i}>
                                                        {(provided, snapshot) => (
                                                            <Box justify="between" pad="small" round="small" direction="row" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                                <QuestionGenerator
                                                                    value={data.advance[i]}
                                                                    allowCollapsible
                                                                    allowDeleteQuestion
                                                                    withFeedback={question.withFeedback}
                                                                    withFeedbackCallback={(ev) => editAdvance(i, "withFeedback", ev.target.checked)}
                                                                    openAnswer={question.openAnswer}
                                                                    withOpenAnswerCallback={(ev) => editAdvance(i, "openAnswer", ev.target.checked)}
                                                                    deleteQuestionCallback={() => deleteQuestion(i)}
                                                                    answersCallback={(ev) => editAdvance(i, "answers", ev)}
                                                                    question={data.advance[i].question}
                                                                    questionCallback={event => editAdvance(i, "question", event.target.value)}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            }
                        </Box>
                        <Box justify="between" margin="small" gap="small" direction="row">
                            <Button color="gainsboro" style={{ borderRadius: 7, height: 45, color: "gainsboro" }} onClick={() => fileInput.current.click()} label="Carga Masiva" />
                            <input type="file" accept=".csv" ref={fileInput} onChange={e => loadCSV(e)} style={{ display: "none" }} />
                            <Button style={{ borderRadius: 7, height: 45 }} onClick={() => addQuestion()} label="Añadir Pregunta" />
                        </Box>
                    </Box>
                    {/* FIN SECCIONES CURSO§ */}

                </Box>


                <Box basis="1/3" gap="medium" >
                    {/* BOTONES PUBLICACION */}
                    <Box background="white" overflow="hidden" round="small" elevation="medium" >

                        <Box pad="small" >
                            <Heading color="gray" level="4" margin="none">Publicación</Heading>
                        </Box>

                        <Box pad="small" gap="small">
                            <Button
                                disabled={data.name !== "" ? false : true}
                                primary
                                label={id ? "Actualizar Examen" : "Crear Examen"}
                                onClick={() => tooglePublishModal(true)}
                            />
                        </Box>


                    </Box>
                    {/* FIN BOTONES PUBLICACION */}

                </Box>

            </Box>
            {/* MODALES */}



            {publishModal && (
                <Layer
                    onEsc={() => tooglePublishModal(!publishModal)}
                    onClickOutside={() => tooglePublishModal(!publishModal)}
                >
                    <Box width="medium" pad="medium" gap="medium" align="center" justify="center">
                        <Heading level="3" margin="none">{id ? "Actualizar" : "Publicar"} examen</Heading>
                        <Text>Vas a proceder a {id ? "Actualizar" : "Publicar"} este examen, ¿estás seguro que deseas hacerlo?</Text>

                        <Box direction="row" gap="small" width="100%" justify="between">
                            <Button label="Cancelar"
                                onClick={() => tooglePublishModal(!publishModal)}
                            />
                            <Button
                                primary
                                label={id ? "Actualizar" : "Publicar"}
                                onClick={() => {
                                    tooglePublishModal(!publishModal)
                                    id ? editExam() : publishExam()
                                }}
                            />
                        </Box>
                    </Box>
                </Layer>
            )}


            {/* FIN MODALES */}


        </Box>
    );
};

export default withRouter(ExamForm);